import Amplify from 'aws-amplify'

export const checkAuthentication = () => Amplify.Auth.currentAuthenticatedUser()
export const currentCredentials = () => Amplify.Auth.currentCredentials()
export const logout = () => Amplify.Auth.signOut({ global: true })
export const sendWhistleblowerReport = (message, store) => {
  store.commit('setWhistleblowerError', null)
  store.commit('setWhistleblowerLoading', true)
  return Amplify.API.post('UserAPI', '/user/whistleblow', {
    body: { message: message }
  })
    .then(() => {
      store.commit('setWhistleblowerSuccess', true)
      store.commit('setWhistleblowerLoading', false)
    })
    .catch((error) => {
      store.commit('setWhistleblowerError', error)
      store.commit('setWhistleblowerLoading', false)
    })
}
export const saveAttributes = (attributes, store, router) => {
  store.commit('setUserDetailsError', null)
  store.commit('setUserDetailsLoading', true)
  return Amplify.API.put('UserAPI', '/user', { body: attributes })
    .then(() => {
      return Amplify.Auth.currentAuthenticatedUser({ bypassCache: true })
        .then((user) => store.commit('setLoggedInUser', user))
        .then(() => {
          if (router) {
            router.push('overview')
          }
        })
    })
    .catch((error) => {
      store.commit('setUserDetailsError', error)
    })
    .finally(() => {
      store.commit('setUserDetailsLoading', false)
    })
}
