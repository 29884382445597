export default {
  "completePassword": {
    "button": {
      "submit": "Send"
    },
    "header": "Vælg en ny adgangskode",
    "placeholder": {
      "confirmPassword": "Bekræft adgangskode",
      "password": "Adgangskode"
    }
  },
  "dashboard": {
    "button": {
      "goToAllResults": "Alle resultater",
      "myActions": "MINE HANDLINGER",
      "ourCompanyResults": "VORES RESULTATER"
    },
    "card": {
      "body":
        "Her kan du finde dine egne personlige handlinger, som du har valgt at foretage. Du kan også se hvordan dit resultat er, sammenlignet med andre i din gruppe eller organisation.",
      "title": "Aktiviteter og resultater"
    },
    "hello": "Hej {username}!",
    "introHeadline": "PULS hos {organizationName}",
    "settings": {
      "body": "Her kan du ændre det brugte sprog.",
      "da": "Dansk",
      "de": "Tysk",
      "en": "Engelsk",
      "false": "Norsk",
      "fr": "Fransk",
      "logout": {
        "button": {
          "title": "Log ud"
        },
        "confirmation": {
          "areyousure":
            "Hvis du logger ud, skal du anmode om en ny engangskode, for at logge ind igen.",
          "cancel": "Annullér",
          "false": "Annullér",
          "no": "Annullér",
          "title": "Vil du logge ud?",
          "true": "Log ud",
          "yes": "Log ud"
        },
        "loggedin": "Du er logget ind som {username}."
      },
      "no": "Norsk",
      "sv": "Svensk",
      "title": "Indstillinger"
    }
  },
  "error": {
    "CodeMismatchException":
      "Engangskoden du indtastede er ugyldig. Dobbelttjek den og prøv igen.",
    "couldNotLoadResults": "Kunne ikke indlæse resultater, prøv venligst igen.",
    "couldNotLoadSurveys":
      "Kunne ikke indlæse spørgeundersøgelser, prøv venligst igen.",
    "emailAtLeast3Letter": "E-mailadressen skal indeholde mindst 3 bogstaver",
    "emptyEmail": "Indtast din e-mailadresse",
    "emptyOrganization": "Indtast navnet på din organisation",
    "emptyPassword": "Indtast en adgangskode",
    "ExpiredCodeException":
      "Engangskoden du indtastede er for gammel, og kan af sikkerhedsmæssige årsager ikke bruges.",
    "InvalidParameterException": "Mangler det påkrævede parameter Session.",
    "InvalidPasswordException": "Vælg venligst en mere sikker adgangskode.",
    "LimitExceededException":
      "For mange loginforsøg. Du skal vente lidt tid, før du prøver igen.",
    "NotAuthorizedException":
      "Det er ikke muligt at logge ind med din brugerkonto i øjeblikket.",
    "notMatchPassword": "Adgangskoderne matcher ikke!",
    "OrganizationNotFoundException":
      "Vi kunne ikke finde en organisation med det navn, prøv venligst igen.",
    "UserNotFoundException":
      "Vi kunne ikke finde en bruger registreret med {username}."
  },
  "forgotten": {
    "backToLogin": "Annullér",
    "helptext":
      "Her kan du nulstille din adgangskode. Du modtager en e-mail på ovenstående e-mailadresse med en kode, som du skal indtaste på næste trin.",
    "helptext-quickstart":
      "Vi har lige sendt dig en midlertidig kode på {email}. Indtast den nedenfor, og vælg en adgangskode.",
    "helptext2": "Vælg en sikker adgangskode.",
    "input": {
      "code": "Kode fundet i e-mail",
      "organization": "Organisation",
      "password": "Ny adgangskode",
      "username": "E-mailadresse"
    },
    "requestcode": {
      "submit": "Fortsæt"
    },
    "setNewPassword": {
      "submit": "Start!"
    },
    "wait": "Vent venligst..."
  },
  "general": {
    "defaultProductTitle": "Puls⁺"
  },
  "help": {
    "card": {
      "body":
        "Er der noget vi kan hjælpe dig med? Klik blot på knappen nedenfor, for at gå til hjælpecenteret.",
      "title": "Hjælp og support"
    },
    "description": "Ingen grund til bekymring, vi er her for at hjælpe.",
    "title": "HJÆLP"
  },
  "ie": {
    "detailedText":
      "Puls-platformen kan bruges med Internet Explorer, men det er en gammel webbrowser, som ikke følger de moderne standarder for nettet. Vi anbefaler derfor, at du benytter en af browserne nedenfor. Du kan også bruge din mobiltelefon.",
    "detailedTitle": "Om Internet Explorer",
    "readMore": "Læs mere",
    "warning":
      "Du bruger Internet Explorer. Vi anbefaler at du skifter til en anden webbrowser for at få den bedste oplevelse."
  },
  "integrations": {
    "allIntegrations": "Alle sammenkøringer",
    "apps": {
      "sftp": {
        "connectionInfo": "Uploader til {dir} på SFTP-server.",
        "title": "SFTP"
      },
      "slack": {
        "connectionInfo": "Forbundet til {teamName} kanal {channel}",
        "title": "Nedgang"
      }
    },
    "connectedSince": "Koblet op siden {date}",
    "empty": {
      "description":
        "Ved at tilføje en sammenkøring kan du få Puls til at oprette forbindelse til din egen service. Vælg knappen herunder for at oprette forbindelse til en tjeneste."
    },
    "errors": {
      "invalidIncomingIntegration":
        "Ugyldig indgående data til sammenkøring! Kan ikke fortsætte."
    },
    "events": {
      "surveyCompleted": {
        "description":
          "Når en undersøgelse er afsluttet, sendes resultaterne {groupTitle} automatisk til sammenkøring.",
        "title": "Afsluttet undersøgelse"
      },
      "title": "Vælg begivenhed, når sammenkøringen skal kontaktes"
    },
    "groups": {
      "title": "Vælg gruppe for at oprette forbindelse til {appName}"
    },
    "integrationTo": "Samkøring til {appName}",
    "nothingSelected": "Vælg...",
    "setupIntegration": {
      "saveButtonTitle": "Gem",
      "title": "Indstil samkøring til {appName}"
    },
    "title": "Samkøring"
  },
  "login": {
    "button": {
      "forgotPassword": "Glemt adgangskode",
      "submit": "Login"
    },
    "captchaLegal": {
      "disclaimer":
        "Denne side er beskyttet af reCAPTCHA og Googles {toc} samt {privacyPolicy} gælder.",
      "policy": "Politik om beskyttelse af personlige oplysninger",
      "toc": "Servicevilkår"
    },
    "header": "Velkommen til Puls!",
    "placeholder": {
      "email": "E-mailadresse",
      "organization": "Organisation",
      "password": "Adgangskode"
    }
  },
  "navbar": {
    "welcome": "Velkommen"
  },
  "results": {
    "participants": "{participants} deltagere",
    "surveys": {
      "completed": {
        "latest": "Seneste opdaterede undersøgelse",
        "title": "Resultater for afsluttede undersøgelser"
      },
      "published": {
        "title": "Tjek resultater"
      }
    }
  },
  "setup": {
    "attributes": {
      "birthdate": {
        "info":
          "Dette skal bruges for at indsamle statistik, baseret på forskellige aldersgrupper.",
        "label": "Fødselsdato"
      },
      "birth_date": {
        "info":
          "Dette skal bruges for at indsamle statistik, baseret på forskellige aldersgrupper.",
        "label": "Fødselsdato"
      },
      "custom:employed_since": {
        "info":
          "Datoen hvor du blev ansat. Det behøver ikke at være en nøjagtig dato. Spring den over, hvis du ikke er ansat.",
        "label": "Ansat siden"
      },
      "employment_date": {
        "info":
          "Datoen hvor du blev ansat. Det behøver ikke at være en nøjagtig dato. Spring den over, hvis du ikke er ansat.",
        "label": "Ansat siden"
      },
      "family_name": {
        "label": "Efternavn"
      },
      "gender": {
        "one": "Andre",
        "other": "Andre",
        "zero": "Andre",
        "female": "Kvinde",
        "info":
          "Vi spørger om køn, så det kan blive inkluderet i statistikkerne. Du behøver ikke at vælge noget, hvis du ikke vil.",
        "label": "Vælg køn:",
        "male": "Mand",
        "wontsay": "Foretrækker ikke at oplyse"
      },
      "given_name": {
        "label": "Fornavn"
      },
      "locale": {
        "da": "Dansk",
        "de": "Tysk",
        "dk": "Dansk",
        "en": "Engelsk",
        "es": "Spansk",
        "false": "Norsk",
        "fr": "Fransk",
        "info":
          "Vælg det sprog du foretrækker at bruge. Dette kan også ændres senere.",
        "label": "Vælg sprog:",
        "ms": "Malajisk",
        "no": "Norsk",
        "sv": "Svensk"
      }
    },
    "button": {
      "submit": "Gem",
      "tryAgain": "Prøv igen"
    },
    "error": {
      "badPassword": "Vælg venligst en mere sikker adgangskode.",
      "clickLinkAgain":
        "Klik venligst på linket i velkomstmailen igen. Hvis problemet fortsætter, bedes du kontakte support@pulsmeter.se",
      "invalid": "Det link er ikke gyldigt mere, klik nedenfor i stedet:",
      "noPassword": "Du skal vælge en adgangskode.",
      "passwordsMustMatch": "Adgangskoderne skal matche.",
      "sessionError": "Vi er stødt på et problem!",
      "SSONotFound": "Der er ingen SSO-konfiguration for organisationen.",
      "unexpected": "Der var et uventet problem"
    },
    "header": "Kom i gang med Puls!",
    "introText":
      "Hej {name}! Du er blevet inviteret til Puls, en platform for medarbejderengagement. Aktivér din konto ved at udfylde nedenstående formular. ",
    "introTextVolontary":
      "Adgangskode er det eneste påkrævede udfyldningsfelt.",
    "login": "Fortsæt",
    "placeholder": {
      "confirmPassword": "Bekræft adgangskoden",
      "newPassword": "Vælg en adgangskode"
    }
  },
  "start": {
    "button": {
      "getOrganizationData": {
        "title": "Fortsæt"
      },
      "requestCode": {
        "title": "Hent engangskode"
      },
      "submitCode": {
        "title": "Login"
      }
    },
    "confirmRequestCode": {
      "header": {
        "description":
          "Vi vil først sikre os, at du er den rigtige person! Når du fortsætter, vil du få tilsendt en engangskode. Brug den i næste trin til at logge ind på Puls hos {organizationName}.",
        "help":
          "Du har for nyligt anmodet om en kode. Sørg for at indtaste koden i det vindue, hvor du anmodede om den. Hvis du fortsætter her, vil der blive sendt en ny kode."
      }
    },
    "enterCode": {
      "form": {
        "code": {
          "placeholder": "engangskode"
        }
      },
      "header": {
        "deliveryMedium": {
          "EMAIL": "e-mail",
          "SMS": "besked"
        },
        "description":
          "En engangskode er blevet sendt til dig via {deliveryMedium}. Brug den nedenfor, for at logge ind!",
        "title": "Velkommen til Puls!"
      },
      "help": {
        "noCodeDescriptionEmail":
          "Koden blev sendt til dig via e-mail til den e-mailadresse, der omfatter '{destination}'. Der kan gå lidt tid, før du får koden. Hvis du ikke kan finde den, så kig i din mappe med uønsket mail. Sørg for at holde dette vindue åbent, og indtast ovenstående kode.",
        "noCodeDescriptionSMS":
          "Koden blev sendt som en sms til din mobiltelefon på det telefonnummer, der indeholder '{destination}'. Der kan gå lidt tid, før du får koden. Genkender du ikke telefonnummeret, bedes du kontakte din administrator.",
        "noCodeLinkTitle": "Har du ikke modtaget en kode?",
        "noCodeStartOverLinkTitle": "Start forfra"
      }
    },
    "enterEmail": {
      "form": {
        "username": {
          "placeholder": "e-mailadresse"
        }
      },
      "header": {
        "description": "Indtast din e-mailadresse hos {organizationName}:"
      }
    },
    "enterOrganization": {
      "form": {
        "organization": {
          "placeholder": "organisationsnavn"
        }
      },
      "header": {
        "description":
          "Start med at indtaste navnet på organisationen du tilhører.",
        "invalid": {
          "spaces": "Brug ikke mellemrum.",
          "specialchars": "Brug kun bogstaver.",
          "uppercase": "Brug kun små bogstaver."
        }
      }
    }
  },
  "surveys": {
    "description": "Disse undersøgelser pågår nu:",
    "descriptionOneSurveyOnly":
      "Vælg undersøgelsen nedenfor, for at komme i gang!",
    "descriptionOneSurveyOnlyUserDone":
      "Denne undersøgelse er ikke afsluttet. Vælg den, for at se de handlinger du har valgt.",
    "ended": "afsluttet",
    "youreDone": "Du er færdig"
  },
  "surveysToFillIn": {
    "description": "Detter er igangværende undersøgelser:",
    "descriptionOneSurveyOnly": "Vælg undersøgelsen nedenfor, og kom i gang!",
    "descriptionOneSurveyOnlyUserDone":
      "Denne undersøgelse er ikke afsluttet. Vælg den, for at se de handlinger du har valgt.",
    "emptyResult":
      "Pt. er der ingen undersøgelse, der venter på at blive udfyldt af dig. Du kan se dine handllinger/kommentarer eller resultater her nedenfor.",
    "ended": "afsluttet",
    "endsIn": "afslutter",
    "retry": "Prøv igen",
    "title":
      "Du har en undersøgelse, der skal udfyldes|Der er {count} undersøgelse, der venter på dig",
    "youreDone": "Du er færdig"
  },
  "user": {
    "attributes": {
      "birthdate": {
        "info":
          "Dette skal bruges for at indsamle statistik, baseret på forskellige aldersgrupper.",
        "label": "Fødselsdato"
      },
      "birth_date": {
        "info":
          "Dette skal bruges for at indsamle statistik, baseret på forskellige aldersgrupper.",
        "label": "Fødselsdato"
      },
      "custom:employed_since": {
        "info":
          "Datoen hvor du blev ansat. Det behøver ikke at være en nøjagtig dato. Spring den over, hvis du ikke er ansat.",
        "label": "Ansat siden"
      },
      "employment_date": {
        "info":
          "Datoen hvor du blev ansat. Det behøver ikke at være en nøjagtig dato. Spring den over, hvis du ikke er ansat.",
        "label": "Ansat siden"
      },
      "family_name": {
        "label": "Efternavn"
      },
      "gender": {
        "one": "Andre",
        "other": "Andre",
        "zero": "Andre",
        "female": "Kvinde",
        "info":
          "Vi spørger om køn, så det kan blive inkluderet i statistikkerne. Du behøver ikke at vælge noget, hvis du ikke vil.",
        "label": "Vælg køn:",
        "male": "Mand",
        "wontsay": "Foretrækker ikke at oplyse"
      },
      "given_name": {
        "label": "Fornavn"
      },
      "locale": {
        "da": "Dansk",
        "de": "Tysk",
        "en": "Engelsk",
        "es": "Spansk",
        "false": "Norsk",
        "fi": "Finsk",
        "fr": "Fransk",
        "info":
          "Vælg det sprog du foretrækker at bruge. Dette kan også ændres senere.",
        "it": "Italiensk",
        "label": "Vælg sprog:",
        "ms": "Malajisk",
        "nl": "Hollandsk",
        "no": "Norsk",
        "pl": "Polsk",
        "sv": "Svensk"
      }
    },
    "button": {
      "cancel": "Annuller",
      "skip": "Gå tilbage",
      "submit": "Gem",
      "tryAgain": "Prøv igen"
    },
    "error": {
      "badPassword": "Vælg venligst en mere sikker adgangskode.",
      "clickLinkAgain":
        "Klik venligst på linket i velkomstmailen igen. Hvis problemet fortsætter, bedes du kontakte support@pulsmeter.se",
      "invalid": "Det link er ikke gyldigt mere, klik nedenfor i stedet:",
      "noPassword": "Du skal vælge en adgangskode.",
      "passwordsMustMatch": "Adgangskoderne skal matche.",
      "sessionError": "Vi er stødt på et problem!",
      "SSONotFound": "Der er ingen SSO-konfiguration for organisationen.",
      "unexpected": "Der var et uventet problem"
    },
    "header": "Brugerindstillinger",
    "introText": "Kontroller, at din brugerprofil er korrekt.",
    "introTextVolontary": "Du kan efterlade felter tomme, hvis du vil.",
    "login": "Fortsæt"
  },
  "whistleblower": {
    "buttons": {
      "cancel": "Annuller",
      "done": "Ok",
      "send": "Send anonymt"
    },
    "description":
      "Vi giver dig mulighed for anonymt at sende en rapport om ulovlig adfærd eller forseelser, som du har været vidne til på {organizationName}.",
    "detailedDescription":
      "Således fungerer det. Du indsætter din rapport i tekstboksen nedenfor. Når du sender rapporten, sendes der en e -mail til en foruddefineret modtager i din organisation, der har ansvar for at handle. Det er umuligt for modtageren at se, at beskeden blev sendt af dig. Det kan kun ses, at den kom fra Puls-platformen.  Hvis du ønsker at blive kontaktet, skal du sørge for at gøre opmærksom på dette i din besked. Hvis du vil være anonym, kan du bruge en engangs (midlertidig) e-mailadresse eller SIM -kort til mobiltelefon.",
    "doneText":
      "Tak for indsendelsen af din rapport. Vi tager det alvorligt. Hvis du har oplyst dine kontaktoplysninger, vender vi tilbage til dig hurtigst muligt!",
    "linkTitle": "Læs mere her.",
    "linkToHelp": "https://www.puls-solutions.com/help/TODO",
    "notEnabled": {
      "description":
        "Beklager. Whistleblower -funktionen er ikke aktiveret af din organisation.",
      "title": "Ikke tilgængelig"
    },
    "placeholder":
      "Indtast din rapport her. Vær så præcis som mulig, og lad os vide, hvis og hvordan du ønsker at blive kontaktet.",
    "title": "Send anonym rapport"
  }
}
