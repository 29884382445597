import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import store from './store'
import AWS from 'aws-sdk'
import {
  checkAuthentication,
  currentCredentials
} from './services/AwsAmplifyService'

Vue.use(Router)
const router = new Router({
  routes: [
    {
      path: '/start',
      name: 'Start',
      component: () => import('./views/Start'),
      meta: {
        title: 'Puls - Start',
        authentication: false
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('./views/Login'),
      meta: {
        title: 'Puls - Login',
        authentication: false
      }
    },
    {
      path: '/logout',
      name: 'Logout',
      component: () => import('./views/Logout'),
      meta: {
        title: 'Puls - Logout',
        authentication: false
      }
    },
    {
      path: '/loggedout',
      name: 'LoggedOut',
      component: () => import('./views/LoggedOut'),
      meta: {
        title: 'Puls - Logged out',
        authentication: false
      }
    },
    {
      path: '/setup/:organization/:username/:temporarypassword',
      name: 'Setup',
      component: () => import('./views/Setup'),
      props: true,
      meta: {
        title: 'Puls - Setup',
        authentication: false
      }
    },
    {
      path: '/complete-password',
      name: 'CompletePassword',
      component: () => import('./views/CompletePassword'),
      meta: {
        title: 'Puls - Password',
        authentication: false
      }
    },
    {
      path: '/sso',
      name: 'SSO',
      component: () => import('./views/SSO'),
      meta: {
        title: 'Puls - SSO Callback',
        authentication: false
      }
    },
    {
      path: '/whoami',
      name: 'WhoAmI',
      component: () => import('./views/WhoAmI'),
      meta: {
        title: 'Puls - Who Am I',
        authentication: true
      }
    },
    {
      path: '/tester',
      name: 'Tester',
      component: () => import('./views/Tester'),
      meta: {
        title: 'Puls - Tester',
        authentication: false
      }
    },
    {
      path: '/insights',
      name: 'InsightsView',
      component: () => import('./views/InsightsView.vue'),
      meta: {
        title: 'Puls - Insights',
        authentication: true
      }
    },
    {
      path: '/ie',
      name: 'IE',
      component: () => import('./views/IE'),
      meta: {
        title: 'Puls - IE',
        authentication: false
      }
    },
    {
      path: '/forgot/:organization?/:username?/:code?',
      name: 'ForgotPassword',
      component: () => import('./views/ForgotPassword'),
      meta: {
        title: 'Puls - Forgot Password',
        authentication: false
      }
    },
    {
      path: '/',
      component: Home,
      name: 'overview',
      redirect: '/overview',
      meta: {
        authentication: true
      },
      children: [
        {
          path: 'overview',
          alias: 'o',
          name: 'Overview',
          component: () => import('./views/Overview'),
          meta: {
            title: 'Puls - Overview'
          }
        },
        {
          path: 'user',
          name: 'User',
          component: () => import('./views/User'),
          props: true,
          meta: {
            title: 'Puls - User'
          }
        },
        {
          path: 'whistleblower',
          name: 'Whistleblower',
          component: () => import('./views/Whistleblower'),
          props: true,
          meta: {
            title: 'Puls - Whistleblower'
          }
        },
        {
          path: 'integrations',
          name: 'Integrations',
          component: () => import('./components/Integrations'),
          props: true,
          meta: {
            title: 'Puls - Integrations'
          }
        },
        {
          path: 'integration/save',
          name: 'Integration',
          component: () => import('./components/Integration'),
          props: true,
          meta: {
            title: 'Puls - Integration'
          }
        }
      ]
    },
    {
      path: '*',
      component: () => import('./views/NotFound'),
      meta: {
        authentication: false
      }
    }
  ]
})

// function applyOrgNameIfPresent (to) {
// console.log(to)
// }

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Puls'
  if (to.query.r) {
    localStorage.temporaryRedirect = to.query.r
  }
  if (to.matched.some((record) => record.meta.authentication)) {
    checkAuthentication()
      .then((user) => {
        store.commit('setLoggedInUser', user)
      })
      .then(() => {
        currentCredentials().then((credentials) => {
          AWS.config.update({ credentials: credentials })
          store.commit('setHasToken', true)
        })
      })
      .catch(() => {
        store.commit('setLoggedInUser', null)
        store.commit('setHasToken', false)
        // this.$router.push('/login')
        next({
          path: '/start',
          query: to.query
        })
      })
    if (!store.getters.hasToken) {
      next({
        path: '/start'
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
