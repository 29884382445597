const state = {
  notifications: []
}

const mutations = {
  addGlobalNotifications (state, notifications) {
    state.notifications.push(notifications)
  },
  removeGlobalNotification (state, id) {
    state.notifications = state.notifications.filter(item => item.id !== id)
  }
}

export default {
  state,
  mutations
}
