import _ from 'lodash'

export default {
  install(Vue, i18n, store) {
    Vue.prototype.$tr = function (messages, key, interpolate = null) {
      if (!messages || !messages.en) {
        return 'missing messages'
      }
      let locale = i18n.locale
      if (!messages[locale]) {
        locale = 'en' // fallback
      }
      let string = _.get(messages[locale], key) || ''
      if (interpolate) {
        const regex = new RegExp(`{${interpolate.key}}`, 'g')
        string = string.replace(regex, interpolate.value)
      }
      if (typeof string === 'object') {
        return string
      }
      return string
        .replace(/{organizationName}/g, store.getters.userInfo.organizationName)
        .replace(/{username}/g, store.getters.userInfo.username)
    }
    Vue.prototype.$productName = (vue) => {
      if (window.localStorage.orgData) {
        const orgData = JSON.parse(window.localStorage.orgData)
        if (
          orgData &&
          orgData.orgSettings &&
          orgData.orgSettings.customProductName
        ) {
          return orgData.orgSettings.customProductName
        }
      }
      return null
    }
    Vue.prototype.$productTitle = (vue) => {
      if (window.localStorage.orgData) {
        const orgData = JSON.parse(window.localStorage.orgData)
        if (
          orgData &&
          orgData.orgSettings &&
          orgData.orgSettings.customProductTitle
        ) {
          return orgData.orgSettings.customProductTitle
        }
      }

      // Fallback to default 'Puls'
      return vue.$t('general.defaultProductTitle')
    }
  }
}
