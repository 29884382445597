export default {
  "completePassword": {
    "button": {
      "submit": "Senden"
    },
    "header": "Wählen Sie ein neues Passwort!",
    "placeholder": {
      "confirmPassword": "Passwort bestätigen",
      "password": "Passwort"
    }
  },
  "dashboard": {
    "button": {
      "goToAllResults": "Alle Ergebnisse",
      "myActions": "MEINE AKTIONEN",
      "ourCompanyResults": "UNSERE ERGEBNISSE"
    },
    "card": {
      "body":
        "Hier findest du deine persönlichen Aktionen, denen du nachgehen möchtest. Du kannst dich auch einen Vergleich Ihrer Ergebnisse mit denen anderer Mitglieder deiner Gruppe oder Organisation ansehen.",
      "title": "Aktivitäten und Ergebnisse"
    },
    "hello": "Hallo, {username}!",
    "introHeadline": "{productTitle} in {organizationName}",
    "settings": {
      "body": "Hier kannst du die verwendete Sprache ändern.",
      "da": "Dänisch",
      "de": "Deutsch",
      "en": "Englisch",
      "false": "Norwegisch",
      "fr": "Französisch",
      "logout": {
        "button": {
          "title": "Ausloggen"
        },
        "confirmation": {
          "areyousure":
            "Wenn du dich abmeldest, musst du einen neuen Einmalcode anfordern, um dich wieder anzumelden.",
          "cancel": "Abbrechen",
          "false": "Abbrechen",
          "no": "Abbrechen",
          "title": "Bist du sicher?",
          "true": "Ausloggen",
          "yes": "Ausloggen"
        },
        "loggedin": "Du bist als {username} angemeldet."
      },
      "no": "Norwegisch",
      "sv": "Schwedisch",
      "title": "Einstellungen"
    }
  },
  "error": {
    "CodeMismatchException":
      "Der von dir eingegebene Einmalcode ist ungültig. Bitte überprüfe ihn noch einmal und versuche es erneut.",
    "couldNotLoadResults":
      "Die Ergebnisse konnten nicht geladen werden, bitte versuche es erneut.",
    "couldNotLoadSurveys":
      "Die Fragebögen konnten nicht geladen werden, bitte versuche es erneut.",
    "emailAtLeast3Letter":
      "Die E-Mail-Adresse muss mindestens 3 Buchstaben enthalten.",
    "emptyEmail": "Gib deine E-Mail-Adresse ein",
    "emptyOrganization": "Geben Sie den Namen Ihres Unternehmens ein.",
    "emptyPassword": "Geben Sie ein Passwort ein.",
    "ExpiredCodeException":
      "Der Einmal-Code, den du eingegeben hast, ist zu alt und kann auch Sicherheitsgründen nicht verwendet werden.",
    "InvalidParameterException": "Es fehlt die notwendige Parameter-Sitzung.",
    "InvalidPasswordException": "Wählen Sie bitte ein sichereres Passwort.",
    "LimitExceededException":
      "Zu viele Login-Versuche. Sie müssen etwas warten, bevor Sie es erneut versuchen.",
    "NotAuthorizedException":
      "Es ist derzeitig nicht möglich, dass Sie sich in Ihr Benutzerkonto einloggen.",
    "notMatchPassword": "Die Passwörter stimmen nicht überein!",
    "OrganizationNotFoundException":
      "Wir konnten kein Unternehmen mit diesem Namen finden, versuchen Sie es erneut.",
    "UserNotFoundException":
      "Wir konnten keinen Benutzer finden, der sich mit {username} angemeldet hat."
  },
  "forgotten": {
    "backToLogin": "Abbrechen",
    "helptext":
      "Hier können Sie Ihr Passwort zurücksetzen. Sie werden eine E-Mail an die obenstehende E-Mail-Adresse zugeschickt bekommen, die einen Code enthält, welchen Sie in dem nächsten Schritt eingeben.",
    "helptext-quickstart":
      "Wir haben Ihnen gerade einen zeitlich begrenzten Code an {E-Mail} gesendet. Geben Sie ihn unten ein und wählen Sie ein Passwort.",
    "helptext2": "Wählen Sie ein sicheres Passwort.",
    "input": {
      "code": "Der in der E-Mail zu findende Code",
      "organization": "Unternehmen",
      "password": "Neues Passwort",
      "username": "E-Mail-Adresse"
    },
    "requestcode": {
      "submit": "Weiter"
    },
    "setNewPassword": {
      "submit": "Start!"
    },
    "wait": "Bitte warten ..."
  },
  "general": {
    "defaultProductTitle": "Puls⁺"
  },
  "help": {
    "card": {
      "body":
        "Können wir dir irgendwie helfen? Klicke einfach auf die Schaltfläche unten, um zum Hilfecenter zu gelangen.",
      "title": "Hilfe und Support"
    },
    "description": "Keine Sorge, wir halten Ihnen den Rücken frei.",
    "title": "HILFE"
  },
  "ie": {
    "detailedText":
      "Die Puls-Plattform kann mit Internet Explorer genutzt werden, aber es ist ein alter Web-Browser, der mit den modernen Web-Standards nicht kompatibel ist. Wir empfehlen daher, einen der untenstehenden Browser zu benutzen. Sie können auch Ihr Handy verwenden.",
    "detailedTitle": "Über Internet Explorer",
    "readMore": "weiterlesen",
    "warning":
      "Sie verwenden den Internet Explorer. Wir empfehlen Ihnen, dass Sie zu einem anderen Web-Browser wechseln, um eine bessere Erfahrung zu haben."
  },
  "integrations": {
    "allIntegrations": "Alle Integrationen",
    "apps": {
      "sftp": {
        "connectionInfo": "Wird in {dir} auf dem SFTP-Server hochgeladen.",
        "title": "SFTP"
      },
      "slack": {
        "connectionInfo": "Verbunden mit {teamName} Kanal {channel}",
        "title": "Slack"
      }
    },
    "connectedSince": "Angemeldet seit {date}",
    "empty": {
      "description":
        "Durch Hinzufügen einer Integration kannst du Puls mit deinem eigenen Dienst verbinden. Wähle die Schaltfläche unten für die Anmeldung bei einem Dienst"
    },
    "errors": {
      "invalidIncomingIntegration":
        "Eingehende Daten ungültig für die Integration! Kann nicht fortgesetzt werden"
    },
    "events": {
      "surveyCompleted": {
        "description":
          "Wenn eine Umfrage abgeschlossen ist, werden die Ergebnisse für {groupTitle} automatisch an die Integration gesendet.",
        "title": "Umfrage abgeschlossen"
      },
      "title":
        "Wähle das Ereignis, wenn die Integration kontaktiert werden soll"
    },
    "groups": {
      "title": "Wähle die Gruppe für die Anmeldung bei {appName} aus"
    },
    "integrationTo": "Integration in {appName}",
    "nothingSelected": "Auswählen ...",
    "setupIntegration": {
      "saveButtonTitle": "Speichern",
      "title": "Integration in {appName} einrichten"
    },
    "title": "Integrationen"
  },
  "login": {
    "button": {
      "forgotPassword": "Passwort vergessen?",
      "submit": "Login"
    },
    "captchaLegal": {
      "disclaimer":
        "Diese Seite wird von reCAPTCHA geschützt und es gelten die {toc} und {privacyPolicy} von Google.",
      "policy": "Datenschutzrichtlinie",
      "toc": "Nutzungsbedingungen"
    },
    "header": "Welcome to Puls!",
    "placeholder": {
      "email": "E-Mail-Adresse",
      "organization": "Unternehmen",
      "password": "Passwort"
    }
  },
  "navbar": {
    "welcome": "Wilkommen"
  },
  "results": {
    "participants": "{participants} Teilnehmer",
    "surveys": {
      "completed": {
        "latest": "Letzte abgeschlossene Umfrage",
        "title": "Ergebnisse für abgeschlossene Umfragen"
      },
      "published": {
        "title": "Ergebnisse prüfen"
      }
    }
  },
  "setup": {
    "attributes": {
      "birthdate": {
        "info":
          "Dies ist erforderlich, um Statistiken auf der Grundlage von unterschiedlichen Altersgruppen zu erfassen.",
        "label": "Geburtsdatum"
      },
      "birth_date": {
        "info":
          "Dies ist erforderlich, um Statistiken auf der Grundlage von unterschiedlichen Altersgruppen zu erfassen.",
        "label": "Geburtstag"
      },
      "custom:employed_since": {
        "info":
          "Das Datum, an dem du eingestellt wurdest. Es braucht nicht unbedingt das genaue Datum zu sein. Überspringe es einfach, wenn du kein Arbeitnehmer bist.",
        "label": "angestellt seit"
      },
      "employment_date": {
        "info":
          "Das Datum, an dem du eingestellt wurdest. Es braucht nicht unbedingt das genaue Datum zu sein. Überspringe es einfach, wenn du kein Arbeitnehmer bist.",
        "label": "Anstellungsdatum"
      },
      "family_name": {
        "label": "Nachname"
      },
      "gender": {
        "other": "Anderes",
        "female": "Frau",
        "info":
          "Wir fragen nach dem Geschlecht, damit es in die Statistiken aufgenommen werden kann. Du brauchst keines auszuwählen, wenn du nicht möchtest.",
        "label": "Wähle das Geschlecht",
        "male": "Mann",
        "wontsay": "Keine Angabe"
      },
      "given_name": {
        "label": "Vorname"
      },
      "locale": {
        "da": "Dänisch",
        "de": "Deutsch",
        "dk": "Dänisch",
        "en": "Englisch",
        "es": "Spanisch",
        "false": "Norwegisch",
        "fr": "Französisch",
        "info":
          "Wählen Sie die Sprache, die Sie bei der Nutzung bevorzugen. Dies kann zu einem späteren Zeitpunkt umgestellt werden.",
        "label": "Wähle Sprache:",
        "ms": "Malaiisch",
        "no": "Norwegisch",
        "sv": "Schwedisch"
      }
    },
    "button": {
      "submit": "Speichern",
      "tryAgain": "Versuchst du es erneut."
    },
    "error": {
      "badPassword": "Wählen Sie bitte ein sichereres Passwort.",
      "clickLinkAgain":
        "Klicken Sie bitte noch einmal auf den Link in der Begrüßungsmail. Wenn das Problem weiterhin bestehen bleiben sollte, dann nehmen Sie bitte über support@pulsmeter.se Kontakt auf.",
      "invalid":
        "Dieser Link ist nicht länger gültig, klicken Sie stattdessen auf die untenstehende Schaltfläche:",
      "noPassword": "Sie müssen ein Passwort auswählen.",
      "passwordsMustMatch": "Die Passwörter müssen übereinstimmen.",
      "sessionError": "Wir sind auf ein Problem gestoßen!",
      "SSONotFound": "Es gibt für die Organisation keine SSO-Konfiguration.",
      "unexpected": "Es ist ein unvorhergesehenes Problem aufgetreten."
    },
    "header": "Beginnst du mit Puls!",
    "introText":
      "Hallo{name}! Sie wurden zu Puls eingeladen, einer Plattform für das Engagement von Mitarbeitern. Aktivieren Sie Ihr Konto, indem Sie das untenstehende Formular ausfüllen. ",
    "introTextVolontary": "Nur ein Passwort ist obligatorisch.",
    "login": "Weiter",
    "placeholder": {
      "confirmPassword": "Passwort erneut eingeben",
      "newPassword": "Passwort"
    }
  },
  "start": {
    "button": {
      "getOrganizationData": {
        "title": "Weiter"
      },
      "requestCode": {
        "title": "Code holen"
      },
      "submitCode": {
        "title": "Login"
      }
    },
    "confirmRequestCode": {
      "header": {
        "description":
          "Zuerst möchten wir ganz einfach sicherstellen, dass du die richtige Person bist! Wenn du fortfährst, wird dir ein einmaliger Code zugeschickt. Nutze ihn im nächsten Schritt, um dich mit {organizationName} bei Puls einzuloggen.",
        "help":
          "Du hast vor Kurzem einen Code angefordert. Vergewisser dich, dass du den Code in dem Fenster eingibst, in dem du ihn angefordert hast. Wenn du hier fortfährst, wird dir ein neuer Code zugeschickt."
      }
    },
    "enterCode": {
      "form": {
        "code": {
          "placeholder": "code"
        }
      },
      "header": {
        "deliveryMedium": {
          "EMAIL": "E-Mail",
          "SMS": "SMS"
        },
        "description":
          "Ein einmaliger Code wurde dir per {deliveryMedium}  zugeschickt. Gib den Code unten in das Feld ein, um dich einzuloggen!",
        "title": "Willkommen bei Puls!"
      },
      "help": {
        "noCodeDescriptionEmail":
          "Der Code wurde dir per E-Mail an die E-Mail-Adresse geschickt, die '{destination}' enthält. Es kann ein bis zwei Minuten dauern, bis der Code eintrifft. Wenn du sie nicht finden kannst, überprüfe deinen Spam-Ordner. Achte darauf, dass du dieses Fenster offen lässt und gib den obigen Code ein.",
        "noCodeDescriptionSMS":
          "Der Code wurde als Textnachricht an dein Mobiltelefon geschickt. An die Telefonnummer, die ‚{destination}' enthält. Es kann ein bis zwei Minuten dauern, bis der Code eintrifft. Wenn dir die Telefonnummer nicht bekannt ist, wende dich bitte an deinen Administrator.",
        "noCodeLinkTitle": "Hast du keinen Code bekommen?",
        "noCodeStartOverLinkTitle": "Neu anfangen"
      }
    },
    "enterEmail": {
      "form": {
        "username": {
          "placeholder": "E-Mail-Addresse"
        }
      },
      "header": {
        "description": "Gib deine E-Mail-Adresse bei {organizationName} ein:"
      }
    },
    "enterOrganization": {
      "form": {
        "organization": {
          "placeholder": "Name der Organisation"
        }
      },
      "header": {
        "description":
          "Beginne mit der Eingabe des Namens der Organisation, der du angehörst.",
        "invalid": {
          "spaces": "Keine Leerzeichen, bitte. ",
          "specialchars": "Verwende nur Buchstaben.",
          "uppercase": "Verwenden Sie nur Kleinbuchstaben."
        }
      }
    }
  },
  "surveys": {
    "description": "Hier sind die Umfragen, zu denen Sie eingeladen wurden.",
    "descriptionOneSurveyOnly":
      "Wählen Sie die unten stehende Umfrage aus, um loszulegen!",
    "descriptionOneSurveyOnlyUserDone":
      "Die Umfrage läuft noch. Klicke diese an, um die von dir ausgewählten Aktionen anzuzeigen.",
    "ended": "endete",
    "youreDone": "Du bist fertig"
  },
  "surveysToFillIn": {
    "description": "Diese Umfragen laufen derzeit:",
    "descriptionOneSurveyOnly": "Wähle die Umfrage unten aus, um loszulegen!",
    "descriptionOneSurveyOnlyUserDone":
      "Die Umfrage läuft noch. Klicke diese an, um die von dir ausgewählten Aktionen anzuzeigen.",
    "emptyResult":
      "Im Moment wartet keine Umfrage darauf, dass du sie ausfüllst. Du kannst deine Aktionen/Kommentare oder Ergebnisse hier unten einsehen.",
    "ended": "beendet",
    "endsIn": "endet",
    "retry": "Wiederholen",
    "title":
      "Du musst eine Umfrage ausfüllen|Es warten {count} Umfragen auf dich",
    "youreDone": "Du bist fertig"
  },
  "user": {
    "attributes": {
      "birthdate": {
        "info":
          "Dies ist erforderlich, um Statistiken auf der Grundlage von unterschiedlichen Altersgruppen zu erfassen.",
        "label": "Geburtsdatum"
      },
      "birth_date": {
        "info":
          "Dies ist erforderlich, um Statistiken auf der Grundlage von unterschiedlichen Altersgruppen zu erfassen.",
        "label": "Geburtstag"
      },
      "custom:employed_since": {
        "info":
          "Das Datum, an dem du eingestellt wurdest. Es braucht nicht unbedingt das genaue Datum zu sein. Überspringe es einfach, wenn du kein Arbeitnehmer bist.",
        "label": "angestellt seit"
      },
      "employment_date": {
        "info":
          "Das Datum, an dem du eingestellt wurdest. Es braucht nicht unbedingt das genaue Datum zu sein. Überspringe es einfach, wenn du kein Arbeitnehmer bist.",
        "label": "Anstellungsdatum"
      },
      "family_name": {
        "label": "Nachname"
      },
      "gender": {
        "other": "Anderes",
        "female": "Frau",
        "info":
          "Wir fragen nach dem Geschlecht, damit es in die Statistiken aufgenommen werden kann. Du brauchst keines auszuwählen, wenn du nicht möchtest.",
        "label": "Wähle das Geschlecht",
        "male": "Mann",
        "wontsay": "Keine Angabe"
      },
      "given_name": {
        "label": "Vorname"
      },
      "locale": {
        "da": "Dänisch",
        "de": "Deutsch",
        "en": "Englisch",
        "es": "Spanisch",
        "false": "Norwegisch",
        "fi": "Finnisch",
        "fr": "Französisch",
        "info":
          "Wählen Sie die Sprache, die Sie bei der Nutzung bevorzugen. Dies kann zu einem späteren Zeitpunkt umgestellt werden.",
        "it": "Italienisch",
        "label": "Wähle Sprache:",
        "ms": "Malaiisch",
        "nl": "Niederländisch",
        "no": "Norwegisch",
        "pl": "Polnisch",
        "sv": "Schwedisch"
      }
    },
    "button": {
      "cancel": "Abbrechen",
      "skip": "Zurückgehen",
      "submit": "Speichern",
      "tryAgain": "Versuchst du es erneut."
    },
    "error": {
      "badPassword": "Wählen Sie bitte ein sichereres Passwort.",
      "clickLinkAgain":
        "Klicken Sie bitte noch einmal auf den Link in der Begrüßungsmail. Wenn das Problem weiterhin bestehen bleiben sollte, dann nehmen Sie bitte über support@pulsmeter.se Kontakt auf.",
      "invalid":
        "Dieser Link ist nicht länger gültig, klicken Sie stattdessen auf die untenstehende Schaltfläche:",
      "noPassword": "Sie müssen ein Passwort auswählen.",
      "passwordsMustMatch": "Die Passwörter müssen übereinstimmen.",
      "sessionError": "Wir sind auf ein Problem gestoßen!",
      "SSONotFound": "Es gibt für die Organisation keine SSO-Konfiguration.",
      "unexpected": "Es ist ein unvorhergesehenes Problem aufgetreten."
    },
    "header": "Einstellungen",
    "introText": "Überprüfen Sie, ob Ihr Benutzerprofil korrekt ist.",
    "introTextVolontary": "Nichts ist obligatorisch.",
    "login": "Weiter"
  },
  "whistleblower": {
    "buttons": {
      "cancel": "Abbrechen",
      "done": "Ok",
      "send": "Anonym senden"
    },
    "description":
      "Wir bieten dir die Möglichkeit, anonym eine Meldung über rechtswidriges Verhalten oder Vergehen, welche du bei {organizationName} beobachtet hast, zu senden.",
    "detailedDescription":
      "So funktioniert es. Du gibst deinen Bericht in das Textfeld unten ein. Wenn du den Bericht sendest, wird eine E-Mail an einen vorher festgelegten Empfänger in deiner Organisation, der für das Ergreifen von Maßnahmen zuständig ist, gesendet. Der Empfänger kann nicht erkennen, dass die Nachricht von dir gesendet wurde, sondern nur, dass sie von der Puls-Plattform stammt.  Wenn du kontaktiert werden möchtest, stelle sicher, dass du dies in deiner Nachricht angibst. Wenn du anonym bleiben möchtest, kannst du eine einmalige (vorübergehende) E-Mail-Adresse oder eine SIM-Karte für das Mobiltelefon verwenden.",
    "doneText":
      "Vielen Dank für die Übermittlung deines Berichts. Wir werden diesen ernst nehmen. Wenn du Kontaktdaten angegeben hast, werden wir uns so schnell wie möglich bei dir melden!",
    "linkTitle": "Hier erfährst du mehr",
    "linkToHelp": "https://www.puls-solutions.com/help/TODO",
    "notEnabled": {
      "description":
        "Entschuldigung Die Whistleblower-Funktion wurde von deiner Organisation nicht aktiviert.",
      "title": "Nicht verfügbar"
    },
    "placeholder":
      "Gib hier deinen Bericht ein Sei so konkret wie möglich und teile uns mit, ob und wie du kontaktiert werden möchtest.",
    "title": "Einen anonymen Bericht senden"
  }
}
