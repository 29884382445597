import { API } from 'aws-amplify'

export const loadIntegrations = () =>
  API.get('UserAPI', '/user/settings/integrations', {})
export const loadIntegrationsAvailable = () =>
  API.get('UserAPI', '/user/settings/integrationsavailable', {})

export const saveIntegration = (data) =>
  API.post('UserAPI', '/user/settings/integrations', {
    body: {
      ...data
    }
  })

export const removeIntegration = (integrationName) => {
  return API.del(
    'UserAPI',
    `/user/settings/integrations/${integrationName}`,
    {}
  )
}
