import Vue from 'vue'
import VueI18n from 'vue-i18n'
import config from './config'
import DevOps from '@puls-solutions/puls-devops-utils'
import ar from './locales/ar'
import da from './locales/da'
import de from './locales/de'
import en from './locales/en'
import es from './locales/es'
import fi from './locales/fi'
import fr from './locales/fr'
import hr from './locales/hr'
import it from './locales/it'
import nl from './locales/nl'
import no from './locales/no'
import pl from './locales/pl'
import ru from './locales/ru'
import so from './locales/so'
import sr from './locales/sr'
import sv from './locales/sv'
import uk from './locales/uk'
import zh from './locales/zh-CN'

const c = DevOps.WebApp.configToUse({ config })

Vue.use(VueI18n)

function locale() {
  return window.localStorage.getItem('selectedLocale') || c.defaultLanguage
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || locale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: {
    ar,
    da,
    de,
    en,
    es,
    fi,
    fr,
    hr,
    it,
    nl,
    no,
    pl,
    ru,
    so,
    sr,
    sv,
    uk,
    zh
  }
})
