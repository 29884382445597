export default {
  "completePassword": {
    "button": {
      "submit": "Lähetä"
    },
    "header": "Valitse uusi salasana",
    "placeholder": {
      "confirmPassword": "Vahvista salasana",
      "password": "Salasana"
    }
  },
  "dashboard": {
    "button": {
      "goToAllResults": "Kaikki tulokset",
      "myActions": "MINUN TOIMINTONI",
      "ourCompanyResults": "TULOKSEMME"
    },
    "card": {
      "body":
        "Täältä löydät ne toimenpiteet, jotka olet valinnut tehtäväksesi. Voit myös nähdä, miten tuloksesi vertautuvat muihin ryhmässäsi tai organisaatiossasi.",
      "title": "Toimenpiteet ja tulokset"
    },
    "greetings": {
      "day": "Hei {username}!"
    },
    "hello": "Hei {organizationName}!",
    "introHeadline": "TERVETULOA YRITYKSEN {organizationName} PULSIIN!",
    "settings": {
      "body":
        "Täältä voit vaihtaa käyttämäsi kielen, ja täältä löydät myös muut tiliäsi koskevat asetukset.",
      "da": "Tanska",
      "de": "Saksa",
      "en": "Englanti",
      "false": "Norja",
      "fr": "Ranska",
      "logout": {
        "button": {
          "title": "Kirjaudu ulos"
        },
        "confirmation": {
          "areyousure":
            "Jos kirjaudut ulos, sinun on sisään kirjautuessasi pyydettävä uusi kertakäyttöinen koodi.",
          "cancel": "Peruuta",
          "false": "Peruuta",
          "no": "Peruuta",
          "title": "Haluatko kirjautua ulos?",
          "true": "Kirjaudu ulos",
          "yes": "Kirjaudu ulos"
        },
        "loggedin": "Olet kirjautuneena sisään käyttäjänä {username}."
      },
      "no": "Norja",
      "sv": "Ruotsi",
      "title": "Asetukset"
    }
  },
  "error": {
    "CodeMismatchException":
      "Antamasi kertakäyttöinen koodi on virheellinen. Tarkista koodi ja yritä uudelleen.",
    "couldNotLoadResults": "Tuloksia ei voitu ladata. Yritä uudelleen.",
    "couldNotLoadSurveys": "Kyselyitä ei voitu ladata. Yritä uudelleen.",
    "emailAtLeast3Letter":
      "Sähköpostiosoitteen pitää sisältää vähintään kolme kirjainta",
    "emptyEmail": "Anna sähköpostiosoitteesi",
    "emptyOrganization": "Syötä organisaatiosi nimi",
    "emptyPassword": "Anna salasana",
    "ExpiredCodeException":
      "Antamasi kertakäyttöinen koodi on liian vanha eikä sitä turvallisuussyistä voi enää käyttää.",
    "InvalidParameterException": 'Vaadittu "Istunto"-parametri puuttuu.',
    "InvalidPasswordException": "Valitse suojatumpi salasana.",
    "LimitExceededException":
      "Liian monta epäonnistunutta yritystä. Sinun on odotettava hetki ennen kuin voit yrittää uudelleen.",
    "NotAuthorizedException":
      "Tällä hetkellä käyttäjätililläsi ei voi kirjautua sisään.",
    "notMatchPassword": "Salasanat eivät ole samat!",
    "OrganizationNotFoundException":
      "Emme löytäneet tuon nimistä järjestöä. Ystävällisesti yritä uudelleen.",
    "UserNotFoundException":
      "Emme löytäneet käyttäjätiliä, jonka nimi on {username}."
  },
  "forgotten": {
    "backToLogin": "Peruuta",
    "helptext":
      "Täältä voit palauttaa salasanasi. Saat yllä olevaan sähköpostiosoitteeseen viestin, joka sisältää koodin, jonka annat seuraavassa vaiheessa.",
    "helptext-quickstart":
      "Lähetimme sinulle väliaikaisen koodin osoitteeseen {email}. Syötä koodi alle ja valitse salasana.",
    "helptext2": "Valitse suojattu salasana.",
    "input": {
      "code": "Sähköpostista löytyvä koodi",
      "organization": "Järjestö",
      "password": "Uusi salasana",
      "username": "Sähköpostiosoite"
    },
    "requestcode": {
      "submit": "Jatka"
    },
    "setNewPassword": {
      "submit": "Aloita!"
    },
    "wait": "Ole hyvä ja odota..."
  },
  "general": {
    "defaultProductTitle": "Puls⁺"
  },
  "help": {
    "card": {
      "body":
        "Voimmeko auttaa? Klikkaa vain alla olevaa nappia siirtyäksesi apukeskukseen.",
      "title": "Apu ja tukipalvelut"
    },
    "description": "Ei hätää, me autamme.",
    "title": "APUA"
  },
  "ie": {
    "detailedText":
      "Puls-alustaa voidaan käyttää Internet Explorerilla, mutta se on vanha verkkoselain, joka ei ole enää nykyverkon standardien mukainen. Suosittelemmekin käyttämään yhtä alla olevista selaimista. Voit käyttää myös matkapuhelintasi.",
    "detailedTitle": "Tietoja Internet Explorerista",
    "readMore": "Lue lisää",
    "warning":
      "Käytät Internet Exploreria. Suosittelemme vaihtamaan toiseen verkkoselaimeen parhaan kokemuksen takaamiseksi."
  },
  "integrations": {
    "allIntegrations": "Kaikki integroinnit",
    "apps": {
      "sftp": {
        "connectionInfo": "Ladataan SFTP-palvelimen osoitteeseen {dir}.",
        "title": "SFTP"
      },
      "slack": {
        "connectionInfo": "Yhdistetty tiimin {teamName} kanavaan {channel}",
        "title": "Slack"
      }
    },
    "connectedSince": "Yhdistetty {date} lähtien",
    "empty": {
      "description":
        "Lisäämällä integroinnin voit saada Pulsin muodostamaan yhteyden omaan palveluusi. Muodosta yhteys palveluun valitsemalla alla oleva painike."
    },
    "errors": {
      "invalidIncomingIntegration":
        "Virheelliset saapuvat tiedot integrointia varten! Ei voida jatkaa."
    },
    "events": {
      "surveyCompleted": {
        "description":
          "Kun kysely on valmis, ryhmän {groupTitle} tulokset lähetetään automaattisesti integrointiin.",
        "title": "Kysely on valmis"
      },
      "title": "Valitse tapahtuma, jolloin integrointiin tulisi ottaa yhteyttä"
    },
    "groups": {
      "title": "Valitse sovellukseen {appName} yhdistettävä ryhmä"
    },
    "integrationTo": "Integrointi sovellukseen {appName}",
    "nothingSelected": "Valitse...",
    "setupIntegration": {
      "saveButtonTitle": "Tallenna",
      "title": "Määritä integrointi sovellukseen {appName}"
    },
    "title": "Integroinnit"
  },
  "login": {
    "button": {
      "forgotPassword": "Salasana unohtunut",
      "submit": "Kirjaudu sisään"
    },
    "captchaLegal": {
      "disclaimer":
        "Tätä sivustoa suojaa reCAPTCHA ja Google {toc} ja{privacyPolicy} sovelletaan.",
      "policy": "Yksityisyysasetukset",
      "toc": "Käyttöehdot"
    },
    "header": "Tervetuloa Pulsiin!",
    "placeholder": {
      "email": "Sähköpostiosoite",
      "organization": "Järjestö",
      "password": "Salasana"
    }
  },
  "navbar": {
    "welcome": "Tervetuloa"
  },
  "results": {
    "participants": "{participants} osallistujaa",
    "surveys": {
      "completed": {
        "latest": "Viimeisin vastattu kysely",
        "title": "Valmiiden kyselyiden tulokset"
      },
      "published": {
        "title": "Tarkista tulokset"
      }
    }
  },
  "setup": {
    "attributes": {
      "birthdate": {
        "info":
          "Tätä tarvitaan eri ikäryhmiin pohjautuvaa tilastointia varten.",
        "label": "Syntymäpäivä"
      },
      "birth_date": {
        "info":
          "Tätä tarvitaan eri ikäryhmiin pohjautuvaa tilastointia varten.",
        "label": "Syntymäpäivä"
      },
      "custom:employed_since": {
        "info":
          "Ajankohta jolloin työllistyit. Kyseessä ei tarvitse olla tarkka päivämäärä. Ohita valinta jos et ole työssä.",
        "label": "Työssä alkaen"
      },
      "employment_date": {
        "info":
          "Ajankohta jolloin työllistyit. Kyseessä ei tarvitse olla tarkka päivämäärä. Ohita valinta jos et ole työssä.",
        "label": "Työssä alkaen"
      },
      "family_name": {
        "label": "Sukunimi"
      },
      "gender": {
        "one": "Muu",
        "other": "Muu",
        "zero": "Muu",
        "female": "Nainen",
        "info":
          "Kysymme sukupuolta tilastointia varten. Jos haluat, voit ohittaa kohdan valitsematta mitään.",
        "label": "Valitse sukupuoli:",
        "male": "Mies",
        "wontsay": "Jätän mieluummin kertomatta"
      },
      "given_name": {
        "label": "Etunimi"
      },
      "locale": {
        "da": "Tanska",
        "de": "Saksa",
        "dk": "Tanska",
        "en": "Englanti",
        "es": "Espanja",
        "false": "Norja",
        "fr": "Ranska",
        "info":
          "Valitse kieli, jota tahtoisit käyttää. Tätä voi muuttaa myöhemminkin.",
        "label": "Valitse kieli:",
        "ms": "Malaiji",
        "no": "Norja",
        "sv": "Ruotsi"
      }
    },
    "button": {
      "submit": "Tallenna",
      "tryAgain": "Yritä uudelleen"
    },
    "error": {
      "badPassword": "Valitse turvallisempi salasana.",
      "clickLinkAgain":
        "Napsauta tervetuliaisviestistämme löytyvää linkkiä uudelleen. Ongelman jatkuessa ota yhteyttä osoitteeseen support@pulsmeter.se",
      "invalid":
        "Kyseinen linkki ei toimi enää. Napsauta sen sijaan alla olevaa:",
      "noPassword": "Salasana on valittava.",
      "passwordsMustMatch": "Salasanojen on täsmättävä.",
      "sessionError": "Kohtasimme ongelman!",
      "SSONotFound": "Organisaatiolla ei ole SSO-kokoonpanoa.",
      "unexpected": "Kohtasimme odottamattoman ongelman"
    },
    "header": "Aloita Pulsin käyttö!",
    "introText":
      "Hei{name}! Sinut on kutsuttu Pulsiin, työntekijöiden osallistamisen ja työhyvinvoinnin kehittämisen tarkoitetulle alustalle. Aktivoi tilisi täyttämällä alla oleva lomake. ",
    "introTextVolontary": "Salasana on ainoa pakollinen kenttä.",
    "login": "Jatka",
    "placeholder": {
      "confirmPassword": "Vahvista salasana",
      "newPassword": "Valitse salasana"
    }
  },
  "start": {
    "button": {
      "getOrganizationData": {
        "title": "Jatka"
      },
      "requestCode": {
        "title": "Hae kertakäyttöinen koodi"
      },
      "submitCode": {
        "title": "Kirjaudu sisään"
      }
    },
    "confirmRequestCode": {
      "header": {
        "description":
          "Haluamme vain varmistaa, että olet oikea henkilö! Jatkaessasi eteenpäin lähetämme sinulle kertakäyttöisen koodin. Käytä sitä seuraavassa vaiheessa kirjautuaksesi Pulsiin organisaatiossa {organizationName}.",
        "help":
          "Olet hiljattain pyytänyt koodin. Varmista, että syötät koodin siihen ikkunaan, jossa sitä pyysit. Jos jatkat tässä eteenpäin, sinulle lähetetään uusi koodi."
      }
    },
    "enterCode": {
      "form": {
        "code": {
          "placeholder": "kertakäyttöinen koodi"
        }
      },
      "header": {
        "deliveryMedium": {
          "EMAIL": "sähköposti",
          "SMS": "tekstiviesti"
        },
        "description":
          "Kertakäyttöinen koodi on lähetetty sinulle käyttäen lähetystapaa {deliveryMedium}. Jätä tämä ikkuna auki ja palaa syöttämään koodi alle:",
        "title": "Tervetuloa Pulsiin!"
      },
      "help": {
        "noCodeDescriptionEmail":
          "Koodi on lähetetty viestillä sähköpostiosoitteeseen, joka sisältää '{destination}'. Koodi tulee parin minuutin sisällä. Jos et näe viestiä, tarkista roskapostisi. Muista pitää tämä ikkuna avoinna ja syötä koodi ylös.",
        "noCodeDescriptionSMS":
          "Koodi on lähetetty tekstiviestinä kännykkääsi ja puhelinnumeroon, joka sisältää '{destination}'. Koodi tulee parin minuutin sisällä. Jos et tunnista puhelinnumeroa, ota yhteys ylläpitäjään.",
        "noCodeLinkTitle": "Etkö saanut koodia?",
        "noCodeStartOverLinkTitle": "Aloita uudelleen"
      }
    },
    "enterEmail": {
      "form": {
        "username": {
          "placeholder": "sähköpostiosoite"
        }
      },
      "header": {
        "description":
          "Syötä organisaatioon {organizationName} liittyvä sähköpostiosoitteesi:"
      }
    },
    "enterOrganization": {
      "form": {
        "organization": {
          "placeholder": "organisaation nimi"
        }
      },
      "header": {
        "description": "Aloita nimeämällä organisaatio, johon kuulut.",
        "invalid": {
          "spaces": "Älä käytä välilyöntejä.",
          "specialchars": "Käytä vain kirjaimia.",
          "uppercase": "Käytä vain pieniä kirjaimia."
        }
      }
    }
  },
  "surveys": {
    "description": "Seuraavat kyselyt ovat avoinna tällä hetkellä:",
    "descriptionOneSurveyOnly": "Aloita valitsemalla kysely alta!",
    "descriptionOneSurveyOnlyUserDone":
      "Kysely on yhä meneillään. Valitse se nähdäksesi valitsemasi aktiviteetit.",
    "emptyResult":
      "Tällä hetkellä sinulle ei ole kyselyitä täytettäväksi. Voit tarkastella toimiasi tai tuloksiasi alta.",
    "ended": "päättyi",
    "endsIn": "päättyy",
    "retry": "Yritä uudelleen",
    "title": "Kyselyt",
    "youreDone": "Olet valmis"
  },
  "surveysToFillIn": {
    "description": "Seuraavat kyselyt ovat nyt meneillään:",
    "descriptionOneSurveyOnly": "Aloita valitsemalla kysely alta!",
    "descriptionOneSurveyOnlyUserDone":
      "Kysely on yhä meneillään. Valitse se nähdäksesi valitsemasi toimenpiteet.",
    "emptyResult":
      "Tällä hetkellä ei ole vastaamistasi odottavia kyselyjä. Alla voit tarkastella toimenpiteitäsi/kommenttejasi tai tuloksiasi.",
    "ended": "päättynyt",
    "endsIn": "päättyy",
    "retry": "Yritä uudelleen",
    "title": "Kysely odottaa vastaamistasi | Sinua odottaa {count} kyselyä",
    "youreDone": "Olet valmis"
  },
  "user": {
    "attributes": {
      "birthdate": {
        "info":
          "Tätä tarvitaan eri ikäryhmiin pohjautuvaa tilastointia varten.",
        "label": "Syntymäpäivä"
      },
      "birth_date": {
        "info":
          "Tätä tarvitaan eri ikäryhmiin pohjautuvaa tilastointia varten.",
        "label": "Syntymäpäivä"
      },
      "custom:employed_since": {
        "info":
          "Ajankohta jolloin työllistyit. Kyseessä ei tarvitse olla tarkka päivämäärä. Ohita valinta jos et ole työssä.",
        "label": "Työssä alkaen"
      },
      "employment_date": {
        "info":
          "Ajankohta jolloin työllistyit. Kyseessä ei tarvitse olla tarkka päivämäärä. Ohita valinta jos et ole työssä.",
        "label": "Työssä alkaen"
      },
      "family_name": {
        "label": "Sukunimi"
      },
      "gender": {
        "one": "Muu",
        "other": "Muu",
        "zero": "Muu",
        "female": "Nainen",
        "info":
          "Kysymme sukupuolta tilastoinnin vuoksi. Jos haluat, voit ohittaa kohdan valitsematta mitään.",
        "label": "Valitse sukupuoli:",
        "male": "Mies",
        "wontsay": "Jätän mieluummin kertomatta"
      },
      "given_name": {
        "label": "Etunimi"
      },
      "locale": {
        "da": "Tanska",
        "de": "Saksa",
        "en": "Englanti",
        "es": "Espanja",
        "false": "Norja",
        "fi": "Suomi",
        "fr": "Ranska",
        "info":
          "Valitse kieli, jota tahtoisit käyttää. Tätä voi muuttaa myöhemminkin.",
        "it": "Italia",
        "label": "Valitse kieli:",
        "ms": "Malaiji",
        "nl": "Hollannin kieli",
        "no": "Norja",
        "pl": "Puola",
        "sv": "Ruotsi"
      }
    },
    "button": {
      "cancel": "Peruuta",
      "skip": "Siirry takaisin",
      "submit": "Tallenna",
      "tryAgain": "Yritä uudelleen"
    },
    "error": {
      "badPassword": "Valitse turvallisempi salasana.",
      "clickLinkAgain":
        "Napsauta tervetuliaisviestistämme löytyvää linkkiä uudelleen. Ongelman jatkuessa ota yhteyttä osoitteeseen support@pulsmeter.se",
      "invalid":
        "Kyseinen linkki ei toimi enää. Napsauta sen sijaan alla olevaa:",
      "noPassword": "Salasana on valittava.",
      "passwordsMustMatch": "Salasanojen on täsmättävä.",
      "sessionError": "Kohtasimme ongelman!",
      "SSONotFound": "Organisaatiolla ei ole SSO-kokoonpanoa.",
      "unexpected": "Kohtasimme odottamattoman ongelman"
    },
    "header": "Käyttäjäasetukset",
    "introText": "Tarkista, että käyttäjäprofiilisi on kunnossa.",
    "introTextVolontary":
      "Alla olevien tietojen täyttäminen on täysin vapaaehtoista.",
    "login": "Jatka"
  },
  "whistleblower": {
    "buttons": {
      "cancel": "Peruuta",
      "done": "Ok",
      "send": "Lähetä nimettömänä"
    },
    "description":
      "Tarjoamme sinulle mahdollisuuden lähettää anonyymisti ilmoituksen laittomasta toiminnasta tai rikkomuksesta, jonka olet havainnut organisaatiossa {organizationName}.",
    "detailedDescription":
      "Näin se toimii. Kirjoitat ilmoituksesi alla olevaan tekstikenttään. Kun lähetät ilmoituksen, sähköpostiviesti lähetetään ennalta määrätylle vastaanottajalle, joka on vastuussa toimenpiteistä organisaatiossasi. Vastaanottaja ei pysty näkemään, että sinä olet lähettänyt viestin. Hän näkee vain sen, että se on saapunut Puls-alustan kautta.  Jos haluat että sinuun otetaan yhteyttä, varmista että mainitset siitä viestissäsi. Jos haluat pysyä nimettömänä, voit käyttää kertakäyttöistä (väliaikaista) sähköpostiosoitetta tai matkapuhelimen SIM-korttia.",
    "doneText":
      "Kiitos ilmoituksesi lähettämisestä. Suhtaudumme siihen vakavasti. Jos olet antanut yhteystietosi, otamme sinuun yhteyttä mahdollisimman pian!",
    "linkTitle": "Lue lisää täältä",
    "linkToHelp": "https://www.puls-solutions.com/help/TODO",
    "notEnabled": {
      "description":
        "Pahoittelumme. Organisaatiosi ei ole ottanut käyttöön ilmianto-ominaisuutta.",
      "title": "Ei saatavilla"
    },
    "placeholder":
      "Kirjoita ilmoituksesi tähän. Kirjoita mahdollisimman yksityiskohtaisesti ja kerro meille, haluatko että sinuun otetaan yhteyttä ja miten.",
    "title": "Lähetä nimetön ilmoitus"
  }
}
