export default {
  "completePassword": {
    "button": {
      "submit": "Verzenden"
    },
    "header": "Kies een nieuw wachtwoord",
    "placeholder": {
      "confirmPassword": "Bevestig wachtwoord",
      "password": "Wachtwoord"
    }
  },
  "dashboard": {
    "button": {
      "goToAllResults": "Alle resultaten",
      "myActions": "MIJN ACTIES",
      "ourCompanyResults": "ONZE RESULTATEN"
    },
    "card": {
      "body":
        "Hier vind je de persoonlijke acties die jij hebt gekozen om te doen. Je kunt ook bekijken hoe jouw resultaten zijn in vergelijking met anderen in jouw groep of organisatie.",
      "title": "Activiteiten en resultaten"
    },
    "hello": "Hallo {username}!",
    "introHeadline": "WELKOM BIJ PULSE VOOR {organizationName}!",
    "settings": {
      "body":
        "Hier kun je de gebruikte taal en andere instellingen van je account wijzigen.",
      "da": "Deens",
      "de": "Duits",
      "en": "Engels",
      "false": "Noors",
      "fr": "Frans",
      "logout": {
        "button": {
          "title": "Uitloggen"
        },
        "confirmation": {
          "areyousure":
            "Als je uitlogt, dien je een nieuwe eenmalige code aan te vragen om weer in te loggen.",
          "cancel": "Annuleren",
          "false": "Annuleren",
          "no": "Annuleren",
          "title": "Wil je uitloggen?",
          "true": "Uitloggen",
          "yes": "Uitloggen"
        },
        "loggedin": "Je bent ingelogd als {username}."
      },
      "no": "Noors",
      "sv": "Zweeds",
      "title": "Instellingen"
    }
  },
  "error": {
    "CodeMismatchException":
      "De eenmalige code die je hebt ingevoerd is ongeldig. Controleer de code en probeer het opnieuw.",
    "couldNotLoadResults": "Kon resultaten niet laden, probeer het nogmaals.",
    "couldNotLoadSurveys": "Kon enquêtes niet laden, probeer het nogmaals.",
    "emailAtLeast3Letter": "E-mailadres dient ten minste 3 letters te bevatten",
    "emptyEmail": "Voer je e-mailadres in",
    "emptyOrganization": "Voer de naam van je organisatie in",
    "emptyPassword": "Voer wachtwoord in",
    "ExpiredCodeException":
      "De eenmalige code die je hebt ingevoerd is te oud en kan om veiligheidsredenen niet worden gebruikt.",
    "InvalidParameterException": "Ontbrekende vereiste parameter Sessie.",
    "InvalidPasswordException": "Gelieve een veiliger wachtwoord te kiezen.",
    "LimitExceededException":
      "Teveel mislukte pogingen. Je dient even te wachten voordat je het opnieuw probeert.",
    "NotAuthorizedException":
      "Het is momenteel niet mogelijk om met je gebruikersaccount in te loggen.",
    "notMatchPassword": "Wachtwoorden komen niet overeen!",
    "OrganizationNotFoundException":
      "We hebben geen organisatie met die naam kunnen vinden, probeer het nogmaals.",
    "UserNotFoundException":
      "We hebben geen gebruikersaccount met de gebruikersnaam {username} kunnen vinden."
  },
  "forgotten": {
    "backToLogin": "Annuleren",
    "helptext":
      "Hier kun je je wachtwoord resetten. Je ontvangt een e-mail op het bovenstaande e-mailadres waarin een code staat die je bij de volgende stap dient in te voeren.",
    "helptext-quickstart":
      "We hebben je zojuist een tijdelijke code gestuurd naar {email}. Voer het hieronder in, en kies een wachtwoord.",
    "helptext2": "Kies een veilig wachtwoord",
    "input": {
      "code": "Code gevonden in e-mail",
      "organization": "Organisatie",
      "password": "Nieuw wachtwoord",
      "username": "E-mailadres"
    },
    "requestcode": {
      "submit": "Doorgaan"
    },
    "setNewPassword": {
      "submit": "Start!"
    },
    "wait": "Een ogenblik..."
  },
  "general": {
    "defaultProductTitle": "Puls⁺"
  },
  "help": {
    "card": {
      "body":
        "Is er iets waarmee we je kunnen helpen? Klik dan op de onderstaande knop om naar het helpcenter te gaan.",
      "title": "Help en ondersteuning"
    },
    "description": "Geen zorgen, we staan voor je klaar.",
    "title": "HELP"
  },
  "ie": {
    "detailedText":
      "Het Puls platform kan met Internet Explorer worden gebruikt maar het is een oude webbrowser die niet voldoet aan de moderne maatstaven van het web. Daarom raden we je aan één van de onderstaande browsers te gebruiken. Je kunt ook je mobiele telefoon gebruiken.",
    "detailedTitle": "Over Internet Explorer",
    "readMore": "Meer lezen",
    "warning":
      "Je maakt gebruik van Internet Explorer. We raden je aan om een andere webbrowser te gebruiken voor de beste ervaring."
  },
  "integrations": {
    "allIntegrations": "Alle Integraties",
    "apps": {
      "sftp": {
        "connectionInfo": "Zal uploaden naar {dir} op SFTP-server.",
        "title": "SFTP"
      },
      "slack": {
        "connectionInfo": "Gekoppeld aan {teamName} kanaal {channel}",
        "title": "Slack"
      }
    },
    "connectedSince": "Gekoppeld sinds {date}",
    "empty": {
      "description":
        "Door een integratie toe te voegen kun je Puls koppelen aan je eigen service. Selecteer de onderstaande knop om een service te koppelen."
    },
    "errors": {
      "invalidIncomingIntegration":
        "Inkomende data voor integratie is ongeldig! Kan niet verdergaan."
    },
    "events": {
      "surveyCompleted": {
        "description":
          "Wanneer een enquête wordt afgerond, worden de resultaten voor {groupTitle} automatisch naar de integratie verstuurd.",
        "title": "Enquête afgerond"
      },
      "title":
        "Kies een gebeurtenis waarbij contact moet worden opgenomen met de integratie"
    },
    "groups": {
      "title": "Kies een groep om deze te koppelen aan {appName}"
    },
    "integrationTo": "Integratie met {appName}",
    "nothingSelected": "Kies...",
    "setupIntegration": {
      "saveButtonTitle": "Opslaan",
      "title": "Stel integratie in met {appName}"
    },
    "title": "Integraties"
  },
  "login": {
    "button": {
      "forgotPassword": "Wachtwoord vergeten",
      "submit": "Inloggen"
    },
    "captchaLegal": {
      "disclaimer":
        "Deze website is beschermd door reCAPTCHA en de Google {toc} en {privacyPolicy} zijn van toepassing.",
      "policy": "Privacybeleid",
      "toc": "Algemene Voorwaarden"
    },
    "header": "Welkom bij Puls!",
    "placeholder": {
      "email": "E-mailadres",
      "organization": "Organisatie",
      "password": "Wachtwoord"
    }
  },
  "navbar": {
    "welcome": "Welkom"
  },
  "results": {
    "participants": "{participants} deelnemers",
    "surveys": {
      "completed": {
        "latest": "Meest recente voltooide enquête",
        "title": "Resultaten van voltooide enquêtes"
      },
      "published": {
        "title": "Bekijk resultaten"
      }
    }
  },
  "setup": {
    "attributes": {
      "birthdate": {
        "info":
          "Dit is nodig om statistieken te kunnen verzamelen gebaseerd op verschillende leeftijdsgroepen.",
        "label": "Geboortedatum"
      },
      "birth_date": {
        "info":
          "Dit is nodig om statistieken te verzamelen op basis van verschillende leeftijdsgroepen.",
        "label": "Geboortedatum"
      },
      "custom:employed_since": {
        "info":
          "De datum waarop je bent aangenomen. Het hoeft niet de exacte datum te zijn. Sla keuze maken over als je niet in dienst bent.",
        "label": "In dienst sinds"
      },
      "employment_date": {
        "info":
          "De datum waarop je bent aangenomen. Het hoeft niet de exacte datum te zijn. Sla keuze maken over indien je niet in dienst bent.",
        "label": "In dienst sinds"
      },
      "family_name": {
        "label": "Achternaam"
      },
      "gender": {
        "one": "Andere",
        "other": "Andere",
        "zero": "Andere",
        "female": "Vrouw",
        "info":
          "We vragen naar het geslacht zodat het kan worden meegenomen in de statistieken. Je hoeft niets in te voeren als je dat niet wilt.",
        "label": "Kies geslacht",
        "male": "Man",
        "wontsay": "Zeg ik liever niet"
      },
      "given_name": {
        "label": "Voornaam"
      },
      "locale": {
        "da": "Deens",
        "de": "Duits",
        "dk": "Deens",
        "en": "Engels",
        "es": "Spaans",
        "false": "Noors",
        "fr": "Frans",
        "info":
          "Kies een taal die je het liefst gebruikt. Dit kan later gewijzigd worden.",
        "label": "Kies taal:",
        "ms": "Maleis",
        "no": "Noors",
        "sv": "Zweeds"
      }
    },
    "button": {
      "submit": "Opslaan",
      "tryAgain": "Probeer nogmaals"
    },
    "error": {
      "badPassword": "Gelieve een veiliger wachtwoord te kiezen.",
      "clickLinkAgain":
        "Gelieve nogmaals op de link in de welkomstmail te klikken. Indien het probleem zich blijft voordoen, neem dan contact op met support@pulsmeter.se",
      "invalid":
        "Die link is niet meer geldig, klik in plaats daarvan hieronder:",
      "noPassword": "Je moet een wachtwoord kiezen.",
      "passwordsMustMatch": "Wachtwoorden dienen overeen te komen.",
      "sessionError": "We zijn een probleem tegen gekomen!",
      "SSONotFound": "Er is geen SSO configuratie voor de organisatie.",
      "unexpected": "Er is een onverwacht probleem ontstaan"
    },
    "header": "Beginnen met Puls!",
    "introText":
      "Hallo{name}! Je bent uitgenodigd voor Puls, een platform voor medewerkersbetrokkenheid. Activeer je account door het onderstaande formulier in te vullen. ",
    "introTextVolontary":
      "Alleen het wachtwoord dient verplicht te worden ingevoerd.",
    "login": "Doorgaan",
    "placeholder": {
      "confirmPassword": "Bevestig het wachtwoord",
      "newPassword": "Kies een wachtwoord"
    }
  },
  "start": {
    "button": {
      "getOrganizationData": {
        "title": "Doorgaan"
      },
      "requestCode": {
        "title": "Haal eenmalige code op"
      },
      "submitCode": {
        "title": "Inloggen"
      }
    },
    "confirmRequestCode": {
      "header": {
        "description":
          "We willen er eerst zeker van zijn dat je de juiste persoon bent! Wanneer je verder gaat, wordt er een eenmalige code verstuurd. Gebruik het bij de volgende stap op in te loggen bij Puls voor {organizationName}.",
        "help":
          "Je hebt recent om een code gevraagd. Zorg ervoor dat je de code invoert in het venster waar je het hebt gevraagd. Als je hier verder gaat, wordt er een nieuwe code verzonden."
      }
    },
    "enterCode": {
      "form": {
        "code": {
          "placeholder": "eenmalige code"
        }
      },
      "header": {
        "deliveryMedium": {
          "EMAIL": "e-mail",
          "SMS": "sms-bericht"
        },
        "description":
          "Er is een eenmalige code naar je gestuurd via {deliveryMedium}. Laat dit venster open en kom hier terug om de code hieronder in te voeren:",
        "title": "Welkom bij Puls!"
      },
      "help": {
        "noCodeDescriptionEmail":
          "De code is naar je gemaild op het e-mailadres met '{destination}'. Het kan enkele minuten duren voordat je de code ontvangt. Controleer de map met ongewenste e-mail als je het mailtje niet kan vinden. Laat dit venster openstaan en vul de bovenstaande code in.",
        "noCodeDescriptionSMS":
          "De code is als tekstbericht naar je mobiele telefoon verstuurd via het telefoonnummer met '{destination}'. Het kan enkele minuten duren voordat je de code ontvangt. Neem contact op met je beheerder als je het telefoonnummer niet herkent.",
        "noCodeLinkTitle": "Heb je geen code ontvangen?",
        "noCodeStartOverLinkTitle": "Begin opnieuw"
      }
    },
    "enterEmail": {
      "form": {
        "username": {
          "placeholder": "e-mailadres"
        }
      },
      "header": {
        "description": "Voer je e-mailadres is op {organizationName}:"
      }
    },
    "enterOrganization": {
      "form": {
        "organization": {
          "placeholder": "naam van de organisatie"
        }
      },
      "header": {
        "description":
          "Begin met het invoeren van de naam van de organisatie waar je bij hoort.",
        "invalid": {
          "spaces": "Gebruik geen spaties.",
          "specialchars": "Gebruik alleen letters.",
          "uppercase": "Gebruik alleen kleine letters."
        }
      }
    }
  },
  "surveys": {
    "description": "Deze enquêtes zijn nu lopende:",
    "descriptionOneSurveyOnly":
      "Selecteer de enquête hieronder om te beginnen!",
    "descriptionOneSurveyOnlyUserDone":
      "De enquête is nog lopende. Selecteer het om de activiteiten te bekijken die je hebt gekozen.",
    "emptyResult":
      "Op dit moment is er geen enquête voor jou om in te vullen. Je kunt je acties of resultaten hieronder bekijken.",
    "ended": "is geëindigd",
    "endsIn": "eindigt",
    "retry": "Probeer opnieuw",
    "title": "Enquêtes",
    "youreDone": "Je bent klaar"
  },
  "surveysToFillIn": {
    "description": "Deze enquêtes lopen momenteel nog:",
    "descriptionOneSurveyOnly":
      "Selecteer de enquête hieronder om te beginnen!",
    "descriptionOneSurveyOnlyUserDone":
      "De enquête loopt nog. Selecteer het om de door jou gekozen acties te bekijken.",
    "emptyResult":
      "Op dit moment heb je geen enquêtes om in te vullen. Je kunt je acties/opmerkingen of resultaten hieronder bekijken.",
    "ended": "geëindigd",
    "endsIn": "eindigt",
    "retry": "Probeer het nogmaals",
    "title":
      "Je hebt een enquête om in te vullen|Er staan {count} enquêtes voor je klaar",
    "youreDone": "Je bent klaar"
  },
  "user": {
    "attributes": {
      "birthdate": {
        "info":
          "Dit is nodig om statistieken te kunnen verzamelen gebaseerd op verschillende leeftijdsgroepen.",
        "label": "Geboortedatum"
      },
      "birth_date": {
        "info":
          "Dit is nodig om statistieken te kunnen verzamelen gebaseerd op verschillende leeftijdsgroepen.",
        "label": "Geboortedatum"
      },
      "custom:employed_since": {
        "info":
          "De datum waarop je bent aangenomen. Het hoeft niet de exacte datum te zijn. Sla keuze maken over als je niet in dienst bent.",
        "label": "In dienst sinds"
      },
      "employment_date": {
        "info":
          "De datum waarop je bent aangenomen. Het hoeft niet de exacte datum te zijn. Sla keuze maken over als je niet in dienst bent.",
        "label": "In dienst sinds"
      },
      "family_name": {
        "label": "Achternaam"
      },
      "gender": {
        "one": "Andere",
        "other": "Andere",
        "zero": "Andere",
        "female": "Vrouw",
        "info":
          "We vragen om het geslacht zodat het in de statistieken kan worden meegenomen. Je hoeft niets te selecteren als je dat niet wilt.",
        "label": "Kies geslacht:",
        "male": "Man",
        "wontsay": "Zwg ik liever niet"
      },
      "given_name": {
        "label": "Voornaam"
      },
      "locale": {
        "da": "Deens",
        "de": "Duits",
        "en": "Engels",
        "es": "Spaans",
        "false": "Noors",
        "fi": "Fins",
        "fr": "Frans",
        "info":
          "Kies de taal die je het liefst gebruikt. Dit kan later gewijzigd worden.",
        "it": "Italiaans",
        "label": "Kies taal:",
        "ms": "Maleis",
        "nl": "Nederlands",
        "no": "Noors",
        "pl": "Pools",
        "sv": "Zweeds"
      }
    },
    "button": {
      "cancel": "Annuleren",
      "skip": "Ga terug",
      "submit": "Opslaan",
      "tryAgain": "Probeer nogmaals"
    },
    "error": {
      "badPassword": "Gelieve een veiliger wachtwoord te kiezen.",
      "clickLinkAgain":
        "Gelieve nogmaals op de link in de welkomstmail te klikken. Indien het probleem blijft bestaan, neem dan contact op met support@pulsmeter.se",
      "invalid":
        "Die link is niet meer geldig, klik in plaats daarvan hieronder:",
      "noPassword": "Je moet een wachtwoord kiezen.",
      "passwordsMustMatch": "Wachtwoorden moeten overeenkomen.",
      "sessionError": "We zijn een probleem tegengekomen!",
      "SSONotFound": "Er is geen SSO configuratie voor de organisatie.",
      "unexpected": "Er is een onverwacht probleem opgetreden"
    },
    "header": "Gebruikersinstellingen",
    "introText": "Controleer of je gebruikersprofiel juist is.",
    "introTextVolontary":
      "Het invullen van de informatie hieronder is vrijwillig.",
    "login": "Doorgaan"
  },
  "whistleblower": {
    "buttons": {
      "cancel": "Annuleren",
      "done": "OK",
      "send": "Anoniem verzenden"
    },
    "description":
      "Wij bieden je de mogelijkheid om een anonieme melding te doen van onwettig gedrag of misdrijven waarvan je getuige bent geweest bij {organizationName}.",
    "detailedDescription":
      "Het werkt als volgt. Je voert je melding in het tekstvak hieronder in. Wanneer je het rapport verstuurt, wordt er een e-mail verzonden naar een aangewezen ontvanger binnen jouw organisatie die verantwoordelijk is voor het ondernemen van actie. De ontvanger kan niet zien dat het bericht door jou is verzonden, alleen dat het afkomstig is van het Puls-platform.  Als je wilt dat er contact met je wordt opgenomen, zorg er dan voor dat je informatie daarover in je bericht opneemt. Als je anoniem wilt blijven, kun je een tijdelijk e-mailadres of wegwerp-SIM-kaart voor je mobiele telefoon gebruiken.",
    "doneText":
      "Bedankt voor het insturen van je melding. We zullen deze serieus nemen. Als je contactinformatie hebt verstrekt, nemen wij zo spoedig mogelijk contact met je op!",
    "linkTitle": "Meer informatie vind je hier",
    "linkToHelp": "https://www.puls-solutions.com/help/TODO",
    "notEnabled": {
      "description":
        "Excuses. De klokkenluidersfunctie is niet geactiveerd door jouw organisatie.",
      "title": "Niet beschikbaar"
    },
    "placeholder":
      "Voer je melding hier in. Wees zo specifiek mogelijk en laat ons weten of en hoe je gecontacteerd wilt te worden.",
    "title": "Anonieme melding sturen"
  }
}
