export default {
  "completePassword": {
    "button": {
      "submit": "Send"
    },
    "header": "Velg et nytt passord",
    "placeholder": {
      "confirmPassword": "Bekreft passord",
      "password": "Passord"
    }
  },
  "dashboard": {
    "button": {
      "goToAllResults": "Alle resultater",
      "myActions": "MINE OPPGAVER",
      "ourCompanyResults": "VÅRE RESULTATER"
    },
    "card": {
      "body":
        "Her kan du finne dine egne personlige oppgaver som du har valgt å gjøre. Du kan også se hvordan resultatene dine er sammenlignet med andre i gruppen eller organisasjonen din.",
      "title": "Aktiviteter og resultater"
    },
    "hello": "Hei {brukernavn}!",
    "introHeadline": "VELKOMMEN TIL PULS PÅ {organizationName}!",
    "settings": {
      "body": "Her kan du endre valgt språk.",
      "da": "Dansk",
      "de": "Tysk",
      "en": "Engelsk",
      "false": "Norsk",
      "fr": "Fransk",
      "logout": {
        "button": {
          "title": "Logg ut"
        },
        "confirmation": {
          "areyousure":
            "Dersom du logger ut må du be om en ny engangskode for å logge inn igjen.",
          "cancel": "Avbryt",
          "false": "Avbryt",
          "no": "Avbryt",
          "title": "Vil du logge ut?",
          "true": "Logg ut",
          "yes": "Logg ut"
        },
        "loggedin": "Du er logget inn som {brukernavn}."
      },
      "no": "Norsk",
      "sv": "Svensk",
      "title": "Innstillinger"
    }
  },
  "error": {
    "CodeMismatchException":
      "Engangskoden du skrev inn er ugyldig. Vennligst dobbeltsjekk og prøv på nytt.",
    "couldNotLoadResults":
      "Kunne ikke lade resultater, vennligst prøv på nytt.",
    "couldNotLoadSurveys":
      "Kunne ikke lade undersøkelser, vennligst prøv på nytt.",
    "emailAtLeast3Letter": "E-postadressen må inneholde minst 3 bokstaver",
    "emptyEmail": "Skriv inn e-postadresse",
    "emptyOrganization": "Skriv inn organisasjonen din sitt navn",
    "emptyPassword": "Skriv inn et passord",
    "ExpiredCodeException":
      "Engangskoden du skrev inn er for gammel og kan av sikkerhetsmessige årsaker ikke brukes.",
    "InvalidParameterException": "Mangler nødvendig økt for parametre.",
    "InvalidPasswordException": "Vennligst velg et sikrere passord.",
    "LimitExceededException":
      "For mange innloggingsforsøk. Du må vente et øyeblikk før du kan prøve på nytt.",
    "NotAuthorizedException":
      "Det er for øyeblikket ikke mulig å logge inn med din brukerkonto.",
    "notMatchPassword": "Passordene matcher ikke!",
    "OrganizationNotFoundException":
      "Vi kunne ikke finne en organsisasjon med det navnet, vennligst prøv på nytt.",
    "UserNotFoundException":
      "Vi kunne ikke finne en registrert bruker med {username}."
  },
  "forgotten": {
    "backToLogin": "Avbryt",
    "helptext":
      "Her kan du tilbakestille passordet ditt. Du kommer til å motta en e-post til e-postadressen ovenfor med en kode som du kan skrive inn ved neste skritt.",
    "helptext-quickstart":
      "Vi har akkurat sendt deg en midlertidig kode til {e-post}. Skriv den inn nedenfor og velg et passord.",
    "helptext2": "Velg et sikkert passord.",
    "input": {
      "code": "Kode funnet i e-post",
      "organization": "Organisasjon",
      "password": "Nytt passord",
      "username": "E-postadresse"
    },
    "requestcode": {
      "submit": "Fortsett"
    },
    "setNewPassword": {
      "submit": "Begynn!"
    },
    "wait": "Vennligst vent..."
  },
  "general": {
    "defaultProductTitle": "Puls⁺"
  },
  "help": {
    "card": {
      "body":
        "Er det noe vi kan hjelpe deg med? Bare klikk på tasten nedenfor for å gå til brukerstøtten.",
      "title": "Hjelp og støtte"
    },
    "description": "Ikke vær bekymret, vi er der for deg.",
    "title": "HJELP"
  },
  "ie": {
    "detailedText":
      "Puls-plattformen kan brukes med Internet Explorer, men det er en gammel nettleser som ikke samsvarer med de moderne standardene på nettet. Vi anbefaler derfor at du bruker en av nettleserne nedenfor. Du kan også bruke mobiltelefonen din.",
    "detailedTitle": "Om Internet Explorer",
    "readMore": "Les mer",
    "warning":
      "Du bruker Internet Explorer. Vi anbefaler at du bytter nettleser for en bedre brukeropplevelse."
  },
  "integrations": {
    "allIntegrations": "Alle integrasjoner",
    "apps": {
      "sftp": {
        "connectionInfo": "Lastes opp til {dir} på SFTP -serveren.",
        "title": "SFTP"
      },
      "slack": {
        "connectionInfo": "Koblet til {teamName} kanal {channel}",
        "title": "Slakk"
      }
    },
    "connectedSince": "Tilkoblet siden {date}",
    "empty": {
      "description":
        "Ved å legge til en integrasjon kan du få Puls til å koble til din egen tjeneste. Velg knappen nedenfor for å koble til en tjeneste."
    },
    "errors": {
      "invalidIncomingIntegration":
        "Ugyldige innkommende data for integrering! Kan ikke fortsette"
    },
    "events": {
      "surveyCompleted": {
        "description":
          "Når en undersøkelse er fullført, blir resultatene for {groupTitle} automatisk sendt til integrering.",
        "title": "Undersøkelse er fullført"
      },
      "title": "Velg hendelse når integrering skal kontaktes"
    },
    "groups": {
      "title": "Velg gruppe for å koble til {appName}"
    },
    "integrationTo": "Integrasjon til {appName}",
    "nothingSelected": "Velg ...",
    "setupIntegration": {
      "saveButtonTitle": "Lagre",
      "title": "Konfigurer integrasjon med {appName}"
    },
    "title": "Integrasjoner"
  },
  "login": {
    "button": {
      "forgotPassword": "Glemt passord",
      "submit": "Logg inn"
    },
    "captchaLegal": {
      "disclaimer":
        "Denne siden er beskyttet av reCAPTCHA og Google {toc} og {regler for personvern} gjelder.",
      "policy": "Personvern",
      "toc": "Bruksbetingelser"
    },
    "header": "Velkommen til Puls!",
    "placeholder": {
      "email": "E-postadresse",
      "organization": "Organisasjon",
      "password": "Passord"
    }
  },
  "navbar": {
    "welcome": "Velkommen"
  },
  "results": {
    "participants": "{participants} deltakere",
    "surveys": {
      "completed": {
        "latest": "Sist fullførte undersøkelse",
        "title": "Resultater for fullførte undersøkelser"
      },
      "published": {
        "title": "Sjekk resultater"
      }
    }
  },
  "setup": {
    "attributes": {
      "birthdate": {
        "info":
          "Dette er nødvendig for å samle statistikk basert på forskjellige aldersgrupper.",
        "label": "Fødselsdato"
      },
      "birth_date": {
        "info":
          "Dette er nødvendig for å samle statistikk basert på forskjellige aldersgrupper.",
        "label": "Fødselsdato"
      },
      "custom:employed_since": {
        "info":
          "Tidsperioden du har vært ansatt. Det behøver ikke være en nøyaktig dato. Hopp over dette valget dersom du ikke er ansatt et sted.",
        "label": "Ansatt siden"
      },
      "employment_date": {
        "info":
          "Tidsperioden du har vært ansatt. Det behøver ikke være en nøyaktig dato. Hopp over dette valget dersom du ikke er ansatt et sted.",
        "label": "Ansatt siden"
      },
      "family_name": {
        "label": "Etternavn"
      },
      "gender": {
        "one": "Andre",
        "other": "Andre",
        "zero": "Andre",
        "female": "Kvinne",
        "info":
          "Vi spør om kjønn slik at det kan inkluderes i statistikken. Du trenger ikke velge noe dersom du ikke ønsker det.",
        "label": "Velg kjønn:",
        "male": "Mann",
        "wontsay": "Foretrekker å ikke angi"
      },
      "given_name": {
        "label": "Fornavn"
      },
      "locale": {
        "da": "Dansk",
        "de": "Tysk",
        "dk": "Dansk",
        "en": "Engelsk",
        "es": "Spansk",
        "false": "Norsk",
        "fr": "Fransk",
        "info": "Velg ønsket språk. Dette kan også endres senere.",
        "label": "Velg språk:",
        "ms": "Malayisk",
        "no": "Norsk",
        "sv": "Svensk"
      }
    },
    "button": {
      "submit": "Lagre",
      "tryAgain": "Prøv på nytt"
    },
    "error": {
      "badPassword": "Vennligst velg et sikrere passord.",
      "clickLinkAgain":
        "Vennligst klikk på lenken i velkomst-eposten en gang til. Vennligst kontakt support@pulsmeter.se dersom problemet vedvarer",
      "invalid": "Det er ikke gyldig lenger, klikk nederst istedenfor:",
      "noPassword": "Du må velge et passord.",
      "passwordsMustMatch": "Passordet må matche.",
      "sessionError": "Vi har støtt på et problem!",
      "SSONotFound": "Det finnes ingen SSO-konfigurasjon for organisasjonen.",
      "unexpected": "Det er oppstått et uventet problem"
    },
    "header": "Kom igang med Puls!",
    "introText":
      "Hei {navn}! Du har blitt invitert til Puls, en plattform for kollegaengasjement. Aktiver kontoen din ved å fylle ut skjemaet nedenfor. ",
    "introTextVolontary": "Kun passord er obligatorisk å fylle ut.",
    "login": "Fortsett",
    "placeholder": {
      "confirmPassword": "Bekreft passordet",
      "newPassword": "Velg et passord"
    }
  },
  "start": {
    "button": {
      "getOrganizationData": {
        "title": "Fortsett"
      },
      "requestCode": {
        "title": "Skaff en engangskode"
      },
      "submitCode": {
        "title": "Logg inn"
      }
    },
    "confirmRequestCode": {
      "header": {
        "description":
          "Vi vil først forsikre oss om at du er riktig person! Når du fortsetter, får du tilsendt en engangskode. Bruk den ved neste steg for å logge inn på Puls ved {organizationName}.",
        "help":
          "Du har nylig bedt om en kode. Pass på at du taster inn koden i vinduet du brukte for å etterspørre den. Hvis du fortsetter herfra, får du tilsendt en ny kode."
      }
    },
    "enterCode": {
      "form": {
        "code": {
          "placeholder": "engangskode"
        }
      },
      "header": {
        "deliveryMedium": {
          "EMAIL": "e-post",
          "SMS": "tekstmelding"
        },
        "description":
          "En engangskode er blitt sendt til deg per {deliveryMedium}. Bruk den nedenfor for å logge inn!",
        "title": "Velkommen til Puls!"
      },
      "help": {
        "noCodeDescriptionEmail":
          "Koden er sendt til deg på  e-post til en e-postadresse som inkluderer '{destination}'. Det kan ta noen minutter før koden ankommer. Hvis du ikke finner den, kan du sjekke spam-mappen. Pass på at du holder dette vinduet åpent og skriv inn koden over.",
        "noCodeDescriptionSMS":
          "Koden ble sendt til deg som tekstmelding til mobiltelefonen din og telefonnummeret som inkluderer '{destination}'. Det kan ta noen minutter før koden ankommer. Hvis du ikke gjenkjenner telefonnummeret, ber vi deg vennligst kontakte administrator.",
        "noCodeLinkTitle": "Har du ikke fått en kode?",
        "noCodeStartOverLinkTitle": "Start på nytt"
      }
    },
    "enterEmail": {
      "form": {
        "username": {
          "placeholder": "e-postadresse"
        }
      },
      "header": {
        "description": "Skriv inn e-postadressen på  {organizationName}:"
      }
    },
    "enterOrganization": {
      "form": {
        "organization": {
          "placeholder": "organisasjonens navn."
        }
      },
      "header": {
        "description": "Begynn med å skrive inn organisasjonen du tilhører",
        "invalid": {
          "spaces": "Ikke bruk mellomrom.",
          "specialchars": "Bare bruk bokstaver.",
          "uppercase": "Bare bruk små bokstaver."
        }
      }
    }
  },
  "surveys": {
    "description": "Disse undersøkelsene pågår nå:",
    "descriptionOneSurveyOnly": "Velg undersøkelse nedenfor for å komme igang!",
    "descriptionOneSurveyOnlyUserDone":
      "Undersøkelsen pågår fortsatt. Velg den for å se aktivitetene du har valgt.",
    "emptyResult":
      "Akkurat nå er det ingen undersøkelser du kan fylle inn. Du kan ta en titt på utførte oppgaver og resultater her nedenfor.",
    "ended": "avsluttet",
    "endsIn": "avslutter",
    "retry": "Prøv på nytt",
    "title": "Undersøkelser",
    "youreDone": "Du er ferdig"
  },
  "surveysToFillIn": {
    "description": "Disse undersøkelsene pågår nå:",
    "descriptionOneSurveyOnly": "Velg undersøkelse under for å komme i gang!",
    "descriptionOneSurveyOnlyUserDone":
      "Denne undersøkelsen pågår fortsatt. Velg den for å se handlingene du har valgt.",
    "emptyResult":
      "Akkurat nå er det ingen undersøkelse som venter på at du skal svare på den. Du kan se på handlingene/kommentarene eller resultatene nedenfor.",
    "ended": "avsluttet",
    "endsIn": "avsluttes",
    "retry": "Prøv på nytt",
    "title":
      "Du har en undersøkelse du må svare på|Det er {count} undersøkelser som venter på deg",
    "youreDone": "Du er ferdig"
  },
  "user": {
    "attributes": {
      "birthdate": {
        "info":
          "Dette er nødvendig for å samle statistikk basert på forskjellige aldersgrupper.",
        "label": "Fødselsdato"
      },
      "birth_date": {
        "info":
          "Dette er nødvendig for å samle statistikk basert på forskjellige aldersgrupper.",
        "label": "Fødselsdato"
      },
      "custom:employed_since": {
        "info":
          "Tidsperioden du har vært ansatt. Det behøver ikke være en nøyaktig dato. Hopp over dette valget dersom du ikke er ansatt et sted.",
        "label": "Ansatt siden"
      },
      "employment_date": {
        "info":
          "Tidsperioden du har vært ansatt. Det behøver ikke være en nøyaktig dato. Hopp over dette valget dersom du ikke er ansatt et sted.",
        "label": "Ansatt siden"
      },
      "family_name": {
        "label": "Etternavn"
      },
      "gender": {
        "one": "Andre",
        "other": "Andre",
        "zero": "Andre",
        "female": "Kvinne",
        "info":
          "Vi spør om kjønn slik at det kan inkluderes i statistikken. Du trenger ikke velge noe dersom du ikke ønsker det.",
        "label": "Velg kjønn:",
        "male": "Mann",
        "wontsay": "Foretrekker å ikke angi"
      },
      "given_name": {
        "label": "Fornavn"
      },
      "locale": {
        "da": "Dansk",
        "de": "Tysk",
        "en": "Engelsk",
        "es": "Spansk",
        "false": "Norsk",
        "fi": "Finsk",
        "fr": "Fransk",
        "info": "Velg ønsket språk. Dette kan også endres senere.",
        "it": "Italiensk",
        "label": "Velg språk:",
        "ms": "Malayisk",
        "nl": "Nederlandsk",
        "no": "Norsk",
        "pl": "Polsk",
        "sv": "Svensk"
      }
    },
    "button": {
      "cancel": "Avbryt",
      "skip": "Gå tilbake",
      "submit": "Lagre",
      "tryAgain": "Prøv på nytt"
    },
    "error": {
      "badPassword": "Vennligst velg et sikrere passord.",
      "clickLinkAgain":
        "Vennligst klikk på lenken i velkomst-eposten en gang til. Vennligst kontakt support@pulsmeter.se dersom problemet vedvarer",
      "invalid": "Det er ikke gyldig lenger, klikk nederst istedenfor:",
      "noPassword": "Du må velge et passord.",
      "passwordsMustMatch": "Passordet må matche.",
      "sessionError": "Vi har støtt på et problem!",
      "SSONotFound": "Det finnes ingen SSO-konfigurasjon for organisasjonen.",
      "unexpected": "Det er oppstått et uventet problem"
    },
    "header": "Brukerinstillinger",
    "introText": "Sjekk at brukerprofilen din er riktig.",
    "introTextVolontary": "Intet er obligatorisk å fylle ut.",
    "login": "Fortsett"
  },
  "whistleblower": {
    "buttons": {
      "cancel": "Avbryt",
      "done": "Ok",
      "send": "Send anonymt"
    },
    "description":
      "Vi gir deg mulighet til å sende en rapport om ulovlig oppførsel eller forseelser som du har vært vitne til  anonymt på {organizationName}.",
    "detailedDescription":
      "Slik fungerer det. Du skriver inn meldingen din i tekstfeltet under. Når du sender meldingen, sendes en e -post til en forhåndsdefinert mottaker i organisasjonen med ansvar for å iverksette tiltak. Det er umulig for mottakeren å se at meldingen ble sendt av deg, bare at den kom fra Puls -plattformen.  Hvis du vil bli kontaktet, må du informere om dette i meldingen. Hvis du vil være anonym, kan du bruke en (midlertidig) e -postadresse eller et SIM-kort til mobiltelefon.",
    "doneText":
      "Takk for at du sender en melding. Vi tar det svært alvorlig. Hvis du har angitt kontaktinformasjon, vil vi komme tilbake til deg så snart som mulig!",
    "linkTitle": "Les mer her",
    "linkToHelp": "https://www.puls-solutions.com/help/TODO",
    "notEnabled": {
      "description":
        "Beklager. Varslefunksjonen har ikke blitt aktivert av ditt selskap.",
      "title": "Ikke tilgjengelig"
    },
    "placeholder":
      "Skriv meldingen din her. Vær så spesifikk som mulig, og gi beskjed om hvis og hvordan du ønsker å bli kontaktet.",
    "title": "Send anonym rapport"
  }
}
