export default {
  "completePassword": {
    "button": {
      "submit": "Invia"
    },
    "header": "Scegli una nuova password",
    "placeholder": {
      "confirmPassword": "Conferma password",
      "password": "Password"
    }
  },
  "dashboard": {
    "button": {
      "goToAllResults": "Tutti i risultati",
      "myActions": "LE MIE AZIONI",
      "ourCompanyResults": "I NOSTRI RISULTATI"
    },
    "card": {
      "body":
        "Qui puoi trovare le azioni personali che hai scelto di fare. Puoi anche confrontare i tuoi risultati con quelli degli altri che fanno parte del tuo gruppo o della tua organizzazione.",
      "title": "Attività e risultati"
    },
    "hello": "Ciao {username}!",
    "introHeadline": "{productTitle} su {organizationName}",
    "settings": {
      "body":
        "Qui puoi modificare la lingua utilizzata, così come altre impostazioni del tuo account.",
      "da": "Danese",
      "de": "Tedesco",
      "en": "Inglese",
      "false": "Norvegese",
      "fr": "Francese",
      "logout": {
        "button": {
          "title": "Disconnettiti"
        },
        "confirmation": {
          "areyousure":
            "Se ti disconnetti, per accedere nuovamente devi richiedere un nuovo codice monouso.",
          "cancel": "Annulla",
          "false": "Annulla",
          "no": "Annulla",
          "title": "Vuoi disconnetterti?",
          "true": "Disconnessione",
          "yes": "Disconnessione"
        },
        "loggedin": "Hai effettuato l'accesso come {username}."
      },
      "no": "Norvegese",
      "sv": "Svedese",
      "title": "Impostazioni"
    }
  },
  "error": {
    "CodeMismatchException":
      "Il codice monouso che hai inserito non è valido. Ricontrollalo e riprova.",
    "couldNotLoadResults":
      "Impossibile caricare i risultati, per favore, riprova.",
    "couldNotLoadSurveys":
      "Impossibile caricare i sondaggi, per favore, riprova.",
    "emailAtLeast3Letter": "L'indirizzo e-mail deve contenere almeno 3 lettere",
    "emptyEmail": "Inserisci il tuo indirizzo e-mail",
    "emptyOrganization": "Inserisci il nome della tua organizzazione",
    "emptyPassword": "Inserisci una password",
    "ExpiredCodeException":
      "Il codice monouso che hai inserito è troppo vecchio e non può essere utilizzato per ragioni di sicurezza.",
    "InvalidParameterException": "Parametro Sessione richiesto mancante.",
    "InvalidPasswordException": "Scegli una password più sicura.",
    "LimitExceededException":
      "Troppi tentativi non riusciti. Devi attendere un po' prima di riprovare.",
    "NotAuthorizedException":
      "Al momento è impossibile accedere con il tuo account utente.",
    "notMatchPassword": "Le password non corrispondono!",
    "OrganizationNotFoundException":
      "Impossibile trovare un'organizzazione con quel nome, per favore, riprova.",
    "UserNotFoundException":
      "Impossibile trovare un account utente identificato da {username}."
  },
  "forgotten": {
    "backToLogin": "Annulla",
    "helptext":
      "Qui puoi reimpostare la tua password. Riceverai un'e-mail all'indirizzo e-mail indicato qui sopra contenente un codice, che inserirai al passaggio successivo.",
    "helptext-quickstart":
      "Ti abbiamo appena inviato un codice temporaneo a {email}. Inseriscilo qui sotto e scegli una password.",
    "helptext2": "Scegli una password sicura.",
    "input": {
      "code": "Codice trovato nell'email",
      "organization": "Organizzazione",
      "password": "Nuova password",
      "username": "Indirizzo e-mail"
    },
    "requestcode": {
      "submit": "Continua"
    },
    "setNewPassword": {
      "submit": "Inizia!"
    },
    "wait": "Ti preghiamo di attendere..."
  },
  "general": {
    "defaultProductTitle": "Puls⁺"
  },
  "help": {
    "card": {
      "body":
        "C'è qualcosa che possiamo fare per te? Clicca sul pulsante qui sotto per recarti al centro assistenza.",
      "title": "Aiuto e assistenza"
    },
    "description": "Non ti preoccupare, ti assistiamo noi.",
    "title": "AIUTO"
  },
  "ie": {
    "detailedText":
      "La piattaforma Puls può essere usata con Internet Explorer ma si tratta di un vecchio browser non conforme ai moderni standard del web. Quindi ti consigliamo di utilizzare uno dei browser indicati qui sotto. Puoi anche utilizzare il tuo telefono cellulare.",
    "detailedTitle": "Su Internet Explorer",
    "readMore": "Ulteriori informazioni",
    "warning":
      "Stai utilizzando Internet Explorer. Per usufruire della miglior esperienza possibile ti consigliamo di passare a un altro browser."
  },
  "integrations": {
    "allIntegrations": "Tutte le integrazioni",
    "apps": {
      "sftp": {
        "connectionInfo": "Verrà caricato in {dir} sul server SFTP.",
        "title": "SFTP"
      },
      "slack": {
        "connectionInfo": "Collegato al canale {channel} di {teamName}",
        "title": "Slack"
      }
    },
    "connectedSince": "Connesso dal {data}",
    "empty": {
      "description":
        "Aggiungendo un'integrazione puoi far connettere Puls al tuo servizio.\t Seleziona il pulsante qui sotto per connetterti a un servizio."
    },
    "errors": {
      "invalidIncomingIntegration":
        "Dati in entrata non validi per l'integrazione! Impossibile continuare."
    },
    "events": {
      "surveyCompleted": {
        "description":
          "Quando un sondaggio è completato i risultati per {groupTitle} vengono automaticamente inviati all'integrazione.",
        "title": "Sondaggio completato"
      },
      "title":
        "Scegli l'evento durante il quale l'integrazione deve essere contattata"
    },
    "groups": {
      "title": "Scegli il gruppo a cui connetterti {appName}"
    },
    "integrationTo": "Integrazioni a {appName}",
    "nothingSelected": "Seleziona...",
    "setupIntegration": {
      "saveButtonTitle": "Salva",
      "title": "Imposta l'integrazione in {appName}"
    },
    "title": "Integrazioni"
  },
  "login": {
    "button": {
      "forgotPassword": "Password dimenticata",
      "submit": "Login"
    },
    "captchaLegal": {
      "disclaimer":
        "Questo sito è protetto da reCAPTCHA e vi si applicano i {toc} e la {privacyPolicy} di Google.",
      "policy": "Informativa sulla privacy",
      "toc": "Termini di servizio"
    },
    "header": "Benvenuto su Puls!",
    "placeholder": {
      "email": "Indirizzo e-mail",
      "organization": "Organizzazione",
      "password": "Password"
    }
  },
  "navbar": {
    "welcome": "Benvenuto"
  },
  "results": {
    "participants": "{participants} partecipanti",
    "surveys": {
      "completed": {
        "latest": "Ultimo sondaggio completato",
        "title": "Risultati dei sondaggi completati"
      },
      "published": {
        "title": "Controlla i risultati"
      }
    }
  },
  "setup": {
    "attributes": {
      "birthdate": {
        "info":
          "Ciò è necessario per raccogliere statistiche basate su diversi gruppi di età.",
        "label": "Data di nascita"
      },
      "birth_date": {
        "info":
          "Ciò è necessario per raccogliere statistiche basate su diversi gruppi d'età.",
        "label": "Data di nascita"
      },
      "custom:employed_since": {
        "info":
          "La data della tua assunzione. Non è necessario che sia la data esatta. Salta la scelta se non sei assunto.",
        "label": "Assunto dal"
      },
      "employment_date": {
        "info":
          "La data della tua assunzione. Non deve essere necessariamente la data esatta. Salta la scelta se non sei assunto.",
        "label": "Assunto dal"
      },
      "family_name": {
        "label": "Cognome"
      },
      "gender": {
        "one": "Altri",
        "other": "Altri",
        "zero": "Altri",
        "female": "Femmina",
        "info":
          "Chiediamo il genere sessuale perché possa essere inserito nelle statistiche. Se non vuoi, non sei costretto a inserire alcunché.",
        "label": "Scegli genere sessuale:",
        "male": "Maschio",
        "wontsay": "Preferisco non indicarlo"
      },
      "given_name": {
        "label": "Nome"
      },
      "locale": {
        "da": "Danese",
        "de": "Tedesco",
        "dk": "Danese",
        "en": "Inglese",
        "es": "Spagnolo",
        "false": "Norvegese",
        "fr": "Francese",
        "info":
          "Scegli la lingua che preferisci. Potrai anche modificarla in un secondo momento.",
        "label": "Scegli lingua:",
        "ms": "Malese",
        "no": "Norvegese",
        "sv": "Svedese"
      }
    },
    "button": {
      "submit": "Salva",
      "tryAgain": "Riprova"
    },
    "error": {
      "badPassword": "Seleziona una password più sicura.",
      "clickLinkAgain":
        "Fai clic un'altra volta sul link nella e-mail di benvenuto. Se il problema persiste ti invitiamo a contattare support@pulsmeter.se",
      "invalid": "Questo link non è più valido, fai clic qui sotto invece:",
      "noPassword": "Devi scegliere una password.",
      "passwordsMustMatch": "Le password debbono corrispondere.",
      "sessionError": "Si è verificato un problema!",
      "SSONotFound": "Non c'è una configurazione SSO per l'organizzazione.",
      "unexpected": "Si è verificato un problema inatteso"
    },
    "header": "Primi passi con Puls!",
    "introText":
      "Ciao{name}! Se stato invitato su Puls, una piattaforma per la motivazione dei colleghi di lavoro. Attiva il tuo account compilando il modulo qui sotto. ",
    "introTextVolontary": "Solo la password è da inserire obbligatoriamente.",
    "login": "Continua",
    "placeholder": {
      "confirmPassword": "Conferma la password",
      "newPassword": "Scegli una password"
    }
  },
  "start": {
    "button": {
      "getOrganizationData": {
        "title": "Continua"
      },
      "requestCode": {
        "title": "Ottieni codice monouso"
      },
      "submitCode": {
        "title": "Accesso"
      }
    },
    "confirmRequestCode": {
      "header": {
        "description":
          "Prima vogliamo assicurarci che tu sia la persona giusta! Continuando ti verrà inviato un codice monouso. Utilizzalo al prossimo passaggio per accedere a Puls presso {organizationName}.",
        "help":
          "Recentemente hai richiesto un codice. Assicurati di inserire il codice nella finestra in cui lo hai richiesto. Se continui qui, verrà inviato un nuovo codice."
      }
    },
    "enterCode": {
      "form": {
        "code": {
          "placeholder": "codice monouso"
        }
      },
      "header": {
        "deliveryMedium": {
          "EMAIL": "e-mail",
          "SMS": "sms"
        },
        "description":
          "Ti è stato inviato un codice monouso tramite {deliveryMedium}. Lascia aperta questa finestra e torna qui per inserire il codice qui sotto:",
        "title": "Benvenuto a Puls!"
      },
      "help": {
        "noCodeDescriptionEmail":
          "Il codice ti è stato inviato via e-mail all'indirizzo di posta elettronica che include '{destinazione}'. Possono essere necessari uno o due minuti prima che il codice arrivi. Se non lo trovi, controlla la tua cartella dello spam. Assicurati di tenere aperta questa finestra e inserisci il codice qui sopra.",
        "noCodeDescriptionSMS":
          "Il codice è stato inviato via SMS al tuo cellulare e al numero di telefono che include '{destinazione}'. Possono essere necessari uno o due minuti prima che il codice arrivi. Se non riconosci il numero di telefono, contatta il tuo amministratore.",
        "noCodeLinkTitle": "Non hai ricevuto il codice?",
        "noCodeStartOverLinkTitle": "Ricomincia da capo"
      }
    },
    "enterEmail": {
      "form": {
        "username": {
          "placeholder": "indirizzo e-mail"
        }
      },
      "header": {
        "description":
          "Inserisci il tuo indirizzo e-mail presso {organizationName}:"
      }
    },
    "enterOrganization": {
      "form": {
        "organization": {
          "placeholder": "nome organizzazione"
        }
      },
      "header": {
        "description":
          "Inizia inserendo il nome dell'organizzazione di cui fai parte.",
        "invalid": {
          "spaces": "Non usare spazi.",
          "specialchars": "Usa solo lettere.",
          "uppercase": "Usa solo lettere minuscole."
        }
      }
    }
  },
  "surveys": {
    "description": "Questi sondaggi sono attualmente in corso:",
    "descriptionOneSurveyOnly":
      "Per cominciare seleziona il sondaggio qui sotto!",
    "descriptionOneSurveyOnlyUserDone":
      "Il sondaggio è ancora in corso. Selezionalo per vedere le attività che hai scelto.",
    "emptyResult":
      "In questo momento non c'è un sondaggio in attesa di essere compilato. Puoi dare un'occhiata alle tue azioni o ai risultati qui sotto.",
    "ended": "terminato",
    "endsIn": "termina",
    "retry": "Riprova",
    "title": "Sondaggi",
    "youreDone": "Tutto fatto"
  },
  "surveysToFillIn": {
    "description": "Attualmente sono in corso questi sondaggi:",
    "descriptionOneSurveyOnly":
      "Seleziona il sondaggio qui sotto per iniziare!",
    "descriptionOneSurveyOnlyUserDone":
      "Il sondaggio è ancora in corso.\t Selezionalo per vedere le opzioni che hai scelto.",
    "emptyResult":
      "In questo momento non c'è nessun sondaggio per te da compilare.  Puoi dare un'occhiata alle tue azioni/commenti o risultati qui sotto.",
    "ended": "terminato",
    "endsIn": "termina",
    "retry": "Riprova",
    "title":
      "Hai un sondaggio da compilare|Ci sono {count} sondaggi in attesa per te",
    "youreDone": "Hai finito"
  },
  "user": {
    "attributes": {
      "birthdate": {
        "info":
          "Ciò è necessario per raccogliere statistiche basate su diversi gruppi di età.",
        "label": "Data di nascita"
      },
      "birth_date": {
        "info":
          "Ciò è necessario per raccogliere statistiche basate su diversi gruppi di età.",
        "label": "Data di nascita"
      },
      "custom:employed_since": {
        "info":
          "La data della tua assunzione. Non è necessario che sia la data esatta. Salta la scelta se non sei assunto.",
        "label": "Assunto dal"
      },
      "employment_date": {
        "info":
          "La data della tua assunzione. Non è necessario che sia la data esatta. Salta la scelta se non sei assunto.",
        "label": "Assunto dal"
      },
      "family_name": {
        "label": "Cognome"
      },
      "gender": {
        "one": "Altri",
        "other": "Altri",
        "zero": "Altri",
        "female": "Femmina",
        "info":
          "Chiediamo il genere sessuale per poterlo inserire nelle statistiche. Se non vuoi, non sei costretto ad inserire alcunché.",
        "label": "Scegli il genere sessuale:",
        "male": "Maschio",
        "wontsay": "Preferisco non indicarlo"
      },
      "given_name": {
        "label": "Nome"
      },
      "locale": {
        "da": "Danese",
        "de": "Tedesco",
        "en": "Inglese",
        "es": "Spagnolo",
        "false": "Norvegese",
        "fi": "Finlandese",
        "fr": "Francese",
        "info":
          "Scegli la lingua che preferisci. Potrai modificarla in seguito.",
        "it": "Italiano",
        "label": "Scegli lingua:",
        "ms": "Malese",
        "nl": "Olandese",
        "no": "Norvegese",
        "pl": "Polacco",
        "sv": "Svedese"
      }
    },
    "button": {
      "cancel": "Annulla",
      "skip": "Torna indietro",
      "submit": "Salva",
      "tryAgain": "Riprova"
    },
    "error": {
      "badPassword": "Seleziona una password più sicura.",
      "clickLinkAgain":
        "Fai clic un'altra volta sul link nella e-mail di benvenuto. Se il problema persiste ti invitiamo a contattare support@pulsmeter.se",
      "invalid": "Questo link non è più valido, fai clic qui sotto invece:",
      "noPassword": "Devi scegliere una password.",
      "passwordsMustMatch": "Le password devono corrispondere.",
      "sessionError": "Si è verificato un problema!",
      "SSONotFound": "Non c'è una configurazione SSO per l'organizzazione.",
      "unexpected": "Si è verificato un problema inatteso"
    },
    "header": "Impostazioni utente",
    "introText": "Verifica che il tuo profilo utente sia corretto.",
    "introTextVolontary": "L'informazione qui sotto è opzionale.",
    "login": "Continua"
  },
  "whistleblower": {
    "buttons": {
      "cancel": "Annulla",
      "done": "Ok",
      "send": "Invia in forma anonima"
    },
    "description":
      "Ti diamo la possibilità di inviare in modo anonimo un report su comportamenti illeciti o scorrettezze di cui sei stato testimone in {organizationName}.",
    "detailedDescription":
      "Ecco come funziona.\t Inserisci il tuo report nella casella di testo qui sotto. Quando invii il report, un'e-mail viene inviata a un destinatario predefinito della tua azienda responsabile dell'azione da adottare. Il destinatario non potrà vedere che il messaggio è stato inviato da te, ma solo che proviene dalla piattaforma Puls.\t  Se vuoi essere contattato, assicurati di includere le informazioni necessarie nel tuo messaggio.\t Se vuoi rimanere anonimo puoi usare un indirizzo e-mail monouso (temporaneo) o una carta SIM per cellulare.",
    "doneText":
      "Grazie per aver inviato il tuo report. Lo prenderemo seriamente in considerazione.\t Se hai fornito informazioni di contatto ti risponderemo il prima possibile!",
    "linkTitle": "Leggi di più qui",
    "linkToHelp": "https://www.puls-solutions.com/help/TODO",
    "notEnabled": {
      "description":
        "Ci dispiace.  La funzione whistleblower non è abilitata dalla tua organizzazione.",
      "title": "Non disponibile"
    },
    "placeholder":
      "Inserisci qui il tuo report.\t Sii il più specifico possibile e facci sapere se e come vuoi essere contattato.",
    "title": "Invia un report anonimo"
  }
}
