export default {
  "completePassword": {
    "button": {
      "submit": "Enviar"
    },
    "header": "Elige una nueva contraseña",
    "placeholder": {
      "confirmPassword": "Confirma la contraseña",
      "password": "Contraseña"
    }
  },
  "dashboard": {
    "button": {
      "goToAllResults": "Todos los resultados",
      "myActions": "MIS ACCIONES",
      "ourCompanyResults": "NUESTROS RESULTADOS"
    },
    "card": {
      "body":
        "Aquí puedes encontrar las acciones personales que has elegido realizar. También puedes ver tu resultado y compararlo con el de toda la organización.",
      "title": "Acciones y resultados"
    },
    "hello": "¡Hola {username}!",
    "integrations": {
      "title": "Integraciones"
    },
    "introHeadline": "¡BIENVENIDO/A A {productTitle} EN {organizationName}!",
    "settings": {
      "body":
        "Aquí puedes cambiar el idioma, así como otros ajustes de tu cuenta.",
      "da": "Danés",
      "de": "Alemán",
      "en": "Inglés",
      "false": "Noruego",
      "fr": "Francés",
      "logout": {
        "button": {
          "title": "Cerrar sesión"
        },
        "confirmation": {
          "areyousure":
            "Si cierras la sesión necesitarás solicitar un nuevo código de un solo uso para iniciar sesión de nuevo.",
          "cancel": "Cancelar",
          "false": "Cancelar",
          "no": "Cancelar",
          "title": "¿Quieres cerrar sesión?",
          "true": "Cerrar sesión",
          "yes": "Cerrar sesión"
        },
        "loggedin": "Has iniciado sesión como {username}."
      },
      "no": "Noruego",
      "sv": "Sueco",
      "title": "Ajustes"
    }
  },
  "error": {
    "CodeMismatchException":
      "El código de un solo uso que has introducido no es válido.  Compruébalo y asegúrate de que funciona antes de intentarlo de nuevo.",
    "couldNotLoadResults":
      "No se pudieron cargar los resultados: inténtalo de nuevo.",
    "couldNotLoadSurveys":
      "No se pudieron cargar las entrevistas: inténtalo de nuevo.",
    "emailAtLeast3Letter":
      "La dirección de correo electrónico debe contener al menos 3 letras.",
    "emptyEmail": "Introduce tu dirección de correo electrónico",
    "emptyOrganization": "Introduce el nombre de tu organización",
    "emptyPassword": "Introduce una contraseña",
    "ExpiredCodeException":
      "Has introducido un código de un solo uso proporcionado hace demasiado tiempo y no puede usarse por razones de seguridad.",
    "InvalidParameterException":
      "No se encuentra el parámetro requerido para la sesión.",
    "InvalidPasswordException": "Elige una contraseña más segura.",
    "LimitExceededException":
      "Demasiados intentos fallidos. Debes esperar un tiempo para intentarlo de nuevo.",
    "NotAuthorizedException":
      "Actualmente no es posible iniciar sesión con tu cuenta de usuario.",
    "notMatchPassword": "Las contraseñas no coinciden.",
    "OrganizationNotFoundException":
      "No hemos podido encontrar una organización con ese nombre: inténtalo de nuevo.",
    "UserNotFoundException":
      "No hemos pedido encontrar una cuenta de usuario asignada a {username}."
  },
  "forgotten": {
    "backToLogin": "Cancelar",
    "helptext":
      "Aquí puedes restablecer tu contraseña. Recibirás un mensaje en la dirección de correo electrónico anterior con un código que tendrás que introducir en el siguiente paso.",
    "helptext-quickstart":
      "Acabamos de enviarte un código temporal a {email}.  Introdúcelo a continuación y elige una contraseña.",
    "helptext2": "Elige una contraseña segura.",
    "input": {
      "code": "Código enviado al correo electrónico",
      "organization": "Organización",
      "password": "Nueva contraseña",
      "username": "Dirección de correo electrónico"
    },
    "requestcode": {
      "submit": "Continúa"
    },
    "setNewPassword": {
      "submit": "¡Empieza!"
    },
    "wait": "Espera por favor..."
  },
  "general": {
    "defaultProductTitle": "Puls⁺"
  },
  "help": {
    "card": {
      "body":
        "¿Hay algo con lo que te podamos ayudar? Basta que pulses el botón de abajo para acceder al servicio de ayuda.",
      "title": "Ayuda y asistencia"
    },
    "description": "No te preocupes: estamos aquí para ayudarte.",
    "title": "AYUDA"
  },
  "ie": {
    "detailedText":
      "Puedes usar la plataforma Puls con Internet Explorer pero se trata de un navegador antiguo que no cumple los modernos estándares de la web. Por ello te recomendamos que utilices uno de los siguientes navegadores. También puedes usar tu teléfono móvil.",
    "detailedTitle": "Acerca de Internet Explorer",
    "readMore": "Leer más.",
    "warning":
      "Estás usando Internet Explorer. Te recomendamos que utilices otro navegador para mejorar tu experiencia."
  },
  "integrations": {
    "addIntegrationSuccess": "¡Integración añadida con éxito!",
    "allIntegrations": "Todas las integraciones",
    "apps": {
      "sftp": {
        "connectionInfo": "Se cargará en {dir} en el servidor SFTP.",
        "connectionTitle": "Conexión SFTP",
        "title": "SFTP"
      },
      "slack": {
        "connectionInfo": "Conectado al canal {channel} del  {teamName}.",
        "connectionTitle": "Slack para {appDetail}",
        "title": "Slack"
      }
    },
    "buttons": {
      "deleteIntegration": {
        "help": "Esto eliminará {type} de Puls."
      }
    },
    "connectedSince": "Conectado desde {date}",
    "empty": {
      "description":
        "Añadiendo una integración, Puls puede conectarse a tu propio servicio.  Selecciona el botón siguiente para conectarte a un servicio."
    },
    "errors": {
      "invalidIncomingIntegration":
        "¡Datos de entrada para integración no válidos! No se puede continuar."
    },
    "events": {
      "surveyCompleted": {
        "description":
          "Cuando se completa una encuesta, los resultados relativos a {groupTitle} se envían automáticamente a integración.",
        "title": "Encuesta completada"
      },
      "title":
        "Elige el evento que marca el momento en el que debería contactarse la integración"
    },
    "groups": {
      "title": "Elige el grupo para conectarte a {appName}"
    },
    "integrationTo": "Integración en {appName}",
    "nothingSelected": "Seleccionar...",
    "setupIntegration": {
      "saveButtonTitle": "Guardar",
      "title": "Configurar integración en {appName}"
    },
    "title": "Integraciones"
  },
  "login": {
    "button": {
      "forgotPassword": "He olvidado mi contraseña",
      "submit": "Iniciar sesión"
    },
    "captchaLegal": {
      "disclaimer":
        "Este sitio está protegido por reCAPTCHA y se aplican Google {toc} y la {privacyPolicy}.",
      "policy": "Política de privacidad",
      "toc": "Condiciones de servicio"
    },
    "header": "Empezar sesión",
    "placeholder": {
      "email": "Dirección de correo electrónico",
      "organization": "Organización",
      "password": "Contraseña"
    }
  },
  "navbar": {
    "welcome": "Bienvenido/a"
  },
  "results": {
    "participants": "{participants} participantes",
    "surveys": {
      "completed": {
        "latest": "Última encuesta completada",
        "title": "Resultados de encuestas completadas"
      },
      "emptyResult": "¡Pronto podrás ver aquí los resultados!",
      "published": {
        "title": "Comprobar resultados"
      }
    }
  },
  "setup": {
    "attributes": {
      "birthdate": {
        "info":
          "Es necesario para recoger datos estadísticos basados en diferentes grupos de edad.",
        "label": "Fecha de nacimiento"
      },
      "birth_date": {
        "info":
          "Es necesario con el fin de recoger datos estadísticos basados en diferentes grupos de edad.",
        "label": "Fecha de nacimiento"
      },
      "custom:employed_since": {
        "info":
          "La fecha en la que fuiste contratado. No es necesario que sea la fecha exacta. Si no eres empleado no hace falta que elijas ninguna opción.",
        "label": "Empleado desde"
      },
      "employment_date": {
        "info":
          "La fecha en la que fuiste contratado. No es necesario que sea la fecha exacta. Si no eres empleado no hace falta que elijas ninguna opción.",
        "label": "Empleado desde"
      },
      "family_name": {
        "label": "Apellidos"
      },
      "gender": {
        "one": "Otros",
        "other": "Otros",
        "zero": "Otros",
        "female": "Mujer",
        "info":
          "Nuestra pregunta sobre el género tiene una finalidad estadística. No tienes que seleccionar ninguno si no quieres.",
        "label": "Elige género:",
        "male": "Hombre",
        "wontsay": "Prefiero no contestar"
      },
      "given_name": {
        "label": "Nombre"
      },
      "locale": {
        "da": "Danés",
        "de": "Alemán",
        "dk": "Danés",
        "en": "Inglés",
        "es": "Español",
        "false": "Noruego",
        "fr": "Francés",
        "info":
          "Elige el idioma que prefieres usar. Esto también puedes cambiarlo más tarde.",
        "label": "Elige idioma:",
        "ms": "Malayo",
        "no": "Noruego",
        "sv": "Sueco"
      }
    },
    "button": {
      "submit": "Guardar",
      "tryAgain": "Inténtalo de nuevo"
    },
    "error": {
      "badPassword": "Elige una contraseña más segura.",
      "clickLinkAgain":
        "Haz clic de nuevo en el link que aparece en el correo electrónico de bienvenida. Si el problema persiste, ponte en contacto con support@pulsmeter.se",
      "invalid":
        "Este enlace ya no es válido: en su lugar, haz clic en el enlace de abajo.",
      "noPassword": "Debes elegir una contraseña.",
      "passwordsMustMatch": "Las contraseñas deben coincidir.",
      "sessionError": "¡Hemos encontrado un problema!",
      "SSONotFound": "No existe configuración SSO para esta organización.",
      "unexpected": "Se ha producido un problema inesperado"
    },
    "header": "¡Empieza ya a usar Puls!",
    "introText":
      "¡Hola {name}! Has recibido una invitación para unirte a Puls: una plataforma que busca el compromiso entre compañeros de trabajo. Activa tu cuenta rellenando el siguiente formulario. ",
    "introTextVolontary": "Solo es obligatorio rellenar la contraseña.",
    "login": "Continúa",
    "placeholder": {
      "confirmPassword": "Confirma la contraseña",
      "newPassword": "Elige una contraseña"
    }
  },
  "start": {
    "button": {
      "getOrganizationData": {
        "title": "Continúa"
      },
      "requestCode": {
        "title": "Obtén el código de un solo uso"
      },
      "submitCode": {
        "title": "Iniciar sesión"
      }
    },
    "confirmRequestCode": {
      "header": {
        "description":
          "En primer lugar queremos asegurarnos de que eres la persona correcta. Cuando continúes el proceso, te enviaremos un código de un solo uso. Úsalo en el siguiente paso para iniciar sesión en {productTitle} en {organizationName}.",
        "help":
          "Has solicitado un código recientemente. Asegúrate de que introduces el código en la ventana en la que lo solicitaste. Si continúas, se te enviará un nuevo código."
      }
    },
    "enterCode": {
      "form": {
        "code": {
          "placeholder": "código de un solo uso"
        }
      },
      "header": {
        "deliveryMedium": {
          "EMAIL": "correo electrónico",
          "SMS": "mensaje de texto"
        },
        "description":
          "Se te ha enviado un código de un solo uso a través de {deliveryMedium}. Deja abierta esta ventana y regresa aquí para introducir el siguiente código:",
        "title": "¡Bienvenido/a a {productTitle}!"
      },
      "help": {
        "noCodeDescriptionEmail":
          "Se te ha enviado el código a través de correo electrónico a la dirección que incluye '{destination}'.  Puede que el código tarde en llegar un minuto o dos. Si no lo encuentras, comprueba tu carpeta de correo no deseado. Asegúrate de que mantienes esta ventana abierta e introduce el código anterior.",
        "noCodeDescriptionSMS":
          "El código se te ha enviado como un mensaje de texto a tu teléfono móvil y al número de móvil que incluye '{destination}'. Puede que el código tarde en llegar un minuto o dos. Si no reconoces el número de móvil, ponte en contacto con tu administrador.",
        "noCodeLinkTitle": "¿No has recibido un código?",
        "noCodeStartOverLinkTitle": "Empieza de nuevo"
      }
    },
    "enterEmail": {
      "form": {
        "username": {
          "placeholder": "dirección de correo electrónico"
        }
      },
      "header": {
        "description":
          "Introduce tu dirección de correo electrónico en {organizationName}:"
      }
    },
    "enterOrganization": {
      "form": {
        "organization": {
          "placeholder": "nombre de la organización"
        }
      },
      "header": {
        "description":
          "Empieza introduciendo el nombre de la organización a la que perteneces.",
        "invalid": {
          "spaces": "No uses espacios.",
          "specialchars": "Usa solo letras.",
          "uppercase": "Usa solo letras minúsculas."
        }
      }
    }
  },
  "surveys": {
    "description": "Estas encuestas están en curso actualmente:",
    "descriptionOneSurveyOnly":
      "¡Selecciona la encuesta de abajo para empezar!",
    "descriptionOneSurveyOnlyUserDone":
      "La encuesta todavía se está realizando. Selecciónala para ver las acciones que has elegido.",
    "ended": "terminado",
    "youreDone": "Ya has terminado"
  },
  "surveysToFillIn": {
    "description": "Estas encuestas están en curso ahora:",
    "descriptionOneSurveyOnly":
      "Selecciona una encuesta entre las que aparecen más abajo para empezar.",
    "descriptionOneSurveyOnlyUserDone":
      "La encuesta todavía está en curso. Selecciónala para ver las acciones que has elegido.",
    "emptyResult":
      "Ahora mismo no hay ninguna encuesta pendiente que tengas que rellenar. Puedes echar una ojeada a tus acciones/comentarios o resultados aquí abajo.",
    "ended": "terminado",
    "endsIn": "termina",
    "retry": "Volver a intentar",
    "title":
      "Encuesta actual|Tienes una encuesta que completar|Tienes {count} encuestas pendientes",
    "youreDone": "Ya has terminado"
  },
  "user": {
    "attributes": {
      "birthdate": {
        "info":
          "Es necesario para recoger datos estadísticos basados en diferentes grupos de edad.",
        "label": "Fecha de nacimiento"
      },
      "birth_date": {
        "info":
          "Es necesario para recoger datos estadísticos basados en diferentes grupos de edad.",
        "label": "Fecha de nacimiento"
      },
      "custom:employed_since": {
        "info":
          "La fecha en que te contrataron. No es necesario que sea la fecha exacta. Si no eres empleado no hace falta que elijas ninguna opción.",
        "label": "Empleado desde"
      },
      "employment_date": {
        "info":
          "La fecha en que te contrataron. No es necesario que sea la fecha exacta. Si no eres empleado no hace falta que elijas ninguna opción.",
        "label": "Empleado desde"
      },
      "family_name": {
        "label": "Apellidos"
      },
      "gender": {
        "one": "Otros",
        "other": "Otros",
        "zero": "Otros",
        "female": "Mujer",
        "info":
          "Nuestra pregunta sobre el género tiene una finalidad estadística. No tienes que seleccionar ninguno si no quieres.",
        "label": "Elige género:",
        "male": "Hombre",
        "wontsay": "Prefiero no contestar"
      },
      "given_name": {
        "label": "Nombre"
      },
      "locale": {
        "da": "Danés",
        "de": "Alemán",
        "en": "Inglés",
        "es": "Español",
        "false": "Noruego",
        "fi": "Terminar",
        "fr": "Francés",
        "info":
          "Elige el idioma que prefieras. También puedes cambiarlo más tarde.",
        "it": "Italiano",
        "label": "Elige idioma:",
        "ms": "Malayo",
        "nl": "Holandés",
        "no": "Noruego",
        "pl": "Polaco",
        "sv": "Sueco"
      }
    },
    "button": {
      "cancel": "Cancelar",
      "skip": "Ir atrás",
      "submit": "Guardar",
      "tryAgain": "Inténtalo de nuevo"
    },
    "error": {
      "badPassword": "Elige una contraseña más segura.",
      "clickLinkAgain":
        "Haz clic de nuevo en el enlace del correo electrónico de bienvenida. Si el problema persiste ponte en contacto con support@pulsmeter.se",
      "invalid": "Ese enlace ya no es válido; en su lugar haz clic en:",
      "noPassword": "Debes elegir una contraseña.",
      "passwordsMustMatch": "Las contraseñas no coinciden.",
      "sessionError": "¡Hay un problema!",
      "SSONotFound": "No existe configuración SSO para esta organización.",
      "unexpected": "Se ha producido un problema inesperado"
    },
    "header": "Ajustes del usuario",
    "introText": "Comprueba que tu perfil de usuario es correcto.",
    "introTextNoAttributes": "Aquí no hay ajustes que cambiar actualmente.",
    "introTextVolontary": "Es voluntario rellenar la siguiente información.",
    "login": "Continúa"
  },
  "whistleblower": {
    "buttons": {
      "cancel": "Cancelar",
      "done": "Ok",
      "send": "Enviar de forma anónima"
    },
    "description":
      "Te ofrecemos la posibilidad de enviar de forma anónima un informe relativo a comportamientos ilegales o infracciones que hayas detectado en {organizationName}.",
    "detailedDescription":
      "Así es como funciona. Introduce tu informe en el cuadro de texto siguiente. Cuando mandas el informe, se envía un correo electrónico a un destinatario preestablecido de tu organización con responsabilidad para tomar medidas. El destinatario no puede ver que tú has enviado el mensaje, solo que procede de la plataforma Puls.  Si quieres que te contacten, asegúrate de indicarlo en tu mensaje. Si quieres permanecer anónimo, puedes usar una dirección de correo electrónico desechable (provisional) o una tarjeta SIM para móvil.",
    "doneText":
      "Gracias por enviar tu informe. Lo tomaremos en consideración con la mayor seriedad. Si has suministrado información de contacto, nos pondremos en contacto contigo lo antes posible.",
    "linkTitle": "Lee más aquí",
    "linkToHelp": "https://www.puls-solutions.com/help/TODO",
    "notEnabled": {
      "description":
        "Disculpa. La función que permite realizar denuncias no ha sido activada por tu organización.",
      "title": "No disponible"
    },
    "placeholder":
      "Carga aquí tu informe. La información que envíes debe ser lo más específica posible. Dinos si quieres ser contactado y, en ese caso, cómo quieres que lo hagamos.",
    "title": "Enviar un informe anónimo"
  }
}
