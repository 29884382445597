export default {
  "completePassword": {
    "button": {
      "submit": "Välj"
    },
    "header": "Välj ett nytt lösenord",
    "placeholder": {
      "confirmPassword": "Bekräfta lösenordet",
      "password": "Lösenord"
    }
  },
  "dashboard": {
    "button": {
      "goToAllResults": "Alla resultat",
      "myActions": "MINA AKTIVITETER",
      "ourCompanyResults": "VÅRA RESULTAT"
    },
    "card": {
      "body":
        "Här kan du hitta dina egna aktiviteter som du valt att du vill göra. Du kan också titta på ert resultat och hur det kan jämföras med hela organisationen.",
      "title": "Aktiviteter och resultat"
    },
    "greetings": {
      "day": "Hej, {username}!",
      "evening": "God kväll, {username}!",
      "morning": "God morgon, {username}!",
      "night": "God natt, {username}!",
      "simple": "Hej!"
    },
    "hello": "Hej, {username}!",
    "integrations": {
      "title": "Integrationer"
    },
    "introHeadline": "{productTitle} hos {organizationName}",
    "settings": {
      "ar": "Arabiska",
      "body": "Här kan du ändra inställningar till ditt konto.",
      "da": "Danska",
      "de": "Tyska",
      "en": "Engelska",
      "false": "Norska",
      "fr": "Franska",
      "logout": {
        "button": {
          "title": "Logga ut"
        },
        "confirmation": {
          "areyousure":
            "Om du loggar ut kommer du behöva begära en ny engångskod för att kunna komma tillbaka.",
          "cancel": "Avbryt",
          "false": "Avbryt",
          "no": "Avbryt",
          "title": "Vill du logga ut?",
          "true": "Logga ut",
          "yes": "Logga ut"
        },
        "loggedin": "Du är inloggad som {username}."
      },
      "no": "Norska",
      "sv": "Svenska",
      "title": "Inställningar"
    }
  },
  "error": {
    "CodeMismatchException":
      "Engångskoden är inte rätt. Det är bara den allra senaste koden som skickades ut som fungerar.",
    "couldNotLoadResults": "Kunde inte ladda resultatet, försök igen.",
    "couldNotLoadSurveys": "Kunde inte ladda undersökningar, försök igen.",
    "emailAtLeast3Letter": "E-postadressen måste ha minst 3 bokstäver",
    "emptyEmail": "Ange en e-postadress",
    "emptyOrganization": "Ange organisationens namn",
    "emptyPassword": "Ange ett lösenord",
    "EternalLoopError":
      "Ditt konto verkar vara trasigt. Kan du kontakta Puls support?",
    "ExpiredCodeException":
      "Engångskoden du matade in är för gammal och har av säkerhetsskäl slutat fungera.",
    "InvalidParameterException": "Fel parameter.",
    "InvalidPasswordException": "Välj ett säkrare lösenord.",
    "LimitExceededException":
      "För många fel. Du behöver vänta en stund innan du försöker igen.",
    "NotAuthorizedException":
      "Det går inte att logga in med ditt användarkonto nu.",
    "notMatchPassword": "Lösenorden stämmer inte överrens!",
    "OrganizationNotFoundException":
      "Vi hittade ingen organisation med det namnet. Försök igen.",
    "UserNotFoundException":
      "Vi kunde inte hitta {username}. Prova klicka på länken i mailet igen!"
  },
  "forgotten": {
    "backToLogin": "Avbryt",
    "helptext":
      "Här kan du nollställa ditt lösenord. Du kommer få ett mail till ovanstående e-postadress med en kod som du anger i nästa steg.",
    "helptext-quickstart":
      "Du har precis fått en tillfällig kod skickad till {email}. Ange den här nedanför samt ett lösenord.",
    "helptext2": "Välj ett säkert lösenord.",
    "input": {
      "code": "Kod från email",
      "organization": "Organisation",
      "password": "Nytt lösenord",
      "username": "E-postadress"
    },
    "requestcode": {
      "submit": "Fortsätt"
    },
    "setNewPassword": {
      "submit": "Spara"
    },
    "wait": "Vänta lite..."
  },
  "general": {
    "defaultProductTitle": "Puls⁺"
  },
  "help": {
    "card": {
      "body":
        "Finns det något vi kan hjälpa till med eller svara på? Bara tryck på knappen här nedanför.",
      "title": "Hjälp och support"
    },
    "description": "Oroa dig inte, problem är till för att lösas.",
    "title": "HJÄLP"
  },
  "ie": {
    "detailedText":
      "Puls-plattformen kan användas med Internet Explorer, men det är en gammal webbläsare som inte följer moderna standarder för hur webben fungerar. Vi rekommenderar därför att du använder en av nedanstående webbläsare istället. Det går också utmärkt att använda din mobiltelefon.",
    "detailedTitle": "Om Internet Explorer",
    "readMore": "Läs mer",
    "warning":
      "Du använder Internet Explorer. Vi rekommenderar att du byter till en annan webbläsare för bästa upplevelse.\\n"
  },
  "integrations": {
    "addIntegrationSuccess": "Integrationen är uppsatt!",
    "allIntegrations": "Alla integrationer",
    "apps": {
      "sftp": {
        "connectionInfo": "Kommer ladda upp till {dir} på SFTP-servern.",
        "connectionTitle": "SFTP-anslutning",
        "title": "SFTP"
      },
      "slack": {
        "connectionInfo": "Ansluten till {teamName} och kanalen {channel}",
        "connectionTitle": "Slack till {appDetail}",
        "title": "Slack"
      }
    },
    "back": "Tillbaka",
    "buttons": {
      "deleteIntegration": {
        "help": "Det här kommer ta bort {type}-integrationen från Puls."
      }
    },
    "connectedSince": "Ansluten sedan {date}",
    "empty": {
      "description":
        "Genom att lägga till en integration kan du göra så Puls ansluter till er egen tjänst. Välj knapp nedanför för att ansluta till en tjänst."
    },
    "errors": {
      "invalidIncomingIntegration":
        "Felaktig inkommande data för integrationen! Kan inte fortsätta."
    },
    "events": {
      "surveyCompleted": {
        "description":
          "När en undersökning avslutas kommer resultat för {groupTitle} automatiskt skickas till integrationen.",
        "title": "Undersökning avslutad"
      },
      "title": "Välj händelse när integrationen ska bli kontaktad"
    },
    "groups": {
      "title": "Välj grupp att koppla till {appName}"
    },
    "integrationTo": "Integration till  {appName}",
    "nothingSelected": "Välj...",
    "setupIntegration": {
      "saveButtonTitle": "Spara",
      "title": "Ställ in integration till {appName}"
    },
    "title": "Integrationer"
  },
  "loggedOut": {
    "loginButton": {
      "title": "Logga in igen"
    },
    "title": "Du är nu utloggad!"
  },
  "login": {
    "button": {
      "forgotPassword": "Glömt lösenord",
      "submit": "Logga in"
    },
    "captchaLegal": {
      "disclaimer":
        "Den här sidan skyddas av reCAPTCHA och Google's {toc} och {privacyPolicy} gäller.",
      "policy": "integritetspolicy",
      "toc": "villkor"
    },
    "header": "Inloggning",
    "placeholder": {
      "email": "E-postadress",
      "organization": "Organisation",
      "password": "Lösenord"
    }
  },
  "navbar": {
    "welcome": "Välkommen"
  },
  "notFound": {
    "alert": "Den här sidan finns inte. Gå tillbaka till var du kom ifrån",
    "backButton": "Översikt",
    "title": "Kunde inte hittas"
  },
  "results": {
    "participants": "{participants} deltagare",
    "surveys": {
      "completed": {
        "latest": "Senast avslutad undersökning",
        "title": "Resultat för avslutade undersökningar"
      },
      "emptyResult": "Snart kan du se resultat här.",
      "published": {
        "title": "Se resultat"
      }
    }
  },
  "setup": {
    "attributes": {
      "birthdate": {
        "info":
          "För att vi ska kunna samla statistik kopplat till olika åldersgrupper kan du fylla i din födelsedag.",
        "label": "Födelsedag"
      },
      "birth_date": {
        "info":
          "För att vi ska kunna samla statistik kopplat till olika åldersgrupper kan du fylla i din födelsedag.",
        "label": "Födelsedag"
      },
      "custom:employed_since": {
        "info":
          "Datum som du anställdes. Du behöver inte svara exakt. Är du inte anställd, bara hoppa över att fylla i.",
        "label": "Anställd sedan"
      },
      "employment_date": {
        "info":
          "Datum som du anställdes. Du behöver inte svara exakt. Är du inte anställd, bara hoppa över att fylla i.",
        "label": "Anställd sedan"
      },
      "family_name": {
        "label": "Efternamn"
      },
      "gender": {
        "other": "Annat kön",
        "female": "Kvinna",
        "info":
          "Vi frågar om kön för att det ska kunna inkluderas i statistik. Du behöver inte fylla i något här om du inte vill.",
        "label": "Välj kön:",
        "male": "Man",
        "wontsay": "Vill inte säga"
      },
      "given_name": {
        "label": "Förnamn"
      },
      "locale": {
        "ar": "Arabiska",
        "da": "Danska",
        "de": "Tyska",
        "dk": "Danska",
        "en": "Engelska",
        "es": "Spanska",
        "false": "Norska",
        "fi": "Finska",
        "fr": "Franska",
        "hr": "Kroatiska",
        "info":
          "Välj det språk som du föredrar att använda. Det går också att ändra senare!",
        "it": "Italienska",
        "label": "Välj språk:",
        "ms": "Malaysiska",
        "nl": "Holländska",
        "no": "Norska",
        "pl": "Polska",
        "so": "Somaliska",
        "sr": "Serbiska",
        "sv": "Svenska"
      }
    },
    "button": {
      "submit": "Starta!",
      "tryAgain": "Försök igen"
    },
    "error": {
      "badPassword": "Välj ett säkrare lösenord.",
      "clickLinkAgain":
        "Klicka på länken i välkomst-mailet igen för att försöka en gång till. Kontakta support@pulsmeter.se om felet kvarstår.",
      "invalid":
        "Länken fungerar inte längre, gå vidare här nedanför istället:",
      "noPassword": "Du måste välja ett lösenord.",
      "passwordsMustMatch": "Lösenorden måste vara samma.",
      "sessionError": "Vi stötte på ett problem!",
      "SSONotFound": "Det finns ingen SSO-konfiguration för organisationen.",
      "unexpected": "Ett oväntat problem inträffade"
    },
    "header": "Kom igång med Puls!",
    "introText":
      "Hej{name}! Du har blivit inbjuden till Puls, en plattform för medarbetarengagemang. Aktivera ditt konto genom att fylla i uppgifterna här nedanför. ",
    "introTextVolontary": "Allt utom lösenord är frivilligt.",
    "login": "Fortsätt",
    "placeholder": {
      "confirmPassword": "Skriv lösenordet igen",
      "newPassword": "Välj ett lösenord"
    }
  },
  "sso": {
    "button": {
      "continue": "Fortsätt",
      "login": "SSO-inloggning",
      "signout": "Logga ut"
    },
    "error": {
      "generic": "Det uppstod ett problem under inloggningen: {error}"
    },
    "noUserAccountFound": "Inget användarkonto hittades i organisationen"
  },
  "start": {
    "button": {
      "getOrganizationData": {
        "title": "Fortsätt"
      },
      "requestCode": {
        "title": "Hämta engångskod"
      },
      "submitCode": {
        "title": "Logga in"
      }
    },
    "confirmRequestCode": {
      "header": {
        "description":
          "Vi vill först bara kolla att du är du! När du går vidare skickas en engångskod som används i nästa steg för att logga in till {productTitle} hos {organizationName}.",
        "help":
          "Du har nyligen fått en kod skickad till dig. Mata in den i det fönster där du beställde den. Om du går vidare här får du en ny kod."
      }
    },
    "enterCode": {
      "form": {
        "code": {
          "placeholder": "engångskod"
        }
      },
      "header": {
        "deliveryMedium": {
          "EMAIL": "e-post",
          "SMS": "SMS"
        },
        "description":
          "Du har fått en engångskod skickad till dig via {deliveryMedium}. Låt det här fönstret vara öppet och kom tillbaka hit igen för att ange koden här nedanför:",
        "title": "Välkommen till {productTitle}!"
      },
      "help": {
        "noCodeDescriptionEmail":
          "Koden skickades till dig via email till den epostadress som innehåller '{destination}'. Avsändaren är \"Puls\". Det kan ta någon minut innan du får koden. Om du inte hittar den, kolla ditt spam-filter. Tänk på att lämna detta fönster öppet och skriv in koden här ovanför.",
        "noCodeDescriptionSMS":
          "Koden skickades som ett sms till din mobiltelefon på det mobilnummer som innehåller '{destination}'. Det kan ta någon minut innan du får koden. Om du inte känner igen mobilnumret, vänligen kontakta din administratör.",
        "noCodeLinkTitle": "Fick du ingen kod?",
        "noCodeStartOverLinkTitle": "Börja om"
      }
    },
    "enterEmail": {
      "form": {
        "username": {
          "placeholder": "e-postadress"
        }
      },
      "header": {
        "description": "Fyll i din e-postadress på {organizationName}:"
      }
    },
    "enterOrganization": {
      "form": {
        "organization": {
          "placeholder": "organisationsnamn"
        }
      },
      "header": {
        "description":
          "Börja med att fylla i namnet på organisationen du tillhör.",
        "invalid": {
          "spaces": "Använd inte mellanslag.",
          "specialchars": "Använd bara bokstäver.",
          "uppercase": "Använd bara små bokstäver."
        }
      }
    }
  },
  "surveys": {
    "description": "Det här är de pågående undersökningarna:",
    "descriptionOneSurveyOnly": "Välj undersökningen för att komma igång!",
    "descriptionOneSurveyOnlyUserDone":
      "Undersökningen pågår fortfarande. Välj den för att se aktiviteterna du valde.",
    "emptyResult":
      "Just nu finns det ingen undersökning att fylla i. Titta istället på dina aktiviteter eller era resultat här nedanför.",
    "ended": "avslutades",
    "endsIn": "avslutas",
    "retry": "Försök igen",
    "title": "Undersökningar",
    "youreDone": "Du är klar"
  },
  "surveysToFillIn": {
    "description": "Det här är de pågående undersökningarna:",
    "descriptionOneSurveyOnly": "Välj undersökningen för att komma igång!",
    "descriptionOneSurveyOnlyUserDone":
      "Undersökningen pågår fortfarande. Välj den för att se aktiviteterna du valde.",
    "emptyResult":
      "Just nu finns det ingen undersökning att fylla i. Titta istället på dina aktiviteter eller era resultat här nedanför.",
    "ended": "avslutades",
    "endsIn": "avslutas",
    "retry": "Försök igen",
    "title":
      "Nuvarande undersökning| Det finns en undersökning att besvara|Det finns {count} undersökningar att besvara",
    "youreDone": "Du är klar"
  },
  "user": {
    "attributes": {
      "birthdate": {
        "info":
          "För att vi ska kunna samla statistik kopplat till olika åldersgrupper kan du fylla i din födelsedag.",
        "label": "Födelsedag"
      },
      "birth_date": {
        "info":
          "För att vi ska kunna samla statistik kopplat till olika åldersgrupper kan du fylla i din födelsedag.",
        "label": "Födelsedag"
      },
      "custom:employed_since": {
        "info":
          "Datum som du anställdes. Du behöver inte svara exakt. Är du inte anställd, bara hoppa över att fylla i.",
        "label": "Anställd sedan"
      },
      "employment_date": {
        "info":
          "Datum som du anställdes. Du behöver inte svara exakt. Är du inte anställd, bara hoppa över att fylla i.",
        "label": "Anställd sedan"
      },
      "family_name": {
        "label": "Efternamn"
      },
      "gender": {
        "other": "Annat kön",
        "female": "Kvinna",
        "info":
          "Vi frågar om kön för att det ska kunna inkluderas i statistik. Du behöver inte fylla i något här om du inte vill.",
        "label": "Välj kön:",
        "male": "Man",
        "wontsay": "Vill inte säga"
      },
      "given_name": {
        "label": "Förnamn"
      },
      "locale": {
        "ar": "Arabiska",
        "da": "Danska",
        "de": "Tyska",
        "en": "Engelska",
        "es": "Spanska",
        "false": "Norska",
        "fi": "Finska",
        "fr": "Franska",
        "hr": "Kroatiska",
        "info": "Välj det språk som du föredrar att använda.",
        "it": "Italienska",
        "label": "Välj språk:",
        "ms": "Malaysiska",
        "nl": "Holländska",
        "no": "Norska",
        "pl": "Polska",
        "so": "Somaliska",
        "sr": "Serbiska",
        "sv": "Svenska",
        "zh": "Kinesiska"
      }
    },
    "button": {
      "cancel": "Ångra",
      "skip": "Gå tillbaka",
      "submit": "Spara",
      "tryAgain": "Försök igen"
    },
    "error": {
      "badPassword": "Välj ett säkrare lösenord.",
      "clickLinkAgain":
        "Klicka på länken i välkomst-mailet igen för att försöka en gång till. Kontakta support@pulsmeter.se om felet kvarstår.",
      "invalid":
        "Länken fungerar inte längre, gå vidare här nedanför istället:",
      "noPassword": "Du måste välja ett lösenord.",
      "passwordsMustMatch": "Lösenorden måste vara samma.",
      "sessionError": "Vi stötte på ett problem!",
      "SSONotFound": "Det finns ingen SSO-konfiguration för organisationen.",
      "unexpected": "Ett oväntat problem inträffade"
    },
    "header": "Inställningar",
    "introText": "Kolla att ditt användarkonto är rätt.",
    "introTextNoAttributes": "Just nu finns det inget att ändra här.",
    "introTextVolontary": "Du kan lämna fälten tomma, om du vill.",
    "login": "Fortsätt"
  },
  "whistleblower": {
    "buttons": {
      "cancel": "Avbryt",
      "done": "Ok",
      "send": "Skicka anonymt"
    },
    "description":
      "Vi tillhandahåller möjligheten att anonymt skicka in en rapport om olagligt beteende eller förseelser som du har bevittnat på {organizationName}.",
    "detailedDescription":
      "Det fungerar så här. Du lämnar din rapport i textrutan nedan. När du skickar rapporten så skickas ett email till en förutbestämd mottagare i din organisation med ansvar för att agera. Det är omöjligt för mottagaren att se att meddelandet skickades av dig, endast att det kom från Puls-plattformen. Om du vill bli kontaktad, så lämnar du information om detta i ditt meddelande. Om du vill förbli anonym så kan du använda disponibel (tillfällig) email-adress eller SIM-kort till mobiltelefon.",
    "doneText":
      "Tack för att du skickade din rapport. Vi kommer att ta den allvarligt. Om du har uppgett kontaktuppgifter så återkommer vi till dig så fort som möjligt!",
    "linkTitle": "Läs mer här",
    "linkToHelp": "https://www.puls-solutions.com/help/TODO",
    "notEnabled": {
      "description":
        "Beklagar. Visselblåsar-funktionen är inte aktiverad av din organisation.",
      "title": "Ej tillgänglig"
    },
    "placeholder":
      "Lämna din rapport här. Var så specifik som möjligt och berätta om och i så fall hur du vill bli kontaktad.",
    "title": "Skicka anonym rapport"
  }
}
