import { parseJSON } from '@/helpers/Utils'
import {
  loadIntegrations,
  loadIntegrationsAvailable,
  saveIntegration,
  removeIntegration
} from '@/services/UserService'
import router from '../router'

const state = {
  loggedInUser: null,
  hasToken: true,
  userDetailsError: null,
  userDetailsLoading: false,
  whistleblowerLoading: false,
  whistleblowerSuccess: false,
  whistleblowerError: null,
  integrations: null,
  integrationsAvailable: {},
  integrationsLoading: false,
  integrationsError: null
}

const mutations = {
  setLoggedInUser(state, user) {
    state.loggedInUser = user
  },
  setHasToken(state, hasToken) {
    state.hasToken = hasToken
  },
  setUserDetailsError(state, error) {
    state.userDetailsError = error
  },
  setUserDetailsLoading(state, loading) {
    state.userDetailsLoading = loading
  },
  setWhistleblowerLoading(state, loading) {
    state.whistleblowerLoading = loading
  },
  setWhistleblowerSuccess(state, success) {
    state.whistleblowerSuccess = success
  },
  setWhistleblowerError(state, error) {
    state.whistleblowerError = error
  },
  setIntegrations(state, integrations) {
    state.integrations = integrations
  },
  setIntegrationsAvailable(state, integrations) {
    state.integrationsAvailable = integrations
  },
  removeIntegration(state, integrationName) {
    state.integrations = state.integrations.filter(
      (i) => i.name !== integrationName
    )
  },
  setIntegrationsLoading(state, loading) {
    state.integrationsLoading = loading
  },
  setIntegrationsError(state, error) {
    state.integrationsError = error
  },
  handleUnknownUserpool(_, { organizationName }) {
    if (router.history.current.path !== '/start') {
      router.push(`/start?o=${organizationName}`)
    }
  }
}

const actions = {
  // setLoggedInUser ({ commit }) {
  //   commit('setLoggedInUser')
  // },
  // setHasToken ({ commit }) {
  //   commit('setHasToken')
  // }
  loadIntegrations({ commit }) {
    commit('setIntegrationsLoading', true)
    commit('setIntegrationsError', null)
    return loadIntegrations()
      .then((integrations) => {
        commit('setIntegrations', integrations)
      })
      .catch((error) => {
        commit('setIntegrationsError', error)
      })
      .finally(() => {
        commit('setIntegrationsLoading', false)
      })
  },
  loadIntegrationsAvailable({ commit }) {
    commit('setIntegrationsLoading', true)
    commit('setIntegrationsError', null)
    return loadIntegrationsAvailable()
      .then((integrations) => {
        commit('setIntegrationsAvailable', integrations)
      })
      .catch((error) => {
        commit('setIntegrationsError', error)
      })
      .finally(() => {
        commit('setIntegrationsLoading', false)
      })
  },
  saveIntegration({ commit, state }, data) {
    commit('setIntegrationsLoading', true)
    commit('setIntegrationsError', null)
    return saveIntegration(data)
      .then((integration) => {
        commit('setIntegrations', [integration])
      })
      .catch((error) => {
        commit('setIntegrationsError', error)
      })
      .finally(() => {
        commit('setIntegrationsLoading', false)
      })
  },
  removeIntegration({ commit }, { integrationName }) {
    commit('setIntegrationsLoading', true)
    commit('setIntegrationsError', null)
    return removeIntegration(integrationName)
      .then((integration) => {
        commit('removeIntegration', integrationName)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        commit('setIntegrationsError', error)
      })
      .finally(() => {
        commit('setIntegrationsLoading', false)
      })
  }
}

const getters = {
  userDetailsError: (state) => state.userDetailsError,
  userDetailsLoading: (state) => state.userDetailsLoading,
  whistleblowerLoading: (state) => state.whistleblowerLoading,
  whistleblowerSuccess: (state) => state.whistleblowerSuccess,
  whistleblowerError: (state) => state.whistleblowerError,
  integrationsLoading: (state) => state.integrationsLoading,
  integrations: (state) => state.integrations,
  integrationsAvailable: (state) => state.integrationsAvailable,
  integrationsError: (state) => state.integrationsError,
  loggedInUser: (state) => {
    if (state.loggedInUser) {
      return {
        ...state.loggedInUser,
        username: state.loggedInUser.signInUserSession
          ? state.loggedInUser.signInUserSession.idToken.payload.pulsUsername ||
            state.loggedInUser.username
          : state.loggedInUser.username // when new pw required we have no idtoken
      }
    }
    return null
  },
  hasToken: (state) => state.hasToken,
  logoUrl: (state) => {
    if (!state.loggedInUser) {
      return null
    } else {
      const info = state.loggedInUser.signInUserSession.idToken.payload
      if (info.organizationLogoUrl) {
        return info.organizationLogoUrl
      } else {
        return null
      }
    }
  },
  fullName: (state) => {
    if (!state.loggedInUser) {
      return ''
    } else {
      const info = state.loggedInUser.signInUserSession.idToken.payload
      if (info.given_name) {
        return info.given_name + ' ' + info.family_name
      } else {
        return info.email
      }
    }
  },
  givenName: (state) => {
    if (!state.loggedInUser) {
      return ''
    } else {
      const info = state.loggedInUser.signInUserSession.idToken.payload
      if (info.given_name) {
        return info.given_name
      } else {
        return null
      }
    }
  },
  userInfo: (state) => {
    const user = state.loggedInUser
    if (user && user.signInUserSession && user.signInUserSession.idToken) {
      const info = user.signInUserSession.idToken.payload
      const groups = user.signInUserSession.idToken.payload['cognito:groups']
      let groupName = 'My Group'
      if (groups && groups.length === 1) {
        groupName = groups[0]
      }
      return {
        username:
          user.signInUserSession.idToken.payload.pulsUsername ||
          user.signInUserSession.idToken.payload['cognito:username'],
        organizationName:
          user.signInUserSession.idToken.payload.organizationName,
        organizationFullName:
          user.signInUserSession.idToken.payload.organizationFullName,
        groupName: groupName,
        fullName:
          info.given_name && info.family_name
            ? `${info.given_name} ${info.family_name}`
            : info.email
      }
    }
    return {}
  },
  isAdmin: (state) => {
    const loggedInUser = parseJSON(state.loggedInUser)
    if (!loggedInUser) {
      return false
    }
    return (
      loggedInUser &&
      loggedInUser.signInUserSession &&
      loggedInUser.signInUserSession.idToken.payload.userIsAdmin === 'true'
    )
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
