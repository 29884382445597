export default {
  dev: {
      apiKey: 'QKzU5BQ1sg55qnOo1MJf54IMxWx8djeq4KewYTtU',
      env: 'dev',
      defaultLanguage: 'en',
      featureToggles: {

    },
      surveyApiUrl: 'https://survey-api-dev.pulsmeter.net/api',
      resultsApiUrl: 'https://results-api-dev.pulsmeter.net/api',
      publicApiUrl: 'https://public-api-dev.pulsmeter.net/api',
      userApiUrl: 'https://user-api-dev.pulsmeter.net/api',
      insightsApiUrl: 'https://insights-api-dev.pulsmeter.net/api'
    },
  staging: {
      apiKey: 'F7SJUNaGMo3GsbfoDWLFu1aBPgnjL6Hs1v36FmLk',
      env: 'staging',
      defaultLanguage: 'en',
      featureToggles: {

    },
      surveyApiUrl: 'https://survey-api-staging.pulsmeter.net/api',
      resultsApiUrl: 'https://results-api-staging.pulsmeter.net/api',
      publicApiUrl: 'https://public-api-staging.pulsmeter.net/api',
      userApiUrl: 'https://user-api-staging.pulsmeter.net/api',
      insightsApiUrl: 'https://insights-api-staging.pulsmeter.net/api'
    },
  demo: {
      apiKey: 'Acau2ymCSn6DbjXDagDST956kyVL6c7z8GoWuQyQ',
      env: 'demo',
      defaultLanguage: 'en',
      featureToggles: {

    },
      surveyApiUrl: 'https://survey-api-demo.puls-solutions.com/api',
      resultsApiUrl: 'https://results-api-demo.puls-solutions.com/api',
      publicApiUrl: 'https://public-api-demo.puls-solutions.com/api',
      userApiUrl: 'https://user-api-demo.puls-solutions.com/api',
      insightsApiUrl: 'https://insights-api-demo.puls-solutions.com/api'
    },
  www: {
      apiKey: 'RsjKAbk01V51qRYCpKSe01acfdU04ngk3Yl3Uaiz',
      env: 'www',
      defaultLanguage: 'en',
      featureToggles: {

    },
      surveyApiUrl: 'https://survey-api-www.puls-solutions.com/api',
      resultsApiUrl: 'https://results-api-www.puls-solutions.com/api',
      publicApiUrl: 'https://public-api-www.puls-solutions.com/api',
      userApiUrl: 'https://user-api-www.puls-solutions.com/api',
      insightsApiUrl: 'https://insights-api-www.puls-solutions.com/api'
    }
}
