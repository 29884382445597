export default {
  "completePassword": {
    "button": {
      "submit": "Poslati"
    },
    "header": "Odaberi novu lozinku",
    "placeholder": {
      "confirmPassword": "Potvrdi lozinku",
      "password": "Lozinka"
    }
  },
  "dashboard": {
    "button": {
      "goToAllResults": "Svi rezultati",
      "myActions": "MOJE RADNJE",
      "ourCompanyResults": "NAŠI REZULTATI"
    },
    "card": {
      "body":
        "Ovdje možeš pronaći osobne radnje koje si odabrao/la učiniti. Također možeš vidjeti svoj rezultat i njegovu usporedbu s rezultatima cjelokupne organizacije.",
      "title": "Radnje i rezultati"
    },
    "greetings": {
      "day": "Pozdrav, {username}!",
      "evening": "Dobra večer, {username}!",
      "morning": "Dobro jutro, {username}!",
      "night": "Laku noć, {username}!"
    },
    "hello": "Bok {username}!",
    "integrations": {
      "title": "Integracije"
    },
    "introHeadline": "{productTitle} u {organizationName}",
    "settings": {
      "ar": "Arapski",
      "body": "Ovdje možeš promijeniti postavke za svoj račun.",
      "da": "Danski",
      "de": "Njemački",
      "en": "Engleski",
      "false": "Norveški",
      "fr": "Francuski",
      "logout": {
        "button": {
          "title": "Odjava"
        },
        "confirmation": {
          "areyousure":
            "Ako se odjaviš, moraš zatražiti novi jednokratni kod kako bi se ponovno prijavio/la.",
          "cancel": "Otkaži",
          "false": "Otkaži",
          "no": "Otkaži",
          "title": "Želiš li se odjaviti?",
          "true": "Odjava",
          "yes": "Odjava"
        },
        "loggedin": "Prijavljen/a si kao {username}."
      },
      "no": "Norveški",
      "sv": "Švedski",
      "title": "Postavke"
    }
  },
  "error": {
    "CodeMismatchException":
      "Jednokratni kod koji si unio/unijela nije valjan. Provjeri još jednom i pokušaj ponovno.",
    "couldNotLoadResults": "Nije moguće učitati rezultate, pokušaj ponovno.",
    "couldNotLoadSurveys": "Nije moguće učitati ankete, pokušaj ponovno.",
    "emailAtLeast3Letter": "Adresa e-pošte mora sadržavati najmanje 3 slova",
    "emptyEmail": "Unesi svoju adresu e-pošte",
    "emptyOrganization": "Unesi naziv svoje organizacije",
    "emptyPassword": "Unesi lozinku",
    "ExpiredCodeException":
      "Jednokratni kod koji si unio/unijela je prestar i ne može se koristiti iz sigurnosnih razloga.",
    "InvalidParameterException": "Nedostaje potreban parametar Sesija.",
    "InvalidPasswordException": "Odaberi sigurniju lozinku.",
    "LimitExceededException":
      "Previše neuspjelih pokušaja. Moraš pričekati neko vrijeme prije ponovnog pokušaja.",
    "NotAuthorizedException":
      "Trenutno nije moguće prijaviti se s tvojim korisničkim računom.",
    "notMatchPassword": "Lozinke se ne podudaraju!",
    "OrganizationNotFoundException":
      "Nismo mogli pronaći organizaciju s tim imenom, pokušaj ponovno.",
    "UserNotFoundException":
      "Nismo mogli pronaći korisnički račun koji je identificirala osoba {username}."
  },
  "forgotten": {
    "backToLogin": "Otkaži",
    "helptext":
      "Ovdje možeš ponovno postaviti svoju lozinku. Na gore navedenu adresu e-pošte dobit ćeš e-poruku s lozinkom koju ćeš unijeti u sljedećem koraku.",
    "helptext-quickstart":
      "Upravo smo ti poslali privremeni kod na {email}. Unesi ga ispod i odaberi lozinku.",
    "helptext2": "Odaberi sigurnu lozinku.",
    "input": {
      "code": "Kod pronađen u e-pošti",
      "organization": "Organizacija",
      "password": "Nova lozinka",
      "username": "Adresa e-pošte"
    },
    "requestcode": {
      "submit": "Nastavi"
    },
    "setNewPassword": {
      "submit": "Počni!"
    },
    "wait": "Pričekaj..."
  },
  "general": {
    "defaultProductTitle": "Puls⁺"
  },
  "help": {
    "card": {
      "body":
        "Možemo li ti nekako pomoći? Samo klikni gumb u nastavku da bi otišao/la u centar za pomoć.",
      "title": "Pomoć i podrška"
    },
    "description": "Ne brini, mi ti čuvamo leđa.",
    "title": "POMOĆ"
  },
  "ie": {
    "detailedText":
      "Platformu Puls moguće je koristiti s Internet Explorerom, no radi se o starom web pregledniku koji nije u skladu s modernim standardima interneta. Stoga preporučujemo da koristiš jedan od dolje navedenih preglednika. Također možeš koristiti svoj mobilni telefon.",
    "detailedTitle": "O Internet Exploreru",
    "readMore": "Čitaj više",
    "warning":
      "Koristiš Internet Explorer. Preporučujemo da prijeđeš na drugi web preglednik za najbolje iskustvo."
  },
  "integrations": {
    "addIntegrationSuccess": "Integracija je uspješno dodana!",
    "allIntegrations": "Sve integracije",
    "apps": {
      "sftp": {
        "connectionInfo": "Prenijet će se u {dir} na SFTP poslužitelju.",
        "connectionTitle": "SFTP veza",
        "title": "SFTP"
      },
      "slack": {
        "connectionInfo": "Povezan na {teamName} kanal {channel}",
        "connectionTitle": "Pošalji putem aplikacije Slack u {appDetail}",
        "title": "Slack"
      }
    },
    "back": "Natrag",
    "buttons": {
      "deleteIntegration": {
        "help": "Ovo će ukloniti {type} iz Pulsa."
      }
    },
    "connectedSince": "Povezan od {date}",
    "empty": {
      "description":
        "Dodavanjem integracije možete učiniti da se Puls poveže s vlastitom uslugom. Odaberi gumb u nastavku za povezivanje s uslugom."
    },
    "errors": {
      "invalidIncomingIntegration":
        "Nevažeći dolazni podaci za integraciju! Nije moguće nastaviti."
    },
    "events": {
      "surveyCompleted": {
        "description":
          "Kada se anketa završi, rezultati za {groupTitle} se automatski šalju na integraciju.",
        "title": "Anketa završena"
      },
      "title": "Odaberi događaj kada treba kontaktirati integraciju"
    },
    "groups": {
      "title": "Odaberi grupu za povezivanje sa {appName}"
    },
    "integrationTo": "Integracija u {appName}",
    "nothingSelected": "Izaberi...",
    "setupIntegration": {
      "saveButtonTitle": "Spremi",
      "title": "Postavi integraciju u {appName}"
    },
    "title": "Integracije"
  },
  "login": {
    "button": {
      "forgotPassword": "Zaboravljena lozinka",
      "submit": "Prijava"
    },
    "captchaLegal": {
      "disclaimer":
        "Ova je stranica zaštićena reCAPTCHA sistemom i primjenjuju se Google {toc} i {privacyPolicy}.",
      "policy": "Politika privatnosti",
      "toc": "Uvjeti pružanja usluge"
    },
    "header": "Prijava",
    "placeholder": {
      "email": "Adresa e-pošte",
      "organization": "Organizacija",
      "password": "Lozinka"
    }
  },
  "navbar": {
    "welcome": "Dobrodošli"
  },
  "results": {
    "participants": "{participants} sudionika",
    "surveys": {
      "completed": {
        "latest": "Posljednja ispunjena anketa",
        "title": "Rezultati za ispunjene ankete"
      },
      "emptyResult": "Uskoro ćeš ovdje moći vidjeti rezultate!",
      "published": {
        "title": "Provjeri rezultate"
      }
    }
  },
  "setup": {
    "attributes": {
      "birthdate": {
        "info":
          "To je potrebno za prikupljanje statistike na temelju različitih dobnih skupina.",
        "label": "Datum rođenja"
      },
      "birth_date": {
        "info":
          "Ovo je potrebno za prikupljanje statistike na temelju različitih dobnih skupina.",
        "label": "Datum rođenja"
      },
      "custom:employed_since": {
        "info":
          "Datum kada si bio/la zaposlen/a. Ne mora biti točan datum. Preskoči izbor ako nisi zaposlen/a.",
        "label": "Zaposlen od"
      },
      "employment_date": {
        "info":
          "Datum kada si bio/la zaposlen/a. Ne mora biti točan datum. Preskoči izbor ako nisi zaposlen/a.",
        "label": "Zaposlen od"
      },
      "family_name": {
        "label": "Prezime"
      },
      "gender": {
        "few": "Ostali",
        "one": "Drugi",
        "other": "Drugo",
        "zero": "Ostalo",
        "female": "Ženski",
        "info":
          "Postavljamo pitanje o spolu kako bismo to uključili u statistiku. Ne moraš ništa odabrati ako ne želiš.",
        "label": "Odaberi spol:",
        "male": "Muški",
        "wontsay": "Radije ne bih rekao/la"
      },
      "given_name": {
        "label": "Ime"
      },
      "locale": {
        "ar": "Arapski",
        "da": "Danski",
        "de": "Njemački",
        "dk": "Danski",
        "en": "Engleski",
        "es": "Španjolski",
        "false": "Norveški",
        "fr": "Francuski",
        "info":
          "Odaberi jezik koji želiš koristiti. Ovo se također može promijeniti kasnije.",
        "label": "Odaberi jezik:",
        "ms": "Malajski",
        "no": "Norveški",
        "sv": "Švedski"
      }
    },
    "button": {
      "submit": "Spremi",
      "tryAgain": "Pokušaj ponovno"
    },
    "error": {
      "badPassword": "Odaberi sigurniju lozinku.",
      "clickLinkAgain":
        "Još jednom klikni na poveznicu u e-poruci dobrodošlice. Ako se problem nastavi, kontaktiraj support@pulsmeter.se",
      "invalid": "Ta poveznica više nije valjana, umjesto toga klikni ispod:",
      "noPassword": "Moraš odabrati lozinku.",
      "passwordsMustMatch": "Lozinke se moraju podudarati.",
      "sessionError": "Naišli smo na problem!",
      "SSONotFound": "Ne postoji SSO konfiguracija za ovu organizaciju.",
      "unexpected": "Došlo je do neočekivanog problema"
    },
    "header": "Započni s Pulsom!",
    "introText":
      "Bok {name}! Pozvan/a si na Puls, platformu za angažman suradnika. Aktiviraj svoj račun ispunjavanjem obrasca ispod. ",
    "introTextVolontary": "Samo je lozinka obavezna za popunjavanje.",
    "login": "Nastavi",
    "placeholder": {
      "confirmPassword": "Potvrdi lozinku",
      "newPassword": "Odaberi lozinku"
    }
  },
  "start": {
    "button": {
      "getOrganizationData": {
        "title": "Nastavi"
      },
      "requestCode": {
        "title": "Dohvati jednokratni kod"
      },
      "submitCode": {
        "title": "Prijava"
      }
    },
    "confirmRequestCode": {
      "header": {
        "description":
          "Najprije želimo da utvrdimo da si to ti! Kada nastaviš, bit će poslan jednokratni kod. Koristi ga u sljedećem koraku za prijavu na {productTitle} u {organizationName}.",
        "help":
          "Nedavno si zatražio/la kod. Provjeri jesi li unio/unijela kod u prozor gdje si ga zatražio/la. Ako nastaviš ovdje, poslat će novi kod."
      }
    },
    "enterCode": {
      "form": {
        "code": {
          "placeholder": "jednokratni kod"
        }
      },
      "header": {
        "deliveryMedium": {
          "EMAIL": "e-poruka",
          "SMS": "tekstualna poruka"
        },
        "description":
          "Jednokratni kod poslan ti je putem {deliveryMedium}. Ostavi ovaj prozor otvorenim i vrati se ovdje kako bi unio/unijela kod ispod:",
        "title": "Dobro došao/la u {productTitle}!"
      },
      "help": {
        "noCodeDescriptionEmail":
          "Kod ti je poslan putem e-pošte na adresu e-pošte koja uključuje '{destination}'. Može potrajati minuta ili dvije prije nego što kod stigne. Ako ga ne možeš pronaći, provjeri mapu neželjene pošte. Obrati pažnju da ostaviš ovaj prozor otvoren i unesi gornji kod.",
        "noCodeDescriptionSMS":
          "Kod je poslan kao tekstualna poruka na tvoj mobilni telefon i telefonski broj koji uključuje '{destination}'. Može potrajati minuta ili dvije prije nego što kod stigne. Ako ne prepoznaješ telefonski broj, obrati se svom administratoru.",
        "noCodeLinkTitle": "Nisi dobio/la šifru?",
        "noCodeStartOverLinkTitle": "Počni ispočetka"
      }
    },
    "enterEmail": {
      "form": {
        "username": {
          "placeholder": "adresa e-pošte"
        }
      },
      "header": {
        "description": "Unesi svoju adresu e-pošte u {organizationName}:"
      }
    },
    "enterOrganization": {
      "form": {
        "organization": {
          "placeholder": "naziv organizacije"
        }
      },
      "header": {
        "description": "Započni unosom naziva organizacije kojoj pripaš.",
        "invalid": {
          "spaces": "Nemoj koristiti razmake.",
          "specialchars": "Koristi samo slova.",
          "uppercase": "Koristi samo mala slova."
        }
      }
    }
  },
  "surveys": {
    "description": "Ove ankete su u tijeku:",
    "descriptionOneSurveyOnly": "Za početak odaberi anketu u nastavku!",
    "descriptionOneSurveyOnlyUserDone":
      "Anketa je još u tijeku. Odaberi je kako bi vidio/vidjela radnje koje si odabrao/la.",
    "ended": "završeno",
    "youreDone": "Završio/la si"
  },
  "surveysToFillIn": {
    "description": "Ove ankete su u tijeku:",
    "descriptionOneSurveyOnly": "Za početak odaberi anketu u nastavku!",
    "descriptionOneSurveyOnlyUserDone":
      "Anketa je još u tijeku. Odaberi je kako bi vidio/vidjela radnje koje si odabrao/la.",
    "emptyResult":
      "Trenutačno nema ankete koju je moguće ispuniti. Ovdje ispod možeš pogledati svoje radnje/komentare ili rezultate.",
    "ended": "završeno",
    "endsIn": "završava",
    "retry": "Pokušaj ponovno",
    "title":
      "Aktualna anketa|Imaš anketu koju je potrebno ispuniti|Čeka te {count} anketa",
    "youreDone": "Završio/la si"
  },
  "user": {
    "attributes": {
      "birthdate": {
        "info":
          "To je potrebno za prikupljanje statistike na temelju različitih dobnih skupina.",
        "label": "Datum rođenja"
      },
      "birth_date": {
        "info":
          "To je potrebno za prikupljanje statistike na temelju različitih dobnih skupina.",
        "label": "Datum rođenja"
      },
      "custom:employed_since": {
        "info":
          "Datum kada si bio/la zaposlen/a. Ne mora biti točan datum. Preskoči izbor ako nisi zaposlen/a.",
        "label": "Zaposlen/a od"
      },
      "employment_date": {
        "info":
          "Datum kada si bio/la zaposlen/a. Ne mora biti točan datum. Preskoči izbor ako nisi zaposlen/a.",
        "label": "Zaposlen/a od"
      },
      "family_name": {
        "label": "Prezime"
      },
      "gender": {
        "few": "Ostali",
        "one": "Ostali",
        "other": "Ostalo",
        "zero": "Ostalo",
        "female": "Ženski",
        "info":
          "Pitamo za spol kako bismo ga uključili u statistiku. Ne moraš ništa odabrati ako to ne želiš.",
        "label": "Odaberi spol:",
        "male": "Muški",
        "wontsay": "Ne želim se izjasniti"
      },
      "given_name": {
        "label": "Ime"
      },
      "locale": {
        "ar": "Arapski",
        "da": "Danski",
        "de": "Njemački",
        "en": "Engleski",
        "es": "Španjolski",
        "false": "Norveški",
        "fi": "Finski",
        "fr": "Francuski",
        "info":
          "Odaberi jezik koji želiš koristiti. Ovo se također može promijeniti kasnije.",
        "it": "Italijanski",
        "label": "Odaberi jezik:",
        "ms": "Malajski",
        "nl": "Nizozemski",
        "no": "Norveški",
        "pl": "Poljski",
        "sv": "Švedski"
      }
    },
    "button": {
      "cancel": "Otkaži",
      "skip": "Idi natrag",
      "submit": "Spremi",
      "tryAgain": "Pokušaj ponovno"
    },
    "error": {
      "badPassword": "Odaberi sigurniju lozinku.",
      "clickLinkAgain":
        "Još jednom klikni na poveznicu u e-poruci dobrodošlice. Ako se problem nastavi, kontaktiraj support@pulsmeter.se",
      "invalid": "Ta poveznica više nije valjana, umjesto toga klikni ispod:",
      "noPassword": "Moraš odabrati lozinku.",
      "passwordsMustMatch": "Lozinke se moraju podudarati.",
      "sessionError": "Naišli smo na problem!",
      "SSONotFound": "Ne postoji SSO konfiguracija za organizaciju.",
      "unexpected": "Došlo je do neočekivanog problema"
    },
    "header": "Korisničke postavke",
    "introText": "Provjeri je li tvoj korisnički profil ispravan.",
    "introTextNoAttributes": "Ovdje trenutno nema postavki za promjenu.",
    "introTextVolontary": "Podaci u nastavku popunjavaju se dobrovoljno.",
    "login": "Nastavi"
  },
  "whistleblower": {
    "buttons": {
      "cancel": "Otkaži",
      "done": "U redu",
      "send": "Pošalji anonimno"
    },
    "description":
      "Pružamo ti mogućnost da anonimno pošalješ prijavu o nezakonitom ponašanju ili prekršaju kojem si svjedočio/la u {organizationName}.",
    "detailedDescription":
      "Ovako to funkcionira. Svoje izvješće upisuješ u tekstni okvir ispod. Kada pošalješ izvješće, e-poruka se šalje unaprijed definiranom primatelju koji je  u tvojoj organizaciji odgovoran za poduzimanje radnji. Nemoguće je da primatelj vidi da si ti poslao/la poruku. Vidi samo da je stigla s Puls platforme.  Ako želiš da te kontaktiramo, svakako uključi informacije o tome u svoju poruku. Ako želiš ostati anoniman/na, možeš koristiti jednokratnu (privremenu) adresu e-pošte ili SIM karticu za mobilni telefon.",
    "doneText":
      "Hvala na slanju izješća. Shvatit ćemo to ozbiljno. Ako si dao/la kontakt podatke, javit ćemo ti se što je prije moguće!",
    "linkTitle": "Pročitaj više ovdje",
    "linkToHelp": "https://www.puls-solutions.com/help/TODO",
    "notEnabled": {
      "description":
        "Isprike. Tvoja organizacija nije omogućila značajku zviždača.",
      "title": "Nije dostupno"
    },
    "placeholder":
      "Unesi svoje izvješće ovdje. Budi što precizniji/a i reci nam želiš li i kako da te kontaktiramo.",
    "title": "Pošalji anonimnu prijavu"
  }
}
