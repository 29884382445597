// import uuid from 'uuid4'
import { DateTime } from 'luxon'
import { loadResultsSurveys } from '@/services/ResultsService'
const uuid = () => Math.random().toString(36).substr(2, 9)

const state = {
  resultsLoading: false,
  resultsSurveys: [],
  resultsError: null
}

const mutations = {
  setResultsSurveys(state, surveys) {
    state.resultsSurveys = surveys
  },
  setResultsError(state, error) {
    state.resultsError = error
  },
  setResultsLoading(state, loading) {
    state.resultsLoading = loading
  }
}

const actions = {
  loadResultsSurveys({ commit }, { organizationName }) {
    commit('setResultsLoading', true)
    commit('setResultsError', null)
    loadResultsSurveys()
      .then((surveys) => {
        commit('setResultsLoading', false)
        commit('setResultsSurveys', surveys)
      })
      .catch((err) => {
        if (
          err.response.status === 400 &&
          err.response.data === 'ERROR_UNKNOWN_USERPOOL'
        ) {
          commit('handleUnknownUserpool', { organizationName })
        } else {
          const error = err.message || err
          commit('setResultsLoading', false)
          commit('setResultsError', error)
          commit('addGlobalNotifications', {
            type: 'danger',
            id: uuid(),
            error
          })
        }
      })
  }
}

const getters = {
  getResultsSurveys: (state) =>
    [...state.resultsSurveys].sort((a, b) => {
      return (
        DateTime.fromISO(b.details.end_date) -
        DateTime.fromISO(a.details.end_date)
      )
    }),
  getResultsError: (state) => state.resultsError,
  getResultsLoading: (state) => state.resultsLoading
}

export default {
  state,
  mutations,
  actions,
  getters
}
