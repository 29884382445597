// import uuid from 'uuid4'
import { loadSurveys } from '@/services/SurveyService'

const state = {
  loading: false,
  surveys: null,
  error: null
}

const mutations = {
  setSurveys(state, surveys) {
    state.surveys = surveys
  },
  setSurveysError(state, error) {
    state.error = error
  },
  setSurveysLoading(state, loading) {
    state.loading = loading
  }
}

const actions = {
  loadSurveys({ commit }, { organizationName }) {
    commit('setSurveysLoading', true)
    commit('setSurveysError', null)
    loadSurveys()
      .then((surveys) => {
        commit('setSurveysLoading', false)
        commit('setSurveys', surveys)
      })
      .catch((err) => {
        if (
          err.response.status === 400 &&
          err.response.data === 'ERROR_UNKNOWN_USERPOOL'
        ) {
          commit('handleUnknownUserpool', { organizationName })
        } else {
          const error = err.message || err
          commit('setSurveysLoading', false)
          commit('setSurveysError', error)
        }
      })
  }
}

const getters = {
  getSurveys: (state) => state.surveys,
  getPublishedSurveys: (state) =>
    state.surveys
      ? state.surveys.filter((s) => s.details.status === 'published')
      : null
}

export default {
  state,
  mutations,
  actions,
  getters
}
