export default {
  "completePassword": {
    "button": {
      "submit": "إرسال"
    },
    "header": "اختر كلمة مرور جديدة",
    "placeholder": {
      "confirmPassword": "تأكيد كلمة المرور",
      "password": "كلمة المرور"
    }
  },
  "dashboard": {
    "button": {
      "goToAllResults": "جميع النتائج",
      "myActions": "إجراءاتي",
      "ourCompanyResults": "نتائجنا"
    },
    "card": {
      "body":
        "هنا يمكنك العثور على الإجراءات الشخصية التي اخترت القيام بها. يمكنك أيضًا رؤية النتيجة ومقارنتها بالمؤسسة ككل.",
      "title": "الإجراءات والنتائج"
    },
    "greetings": {
      "day": "مرحبًا، {username}!",
      "evening": "مساء الخير، {username}!",
      "morning": "صباح الخير، {username}!",
      "night": "طابت ليلتك، {username}!!"
    },
    "hello": "مرحبًا {username}!",
    "integrations": {
      "title": "عمليات الدمج"
    },
    "introHeadline": "{productTitle} في {organizationName}",
    "settings": {
      "ar": "العربية",
      "body": "هنا يمكنك تغيير إعدادات حسابك.",
      "da": "الدانماركية",
      "de": "الألمانية",
      "en": "الإنجليزية",
      "false": "النرويجية",
      "fr": "الفرنسية",
      "logout": {
        "button": {
          "title": "تسجيل خروج"
        },
        "confirmation": {
          "areyousure":
            "إذا قمت بتسجيل الخروج، فأنت بحاجة إلى طلب رمز جديد يُستخدم مرة واحدة لتسجيل الدخول مرة أخرى.",
          "cancel": "إلغاء",
          "false": "إلغاء",
          "no": "إلغاء",
          "title": "هل ترغب في تسجيل الخروج؟",
          "true": "تسجيل الخروج",
          "yes": "تسجيل الخروج"
        },
        "loggedin": "لقد قمت بتسجيل الدخول باسم {username}."
      },
      "no": "النرويجية",
      "sv": "السويدية",
      "title": "الإعدادات"
    }
  },
  "error": {
    "CodeMismatchException":
      "الرمز الذي يُستخدم مرة واحدة الذي أدخلته غير صالح. يرجى التحقق مرة أخرى والمحاولة مرة أخرى.",
    "couldNotLoadResults": "تعذر تحميل النتائج، يرجى المحاولة مرة أخرى.",
    "couldNotLoadSurveys": "تعذر تحميل الاستبيانات، يرجى المحاولة مرة أخرى.",
    "emailAtLeast3Letter":
      "يجب أن يحتوي عنوان البريد الإلكتروني على أحرف على الأقل",
    "emptyEmail": "أدخل عنوان بريدك الإلكتروني",
    "emptyOrganization": "أدخل اسم مؤسستك",
    "emptyPassword": "أدخل كلمة المرور",
    "ExpiredCodeException":
      "الرمز الذي يُستخدم مرة واحدة الذي أدخلته قديم جدًا ولا يمكن استخدامه لأسباب أمنية.",
    "InvalidParameterException": "جلسة المُحددات المطلوبة مفقودة.",
    "InvalidPasswordException": "الرجاء اختيار كلمة مرور أكثر أمانًا.",
    "LimitExceededException":
      "الكثير من المحاولات الفاشلة. يجب أن تنتظر قليلًا قبل المحاولة مرة أخرى.",
    "NotAuthorizedException":
      "لا يمكن حاليًا تسجيل الدخول باستخدام حساب المستخدم الخاص بك.",
    "notMatchPassword": "كلمتا المرور غير متطابقتين!",
    "OrganizationNotFoundException":
      "لم نتمكن من العثور على مؤسسة بهذا الاسم، يرجى المحاولة مرة أخرى.",
    "UserNotFoundException":
      "لم نتمكن من العثور على حساب مستخدم محدد بـ {username}."
  },
  "forgotten": {
    "backToLogin": "إلغاء",
    "helptext":
      "هنا يمكنك إعادة تعيين كلمة مرورك. ستتلقى بريدًا إلكترونيًا على عنوان البريد الإلكتروني أعلاه مع رمز ستدخله في الخطوة التالية.",
    "helptext-quickstart":
      "لقد أرسلنا لك للتو رمزًا مؤقتًا إلى {email}. أدخله أدناه، واختر كلمة مرور.",
    "helptext2": "اختر كلمة مرور آمنة.",
    "input": {
      "code": "تم العثور على رمز في البريد الإلكتروني",
      "organization": "المؤسسة",
      "password": "كلمة المرور الجديدة",
      "username": "عنوان البريد الإلكتروني"
    },
    "requestcode": {
      "submit": "متابعة"
    },
    "setNewPassword": {
      "submit": "بدء!"
    },
    "wait": "يُرجى الانتظار..."
  },
  "general": {
    "defaultProductTitle": "Puls"
  },
  "help": {
    "card": {
      "body":
        "هل هناك أي شيء يمكننا مساعدتك به؟ ما عليك سوى النقر على الزر أدناه للانتقال إلى مركز المساعدة.",
      "title": "المساعدة والدعم"
    },
    "description": "لا تقلق، نحن ندعمك.",
    "title": "مساعدة"
  },
  "ie": {
    "detailedText":
      "يمكن استخدام منصة Puls مع Internet Explorer ولكنه متصفح ويب قديم لا يتوافق مع المعايير الحديثة للويب. لذلك نوصي باستخدام أحد المتصفحات التالية. يمكنك أيضًا استخدام هاتفك الجوال.",
    "detailedTitle": "حول Internet Explorer",
    "readMore": "اقرأ أكثر",
    "warning":
      "أنت تستخدم Internet Explorer. نوصي باستخدام متصفح إنترنت آخر للحصول على أفضل تجربة."
  },
  "integrations": {
    "addIntegrationSuccess": "تمت إضافة عملية الدمج بنجاح!",
    "allIntegrations": "جميع عمليات الدمج",
    "apps": {
      "sftp": {
        "connectionInfo": "سيتم التحميل إلى {dir} على خادم SFTP.",
        "connectionTitle": "اتصال SFTP",
        "title": "SFTP"
      },
      "slack": {
        "connectionInfo": "متصل بقناة {teamName} {channel}",
        "connectionTitle": "تباطؤ إلى {appDetail}",
        "title": "تباطؤ"
      }
    },
    "back": "رجوع",
    "buttons": {
      "deleteIntegration": {
        "help": "سيؤدي هذا إلى إزالة {type} من Puls."
      }
    },
    "connectedSince": "متصل منذ {date}",
    "empty": {
      "description":
        "من خلال إضافة عملية دمج، يمكنك جعل Puls يتصل بخدمتك الخاصة. حدد الزر أدناه للاتصال بخدمة."
    },
    "errors": {
      "invalidIncomingIntegration":
        "بيانات واردة غير صالحة للدمج! لا يمكن الاستمرار."
    },
    "events": {
      "surveyCompleted": {
        "description":
          "عند اكتمال الاستبيان، يتم إرسال نتائج {groupTitle} تلقائيًا إلى الدمج.",
        "title": "اكتمل الاستبيان"
      },
      "title": "اختر حدثًا يجب عنده الاتصال بالدمج"
    },
    "groups": {
      "title": "اختر مجموعة للاتصال بـ {appName}"
    },
    "integrationTo": "الدمج في {appName}",
    "nothingSelected": "حدد...",
    "setupIntegration": {
      "saveButtonTitle": "حفظ",
      "title": "إعداد الدمج في {appName}"
    },
    "title": "عمليات الدمج"
  },
  "login": {
    "button": {
      "forgotPassword": "هل نسيت كلمة المرور",
      "submit": "تسجيل الدخول"
    },
    "captchaLegal": {
      "disclaimer":
        "هذا الموقع محمي بواسطة reCAPTCHA وتسري عليه {toc} و {privacyPolicy} الخاصة بـ Google.",
      "policy": "سياسة الخصوصية",
      "toc": "شروط الخدمة"
    },
    "header": "تسجيل الدخول",
    "placeholder": {
      "email": "عنوان البريد الإلكتروني",
      "organization": "المؤسسة",
      "password": "كلمة المرور"
    }
  },
  "navbar": {
    "welcome": "مرحبًا"
  },
  "results": {
    "participants": "{participants} مشاركين",
    "surveys": {
      "completed": {
        "latest": "آخر استبيان مكتمل",
        "title": "نتائج الاستبيانات المكتملة"
      },
      "emptyResult": "قريبا ستتمكن من رؤية النتائج هنا!",
      "published": {
        "title": "شاهد النتائج"
      }
    }
  },
  "setup": {
    "attributes": {
      "birthdate": {
        "info":
          "هذا ضروري من أجل جمع الإحصاءات على أساس الفئات العمرية المختلفة.",
        "label": "تاريخ الميلاد"
      },
      "birth_date": {
        "info":
          "هذا ضروري من أجل جمع الإحصاءات على أساس الفئات العمرية المختلفة.",
        "label": "تاريخ الميلاد"
      },
      "custom:employed_since": {
        "info":
          "التاريخ الذي تم توظيفك فيه. لا يجب أن يكون التاريخ بالضبط. تجاوز الاختيار إذا لم تكن موظفًا.",
        "label": "تعمل بالوظيفة منذ"
      },
      "employment_date": {
        "info":
          "التاريخ الذي تم توظيفك فيه. لا يجب أن يكون التاريخ بالضبط. تجاوز الاختيار إذا لم تكن موظفًا.",
        "label": "تعمل بالوظيفة منذ"
      },
      "family_name": {
        "label": "اسم العائلة"
      },
      "gender": {
        "few": "أخرى",
        "many": "أخرى",
        "one": "أخرى",
        "other": "أخرى",
        "two": "أخرى",
        "zero": "أخرى",
        "female": "أنثى",
        "info":
          "نسأل عن النوع الاجتماعي حتى يمكن إدراجه في الإحصائيات. لا يتعين عليك تحديد أي شيء إذا كنت لا تريد ذلك.",
        "label": "اختر النوع:",
        "male": "ذكر",
        "wontsay": "أفضل عدم القول"
      },
      "given_name": {
        "label": "الاسم الأول"
      },
      "locale": {
        "ar": "العربية",
        "da": "الدانماركية",
        "de": "الألمانية",
        "dk": "الدانماركية",
        "en": "الإنجليزية",
        "es": "الإسبانية",
        "false": "النرويجية",
        "fr": "الفرنسية",
        "info": "اختر اللغة التي تفضل استخدامها. يمكن أيضًا تغيير هذا لاحقًا.",
        "label": "اختر اللغة:",
        "ms": "الملايو",
        "no": "النرويجية",
        "sv": "السويدية"
      }
    },
    "button": {
      "submit": "حفظ",
      "tryAgain": "أعد المحاولة"
    },
    "error": {
      "badPassword": "الرجاء تحديد كلمة مرور أكثر أمانًا.",
      "clickLinkAgain":
        "الرجاء النقر فوق الارتباط الموجود في البريد الإلكتروني الترحيبي مرة أخرى. إذا استمرت المشكلة، فيرجى الاتصال بـ support@pulsmeter.se",
      "invalid": "هذا الرابط لم يعد صالحًا، انقر أدناه بدلًا من ذلك:",
      "noPassword": "يجب عليك اختيار كلمة مرور.",
      "passwordsMustMatch": "يجب أن تتطابق كلمتا المرور.",
      "sessionError": "واجهتنا مشكلة!",
      "SSONotFound": "لا توجد تهيئة SSO للمؤسسة.",
      "unexpected": "حدثت مشكلة غير متوقعة"
    },
    "header": "ابدأ مع Puls!",
    "introText":
      "مرحبًا {name}! لقد تمت دعوتك إلى Puls، وهي منصة لإشراك زملاء العمل. قم بتنشيط حسابك عن طريق ملء النموذج أدناه. ",
    "introTextVolontary": "إدخال كلمة المررو هو الإلزامي فقط.",
    "login": "متابعة",
    "placeholder": {
      "confirmPassword": "قم بتأكيد كلمة المرور",
      "newPassword": "اختر كلمة مرور"
    }
  },
  "start": {
    "button": {
      "getOrganizationData": {
        "title": "أعد المحاولة"
      },
      "requestCode": {
        "title": "احصل على الرمز الذي يُستخدم مرة واحدة"
      },
      "submitCode": {
        "title": "تسجيل الدخول"
      }
    },
    "confirmRequestCode": {
      "header": {
        "description":
          "نريد أولًا التأكد من أنك الشخص المناسب! عند المتابعة، سيتم إرسال رمز يُستخدم مرة واحدة. استخدمه في الخطوة التالية لتسجيل الدخول إلى {productTitle} على {organizationName}.",
        "help":
          "لقد طلبت رمزًا مؤخرًا. تأكد من إدخال الرمز في النافذة التي طلبته منها. إذا قمت بالمتابعة هنا، فسيتم إرسال رمز جديد."
      }
    },
    "enterCode": {
      "form": {
        "code": {
          "placeholder": "الرمز الذي يُستخدم مرة واحدة"
        }
      },
      "header": {
        "deliveryMedium": {
          "EMAIL": "بريد إلكتروني",
          "SMS": "رسالة نصية"
        },
        "description":
          "تم إرسال رمز يُستخدم مرة واحدة إليك عبر {deliveryMedium}. اترك هذه النافذة مفتوحة وعد هنا لإدخال الرمز أدناه:",
        "title": "مرحبًا بك في {productTitle}!"
      },
      "help": {
        "noCodeDescriptionEmail":
          "تم إرسال الرمز إليك عبر البريد الإلكتروني إلى عنوان البريد الإلكتروني الذي يتضمن '{destination}'. قد يستغرق وصول الرمز دقيقة أو دقيقتين. إذا لم تتمكن من العثور عليه، فتحقق من مجلد البريد العشوائي. تأكد من إبقاء هذه النافذة مفتوحة وأدخل الرمز بالأعلى.",
        "noCodeDescriptionSMS":
          "تم إرسال الرمز في رسالة نصية إلى هاتفك الجوال ورقم الهاتف الذي يتضمن '{destination}'. قد يستغرق وصول الرمز دقيقة أو دقيقتين. إذا لم تتعرف على رقم الهاتف، فيرجى الاتصال بالمسؤول.",
        "noCodeLinkTitle": "لم تحصل على رمز؟",
        "noCodeStartOverLinkTitle": "ابدأ من جديد"
      }
    },
    "enterEmail": {
      "form": {
        "username": {
          "placeholder": "عنوان البريد الإلكتروني"
        }
      },
      "header": {
        "description": "أدخل عنوان بريدك الإلكتروني على {organizationName}:"
      }
    },
    "enterOrganization": {
      "form": {
        "organization": {
          "placeholder": "اسم المؤسسة"
        }
      },
      "header": {
        "description": "ابدأ بإدخال اسم المؤسسة التي تنتمي إليها.",
        "invalid": {
          "spaces": "لا تستخدم مسافات.",
          "specialchars": "استخدم أحرفًا فقط.",
          "uppercase": "استخدم أحرفًا صغيرة فقط."
        }
      }
    }
  },
  "surveys": {
    "description": "هذه الاستبيانات جارية الآن:",
    "descriptionOneSurveyOnly": "حدد الاستبيان أدناه للبدء!",
    "descriptionOneSurveyOnlyUserDone":
      "الاستبيان لا يزال جاريًا. حدده لرؤية الإجراءات التي اخترتها.",
    "ended": "انتهى",
    "youreDone": "لقد انتهيت"
  },
  "surveysToFillIn": {
    "description": "هذه الاستبيانات جارية الآن:",
    "descriptionOneSurveyOnly": "حدد الاستبيان أدناه للبدء!",
    "descriptionOneSurveyOnlyUserDone":
      "الاستبيان لا يزال جاريًا. حدده لرؤية الإجراءات التي اخترتها.",
    "emptyResult":
      "في الوقت الحالي، لا يوجد استبيان في انتظارك للإجابة عنه. يمكنك إلقاء نظرة على إجراءاتك / تعليقاتك أو نتائجك هنا أدناه.",
    "ended": "انتهى",
    "endsIn": "ينتهي",
    "retry": "أعد المحاولة",
    "title":
      "استبيان حالي | لديك استبيان يجب الإجابة عنه | يوجد {count} استبيانات في انتظارك",
    "youreDone": "لقد انتهيت"
  },
  "user": {
    "attributes": {
      "birthdate": {
        "info":
          "هذا ضروري من أجل جمع الإحصاءات على أساس الفئات العمرية المختلفة.",
        "label": "تاريخ الميلاد"
      },
      "birth_date": {
        "info":
          "هذا ضروري من أجل جمع الإحصاءات على أساس الفئات العمرية المختلفة.",
        "label": "تاريخ الميلاد"
      },
      "custom:employed_since": {
        "info":
          "التاريخ الذي تم توظيفك فيه. لا يجب أن يكون التاريخ بالضبط. تخطي الاختيار إذا لم تكن موظفًا.",
        "label": "تعمل بالوظيفة منذ"
      },
      "employment_date": {
        "info":
          "التاريخ الذي تم توظيفك فيه. لا يجب أن يكون التاريخ بالضبط. تخطَ الاختيار إذا لم تكن موظفًا.",
        "label": "تعمل بالوظيفة منذ"
      },
      "family_name": {
        "label": "اسم العائلة"
      },
      "gender": {
        "few": "أخرى",
        "many": "أخرى",
        "one": "أخرى",
        "other": "أخرى",
        "two": "أخرى",
        "zero": "أخرى",
        "female": "أنثى",
        "info":
          "نسأل عن النوع الاجتماعي حتى يمكن إدراجه في الإحصائيات. لا يتعين عليك تحديد أي شيء إذا كنت لا تريد ذلك.",
        "label": "اختر النوع:",
        "male": "ذكر",
        "wontsay": "أفضل عدم القول"
      },
      "given_name": {
        "label": "الاسم الأول"
      },
      "locale": {
        "ar": "العربية",
        "da": "الدانماركية",
        "de": "الألمانية",
        "en": "الإنجليزية",
        "es": "الإسبانية",
        "false": "النرويجية",
        "fi": "الفنلندية",
        "fr": "الفرنسية",
        "info": "اختر اللغة التي تفضل استخدامها. يمكن أيضًا تغيير هذا لاحقًا.",
        "it": "الإيطالية",
        "label": "اختر اللغة:",
        "ms": "الملايو",
        "nl": "الهولندية",
        "no": "النرويجية",
        "pl": "البولندية",
        "sv": "السويدية"
      }
    },
    "button": {
      "cancel": "إلغاء",
      "skip": "رجوع",
      "submit": "حفظ",
      "tryAgain": "أعد المحاولة"
    },
    "error": {
      "badPassword": "الرجاء تحديد كلمة مرور أكثر أمانًا.",
      "clickLinkAgain":
        "الرجاء النقر فوق الارتباط الموجود في البريد الإلكتروني الترحيبي مرة أخرى. إذا استمرت المشكلة، فيرجى الاتصال بـ support@pulsmeter.se",
      "invalid": "هذا الرابط لم يعد صالحًا، انقر أدناه بدلًا من ذلك:",
      "noPassword": "يجب عليك اختيار كلمة مرور.",
      "passwordsMustMatch": "يجب أن تتطابق كلمتا المرور.",
      "sessionError": "واجهتنا مشكلة!",
      "SSONotFound": "لا توجد تهيئة SSO للمؤسسة.",
      "unexpected": "حدثت مشكلة غير متوقعة"
    },
    "header": "إعدادات المستخدم",
    "introText": "تحقق من صحة ملف تعريف المستخدم الخاص بك.",
    "introTextNoAttributes": "حاليًا لا توجد إعدادات لتغييرها هنا.",
    "introTextVolontary": "إدخال المعلومات التالية اختياري.",
    "login": "أعد المحاولة"
  },
  "whistleblower": {
    "buttons": {
      "cancel": "إلغاء",
      "done": "نعم",
      "send": "إرسال دون الكشف عن الهوية"
    },
    "description":
      "نوفر لك إمكانية إرسال تقرير مجهول عن السلوك غير القانوني أو المخالفات التي شاهدتها في {organizationName}.",
    "detailedDescription":
      "هذه هي الطريقة التي يعمل بها. أدخلت تقريرك في المربع النصي أدناه. عند إرسال التقرير، يتم إرسال بريد إلكتروني إلى مستلم محدد مسبقًا في مؤسستك يتحمل مسؤولية اتخاذ إجراء. من المستحيل أن يرى المستلم أن الرسالة قد تم إرسالها بواسطتك، سيرى فقط أنها جاءت من منصة Puls.  إذا كنت تريد أن يتم الاتصال بك، فتأكد من إدراج معلومات حول ذلك في رسالتك. إذا كنت تريد عدم الكشف عن هويتك، يمكنك استخدام عنوان بريد إلكتروني (مؤقت) أو بطاقة SIM للهاتف الجوال.",
    "doneText":
      "شكرًا لتقديم بلاغك. سوف نأخذ الأمر على محمل الجد. إذا كنت قد قدمت معلومات الاتصال، فسنعود إليك في أقرب وقت ممكن!",
    "linkTitle": "اقرأ المزيد هنا",
    "linkToHelp": "https://www.puls-solutions.com/help/TODO",
    "notEnabled": {
      "description":
        "معذرة. لم يتم تفعيل ميزة المبلغين عن المخالفات من قبل مؤسستك.",
      "title": "غير متاح"
    },
    "placeholder":
      "أدخل بلاغك هنا. كن محددًا قدر الإمكان وأخبرنا إذا كنت تريد الاتصال بك وكيف.",
    "title": "إرسال تقرير مجهول"
  }
}
